// ....order....

.order-detail-content {
  margin-bottom: 30px;

  .pro-heading-title {
    padding-bottom: 35px;
  }
  h4 {
    font-family: $font-family-base-bold;
    font-size: $font-size-lg;
    padding-left: 15px;
  }
  h2 {
    font-family: $font-family-base-bold;
    font-size: $font-size-lg;
    margin-bottom: 30px;
    small {
      text-transform: uppercase;
      color: $body-color-secondary;
    }
  }
  strong {
    font-family: $font-family-base-bold;
  }
  .item-controls {
    display: flex;
    align-items: center;
    .btn {
      font-size: 12px;
      border: 1px solid $gray-300;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      margin-right: 10px;

      &:hover {
        background-color: $secondary;
        color: color-yiq($secondary);
      }
    }
  }
  .top-table {
    background-color: transparent !important;

    tbody tr {
      align-items: center;
      border-bottom: 1px solid #dee2e6;

      td {
        border: none;
      }
    }
  }
  .table {
    background-color: #f8f9fa;

    th {
      border-top: none;
    }

    td {
      .pro-info {
        text-transform: uppercase;
      }
      .item-price {
        font-family: $font-family-base-bold;
        font-size: $font-size-lg;
        color: $secondary;
      }
    }
  }

  .order-id {
    margin-bottom: 30px;
    tbody {
      tr {
        .pending {
          p {
            font-family: $font-family-base-bold;
            font-size: $font-size-sm;
            background-color: $secondary;
            color: color-yiq($secondary);
            margin: 0;
            padding: 3px 7px 2px 7px;
            min-width: 80px;
            max-width: 100px;
            text-transform: uppercase;
            text-align: center;
          }
        }

        td {
          border-top: none;
          padding-left: 15px;
          color: $body-color-secondary;
        }
      }
    }
  }
  .comments-area {
    background-color: $white;
    margin-bottom: 15px;
    h4 {
      padding-left: 0;
    }
    p {
      font-size: $font-size-base;
    }
  }
}
