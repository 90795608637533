.checkout-content {
  .pro-heading-title {
    padding-bottom: 35px;
  }
  .checkout-module {
    // background-color: $white;
    padding: 0 15px;
    width: 100%;

    .checkoutd-nav {
      padding-left: 0;
      padding-right: 0;

      .nav-item {
        font-family: $font-family-base-bold;
        width: calc(25% - 17px);
        margin-right: 17px;
      }
      .nav-item a {
        background-color: #e1e1e1;
        position: relative;
        padding-left: 30px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .nav-item a:after {
        content: '';
        border-top: 18.5px solid transparent;
        border-bottom: 18.5px solid transparent;
        border-left: 18.5px solid #e1e1e1;
        position: absolute;
        right: -18px;
        top: 0;
        z-index: auto;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .nav-item a:before {
        content: '';
        border-top: 18.5px solid transparent;
        border-bottom: 18.5px solid transparent;
        border-left: 18.5px solid $body-bg;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .nav-item a.active {
        background-color: $secondary;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .nav-item a.active:after {
        content: '';
        border-top: 18.5px solid transparent;
        border-bottom: 18.5px solid transparent;
        border-left: 18.5px solid $secondary;
        position: absolute;
        right: -18px;
        top: 0;
        z-index: auto;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .nav-item a.active:before {
        content: '';
        border-top: 18.5px solid transparent;
        border-bottom: 18.5px solid transparent;
        border-left: 18.5px solid $body-bg;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    .form-group {
      .form-check-inline {
        margin-right: 5px;
        background-color: white;
        height: 34px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
      }
    }
    .btn {
      margin-right: 15px;
    }
  }

  .right-table {
    margin-bottom: -19px;
    thead {
      background-color: $white;
      th {
        border-top: none;
      }
    }
    tbody {
      background-color: $white;
    }
  }

  .top-table {
    tbody tr {
      align-items: center;
      border-bottom: 1px solid #ddd;
      td {
        border: none;
      }
    }
  }
  .item-detail {
    float: left;

    h2 {
      font-family: $font-family-base-bold;
      font-size: $font-size-lg;
      margin-bottom: 15px;
      small {
        color: $body-color-secondary;
      }
    }

    .item-controls {
      display: flex;
      align-items: center;
      .btn {
        font-size: 12px;
        border: 1px solid $gray-300;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        margin-right: 10px;

        &:hover {
          background-color: $secondary;
          color: color-yiq($secondary);
        }
      }
    }
  }

  .item-price,
  .item-total {
    font-family: $font-family-base-bold;
    font-size: 1.25rem;
    color: $secondary;
  }

  .tab-content {
    .tab-pane {
      h4 {
        font-family: $font-family-base-bold;
        margin-bottom: 15px;
      }
      .heading {
        margin-top: 15px;
        h4 {
          margin-bottom: 0;
        }
      }
      .top-table {
        thead {
          background-color: $white;
          tr {
            th {
              border-top: none;
            }
          }
        }
        tbody {
          background-color: $white;
        }
      }
    }
  }
}
.bodyrtl {
  .checkout-content .item-detail {
    float: right;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-content {
    .pro-heading-title {
      padding-bottom: 20px;
    }
    .checkout-module {
      .tab-content .tab-pane .top-table {
        border-bottom: none;
        text-align: center;

        thead {
          display: none;
        }
        tbody {
          .d-flex {
            flex-direction: column;
          }
          td {
            padding-right: 0;

            .input-group {
              justify-content: center;
            }
          }
          td:first-child {
            border: none;
          }
          td:last-child {
            padding-bottom: 15px;
            text-align: center;
          }
        }
      }
      .btn-res {
        padding-top: 25px;
      }
      .form-group .form-check-inline {
        width: 100%;
        justify-content: flex-start;
        padding-left: 0;
        margin-bottom: 15px;
      }
    }
    .item-detail {
      width: 100%;
      h2 {
        margin-bottom: 10px;
      }
      .item-controls {
        justify-content: center;

        .btn {
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
    .item-quantity {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
