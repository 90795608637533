@import "./private/variables";
@media only screen and (min-width:1025px){
    .header-desktop{
        display: block;
    }
    .header-mobile{
        display: none;
    }
}
@media only screen and (max-width:1024px){
    .header-mobile{
        display: block;
    }
    .header-desktop{
        display: none;
    }
    .sticky-header{
        display: none !important;
    }

    .bodyrtl{
        .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile {
			right: -250px;
			left: auto;
		}
		
		.header-mobile .header-maxi .navigation-mobile-container #navigation-mobile.navigation-active{
			right: 0;
		}
		.header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .main-manu {
			text-align: right;
		}
		.header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .logout-main .logout a {
			float: left;
			right: auto;
			left: 0.75rem;
		}

		.header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap .btn-secondary {
			
			right: auto;
			left: 0;
		}
		.header-mobile .header-navbar .form-inline .search-field-module .search-field-select {
			float: right;
			
		}
		.header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap input {
			text-align: right;
			padding: 0  20px 0 55px;
			
		}
		.header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
			padding: 0 15px 0 0 ;
			width: 80px;
		}
		.header-mobile .header-navbar .form-inline .search-field-module .search-field-select::before{
			right: auto;
			left: -10px;
			
		}
		.header-mobile .header-maxi .navigation-mobile-container #navigation-mobile a[aria-expanded="true"]:after {
			right: auto;
			left: 20px;
		}
		.header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu .unorder-list li a {
			
			text-align: right;
		}
		.header-mobile .header-maxi .navigation-mobile-container #navigation-mobile a[aria-expanded="false"]:after {
		
			left: 20px ;
			right: auto;
		}
    }
}
@media only screen and (min-width:769px) and (max-width:1024px){
    .header-mobile .header-navbar .form-inline .search-field-module .search-field-select {
        
        width: 150px !important;
    }
    .header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
        
        width: 150px;
    }
    .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap {
        width: calc(100% - 150px);
    }

    .bodyrtl{
        .header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
			padding: 0 15px 0 0 ;
			width: 150px;
		}
    }
}
@media only screen and (min-width:1200px){
   
    .padding-r0{
        padding-right: 0;
    }
    .padding-l0{
        padding-left: 0;
    }
    
    .footer-one{
        .single-footer{
            .newsletter{
                .block {
                    .form-inline{
                        .search {
                            .subscribe{
                                display: block;
                            }
                            .fa-location-arrow{
                                display: none;
                            }
                        }
                    } 
                }
            }
        }       
    } 
}
@media only screen and (min-width:992px) and (max-width:1199px){
    .info-boxes-content .info-box-full {
        border: none;
        padding:0;
        padding-top: 15px;
        margin: 0;

        .info-box{
            justify-content: start;
            
            padding-left: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }
    
    .modal .modal-dialog {
        width: 98%;
    }
    .listing .product article .pro-thumb2 {
        width: 36%;
    }
    .slick-slider{
        padding-bottom: 15px;
    }
    .sticky-header{
        .header-maxi {
            nav {
                .navbar-collapse{
                    ul {
                        li{
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
    .padding-r0{
        padding-right: 0;
    }
    .padding-l0{
        padding-left: 0;
    }
    .rev_slider_wrapper{
        li{
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .checkout-area{
        .checkout-module{
            .checkoutd-nav{
                .nav-item {
                    width: 23%;
                    margin-right: 15px;
                    margin-bottom: 15px;
                }
            } 
        }
    } 
    
    /* shop page */


    .right-menu{
    
        .main-manu {
            font-size: 12px;
        }
        .sub-manu{
            .unorder-list{
    
                .list-item{
                    a{
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .ban-img {
        height: 311px;
    }
    .caption3 {
        position: absolute;
        top: 20%;
        right: 0px;
        width: 77% !important;
    }
    .caption-lb1 {
        width: 247px;
    }
  
}
@media only screen and (min-width:768px) and (max-width:991px){
    .modal .modal-dialog {
        width: 98%;
        max-width: 700px;
    }
    

    .header-mobile .header-mini .navbar {
        padding: 0;
        justify-content: space-between;
    }

    
    
    .product article {
        .thumb {
            padding: 15px;
            .img-fluid {
                margin-left: auto;
                margin-right: auto;
                height: 100%;
            }
        }
        .timer-tag{
            bottom: -19px;
        }
        .upcomming-tag{
            bottom: -19px;
        }
        .mobile-icons{
            position:absolute;
            left:7px;

            .icon-liked {
                position: relative;

                
                .badge {
                    font-family: "Roboto", sans-serif;
                    border-radius: $border-radius;
                    position: absolute;
                    right: -10px;
                    top: -6px;
                }
                
            }
            
            .icon {
                background-color:$white;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35px;
                width: 35px;
                border: $border-width solid $border-color;
                border-radius: $border-radius;
                font-size: 1rem;
                color: $secondary;
                text-decoration: none;
                margin-bottom: 7px;
                cursor: pointer;

                &:hover{
                    background-color: $body-bg-secondary;
                    color:color-yiq($body-bg-secondary);
                }
            }
        }
        .mobile-buttons{
            text-align:center;
           // margin-bottom: 10px;
            a.btn{
                 width:49%;
            }
        }
        
    }
    .info-boxes-content .info-box-full {
        border: none;
        padding:0;
        padding-top: 15px;
        margin: 0;

        .info-box{
            justify-content: start;
            
            padding-left: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }

    .footer-mobile .single-footer .links-list li a {
        font-size: 0.775rem;
    }
    
    .checkout-area{
        .checkout-module{
            .checkoutd-nav{
                .nav-item {
                    width: 23%;
                    margin-right: 15px;
                    margin-bottom: 15px;
                }
            } 
        }
    } 
    .click-btn{
        .input-group{
                margin-bottom: 15px;
        }
    }
    .align-right{
        margin-bottom: 15px;}
}
@media only screen and (max-width:991px){
 
    .header-mobile .header-maxi {
        padding-bottom: 0;
    }
    .header-mobile .header-maxi .logo {
        
        padding-top: 0;
    }
    .banners-content {
        .container-fluid figure{
            margin-bottom: 10px;
        }
    }
    .listing .product article .pro-thumb2 {
        width: 36%;
    }
    .listing{
        .list2{
            p{
                display: none;
            }
        }
    }
    
    .breadcrumb {
       margin-top: 15px;
    }
  
    .info-boxes-content{
        border-top: none;
    }
    
    .product-carousel2{
        .product{
          padding: 15px;
        }
       
    }
    
    
    
    
    .checkout-content{
        .checkout-module{
            .form-group{
                padding:0 !important;
            }

            .checkoutm-nav{
                
                .nav-item{
                    width: calc(33.33% - 15px);
                    position: relative;
                    a{
                        background-color: #e0e0e2;
                        border-radius: 200px;
                        padding: 5px;
                        width: 45px;
                        height: 45px;
                        color: $primary;
                        font-weight: bold;
                        span{
                            display: flex;
                            margin-right: 0px;
                            border-radius: 200px;
                            background-color: white;
                            border: 4px solid $primary;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                        }
                        &.active{
                            background-color: #e0e0e2;
                            color: $primary;

                            span{
                                border-color: #6ad180;
                            }
                        }
                   }
                   &::before{
                        content: "";
                        height: 4px;
                        width: 100%;
                        display: block;
                        background-color: #c8c8c8;
                        position: absolute;
                        top: 21px;
                        z-index: -1;
                        
                        
                   }
                   &:last-child{
                       width:45px;
                       &::before{
                            content: "";
                            width: 0;
                        } 
                   } 
                }            
            }
        }
    }
    
   

    .right-menu{
        

        .img-main{
            margin-bottom: 15px;
            img{
                width: 100%;
                
            }
        }
    }
    .product-page{
        .xzoom-container{
            margin-bottom: 30px;
            .xzoom-thumbs{
                display: none;
            }
        }  

        h1 {
            font-size: 1rem;
			width:88%;
        }
        
        .controls{
            padding-left: 0;
            padding-right: 0;
            display: block;

            .select-control {
                margin-bottom: 15px;
            }
            .input-group{
                width: 100%;
            }
        }
        .product-buttons{
            display: block;
            h2{
                margin-bottom: 7px;
            }
            .buttons{
                margin-top: 7px;
                width: 100%;
            }
        }

    }
    .product-page-two{
        .xzoom-container{
            margin-top: 15px;
            margin-bottom: 0;
        } 
    }
    .product-page-three{
        .xzoom-3{
            .xzoom2, .xzoom3, .xzoom4, .xzoom5 {
                width: 100% !important;
                height: auto;
            }
        
        }
        .add-favrt{
            justify-content: center;

            background-color:$white;
            padding: 5px;
            margin-bottom: 15px; 
        }
        .owl-nav{
            top: 35%; 
            position: absolute;
            transform: translateY(-50%);
            width: 100%;

            button{
                display: inline-block;
                background-color: $body-bg-secondary !important;
                color: $body-color !important;
                border: $border-width solid $border-color !important;
                border-radius: $border-radius;
                margin-left: 1px;
                height: 38px;
                width: 38px;
                text-align: center;
                line-height: 38px;
                text-decoration: none;
                outline: none;
    
                &:hover{
                    background-color: $secondary !important;
                    color: color-yiq($secondary) !important;
                }
            
                span{                  
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    font-size: 2rem;
                    padding-bottom: 7px;
                }
            }
            .disabled{
                opacity: 0.3;
                &:hover{
                    background-color:  $body-bg-secondary !important;
                    color: $body-color !important;
                }
            }
            .owl-prev{
                position: absolute;
                margin-left: -45px;
                border-radius: 50px;
            }
            .owl-next{
                position: absolute;
                margin-right: -45px;
                border-radius: 50px;
                right: 0;
            }
        }
       
        .tab-list{
            
           .nav-index {
            width: 31%;
            padding: 5px;
            font-size: 12px;
           }
           .nav-index:last-child{
               margin-right: 0;
           }
       }
    }

    .product-page-four{
        .xzoom-container{
            margin-bottom: 0;
        }
        .add-favrt{
            justify-content: center;

            background-color:$white;
            padding: 5px;
            margin-bottom: 15px; 
        }
        .product-buttons .buttons {
            padding-left: 0;
            padding-right: 0;
        }
        .product-buttons .icon-liked {
            position: absolute;
            top: 0;
            right: 14px;
        }
        .tab-list{
            
            .nav-index {
                text-align: center;
             width: 31%;
             padding: 5px;
             font-size: 12px;
            }
            .nav-index:last-child{
                margin-right: 0;
            }
        }
    }

    .product-page-five{
        .xzoom-container{
            
        }
        .add-favrt{
            justify-content: center;

            background-color:$white;
            padding: 5px;
            margin-bottom: 15px; 
        }
        .product-buttons .buttons {
            padding-left: 0;
            padding-right: 0;
        }
        .product-buttons .icon-liked {
            position: absolute;
            top: 0;
            right: 14px;
        }
        .tab-list{
            
            .nav-index {
                text-align: center;
             width: 31%;
             padding: 5px;
             font-size: 12px;
            }
            .nav-index:last-child{
                margin-right: 0;
            }
        }
    }

    .product-page-six{
        
        .add-favrt{
            justify-content: center;

            background-color:$white;
            padding: 5px;
            margin-bottom: 15px; 
        }
        
        .tab-list{
            
            .nav-index {
                text-align: center;
             width: 31%;
             padding: 5px;
             font-size: 12px;
            }
            .nav-index:last-child{
                margin-right: 0;
            }
        }
    }
    .blog-content{
        .blog .blog-thumbnail .img-thumbnail {
            
            height: 100%;
        }
        .pagination {
             margin-bottom: 15px;
        }
    }
    .ban-img {
        height: 280px;
    }
    .nav-img {
        height: 100%;
    }
    .carousel-caption{
        position: absolute;
        top: 8%;
        right: 12%;
        width: 300px;    
        .text-deco1{
         
                h1{
                    margin: 0;
                    color:#ffffff;
                    font-size: 24px;
                    line-height: 53px;
                    position: relative;
                    z-index: 99999;
                    font-family: sans-serif;
                }
    
            
            p{
                font-size: 21px;
                font-family: sans-serif;
                position: relative;
                line-height: normal;
                color: #ffffff;
                margin: 0;
    
            }
            .btn{
                position: relative;
                bottom: -25px;
                background-color: #ffffff;
                font-family: sans-serif;
            }
        }
        // ...caption-two....   
        .text-deco2{
          
            text-align: left;
            h2{
                font-size: 11rem;
                position: absolute;
                line-height: 93px; 
                opacity: 0.4;
            }
                h1{
                    color:#ffffff;
                    font-family: sans-serif;
                    font-size: 32px;
                    text-align: left;
                    z-index: 99999;
                    margin: 0;
                    span{
                        color: #ffffff;
                    }
                }
    
            
            p{
                font-size: 14px;
                font-family: sans-serif;
              
                text-align: left;
                line-height: 0;
                color: #ffffff;
            }
            .btn{
                position: relative;
                bottom: -13px;
                border: 3px solid #ffffff;
                color:#ffffff;
                font-family: sans-serif;
            }
        }
       // ...caption-three....
        .text-deco3{
            text-align: right;
         
                h1{
                    color:black;
                    font-family: sans-serif;
                    font-size: 36px;
                    line-height: 85px;
                    text-align: right;
                 
                    z-index: 99999;
                  
                    margin: 0;
                    span{
                        color: red;
                    }
                }
    
            
            p{
                font-size: 16px;
                font-family: sans-serif;
                text-align: right;
                margin: 0;
                line-height: 0;
                color: black;
            }
            .btn{
                font-size: 19px;
                margin: 0;
                padding: 0;
                font-family: sans-serif;
            }
            
        }    
        //   .....caption-full-width1
        .text-deco4{
            // width: 411px;
            text-align: center;
         
                h1{
                    color: #ffffff;
                    font-size: 22px;
                    text-align: center;
                    position: relative;
                    z-index: 99999;
                    font-family: sans-serif;
                    line-height: 21px;
                }
    
            
            p{
                font-size: 25px;
                font-family: sans-serif;
                position: relative;
                text-align: center;
                color: #ffffff;
                font-weight: 600;
    
            }
            .btn{
                position: relative;
                bottom: -13px;
                background-color: #ffffff;
                font-family: sans-serif;
                font-size: 10px;
            }
        }     
          // ...caption-full-width2  
          .text-deco5{
          
            text-align: left;
         
                h1{
                    color:#ffffff;
                    font-family: sans-serif;
                    font-size: 30px;
                    text-align: left;
                    z-index: 99999;
                    margin: 0;
                    span{
                        color: #ffffff;
                    }
                }
    
            
            p{
                font-size: 14px;
                font-family: sans-serif;
              
                text-align: left;
                line-height: 0;
                color: #ffffff;
            }
            .btn{
                position: relative;
                bottom: -25px;
                border: 3px solid #ffffff;
                color:#ffffff;
                font-family: sans-serif;
            }
        }
    // ...caption-full-width3
    .text-deco6{
        text-align: right;
        h2{
            font-size: 11rem;
            position: absolute;
            line-height: 93px;
            margin: 0;
            opacity: 0.4;
        }
            h1{
                color: black;
                font-family: sans-serif;
                font-size: 28px;
                line-height: 66px;
                text-align: right;
                z-index: 99999;
                margin: 0;
                span{
                    color: red;
                }
            }
    
        
        p{
            font-size: 13px;
            font-family: sans-serif;
            text-align: right;
            margin: 0;
            line-height: 0;
            color: black;
        }
        .btn{
            font-size: 19px;
            margin: 0;
            padding: 0;
            font-family: sans-serif;
        }
        
    }      
     //   .....caption-full-width1
     .text-deco7{
        // width: 411px;
        text-align: left;
        h2{
            font-size: 11rem;
            position: absolute;
            line-height: 93px;
            margin: 0;
            opacity: 0.4;
            font-family: sans-serif;
        }
            h1{
                color:#ffffff;
                font-size: 27px;
                line-height: 85px;
                text-align: left;
                position: relative;
                z-index: 99999;
                font-family: sans-serif;
            }
    
        
        p{
            font-size: 29px;
            font-family: sans-serif;
            position: relative;
            text-align: left;
            line-height: 0;
            color: #ffffff;
    
        }
        .btn{
            position: relative;
            bottom: -25px;
            background-color: #ffffff;
            font-family: sans-serif;
        }
    }     
        // ...caption-full-width2  
        .text-deco8{     
            text-align: left;
            h2{
                font-size: 11rem;
                position: absolute;
                line-height: 93px; 
                opacity: 0.4;
            }
                h1{
                    color:#ffffff;
                    font-family: sans-serif;
                    font-size: 44px;
                    text-align: left;
                    z-index: 99999;
                    margin: 0;
                    span{
                        color: #ffffff;
                    }
                }
    
            
            p{
                font-size: 20px;
                font-family: sans-serif;
              
                text-align: left;
                line-height: 0;
                color: #ffffff;
            }
            .btn{
                position: relative;
                bottom: -25px;
                border: 3px solid #ffffff;
                color:#ffffff;
                font-family: sans-serif;
            }
        }
        // ...caption-full-width3
    .text-deco9{
        text-align: right;
      
            h1{
                color:black;
                font-family: sans-serif;
                font-size: 35px;
                line-height: 85px;
                text-align: right;
             
                z-index: 99999;
              
                margin: 0;
                span{
                    color: red;
                }
            }
    
        
        p{
            font-size: 16px;
            font-family: sans-serif;
            text-align: right;
            margin: 0;
            line-height: 0;
            color: black;
        }
        .btn{
            font-size: 19px;
            margin: 0;
            padding: 0;
            font-family: sans-serif;
        }
        
    }  
    .text-deco11{
      
        text-align: left;
        h2{
            font-size: 11rem;
            position: absolute;
            line-height: 93px; 
            opacity: 0.4;
        }
            h1{
             
                font-size: 23px;
            
                span{
                    color: #ffffff;
                }
            }

        
        p{
            font-size: 10px;
        }
        .btn{
            position: relative;
            bottom: -14px;
            font-size: 10px;
            border: 1px solid;
        }
    }
        }
    
       
}
@media only screen and (max-width:767px){
    
    
  
    .product-content .related-product-content {
        margin-top: 30px;
    }
   
    #back-to-top {
        
        bottom: 15px;
        right: 15px;
    }
    #newsletterModal .promo-box {
        padding: 30px 0 30px 0;
    }
    
    
    
   
    
    .pro-mini-content {
        .pro-banner-text {
            padding: 15px;
            img {
                width: 124px;
            }
        }
    }
    
   
    
    
    
    .login-content {
        .registration-process:first-child{
            margin-bottom: 30px;
        }
        .registration-socials{
            padding: 0;
            .btn{
                margin-bottom: 10px;
            }
        
        }
    
    }
    .banners-content{
        padding-top: 10px;
    }
    
    .blog-content{
        .blog-col{
            justify-content: center;
            display: flex;
        }
    }
    
    .products-content .products-area .owl-carousel{
        width: 220px;
        margin-left: auto;
        margin-right: auto;
    }
    .category-area article{
        padding: 0;
    }
    
    .product{
        article{

        
            .cat-title{
                font-size: 10px !important;
            }
        }
  
    }
    .footer-mobile{
        .display-mobile{
            display: block;
        }
        .copyright-content{
            margin-top: 0;
            .footer-image{
                float: none;
                text-align: center;
            }
            .footer-info{
                text-align: center;
            }
        }
    }
    
    .heading{
        h2{
            font-size: 13px;
            margin-bottom: 3px;

            small{
                font-size: 12px;
                line-height: normal;
            }
        }
        hr:before{
            display: none;
        }
    } 

    .product-2x{
        height: auto;
        article{
            .module {
                position: static;
                text-align: center;

                .title{
                    margin-top: 0;
                }
                .price{
                    margin-top: 0;
                }
                .list{
                    display: none;
                }
            }
            .thumb {
                position: static;
                margin-top: 42px;
                width: 100%;
            }
            .buttons{
                .btn{
                    width: 49%;
                }
            }
        } 
    } 

    .products-area{
        .nav-pills{
            .nav-link{
                width: 32.60%;
                font-size: 10px;
            }
            .active{
                &:after{
                    content: '';
                    position: absolute;
                    left:44%;
                    top: 31px;
                    width: 0;
                    height: 0;
                    right: 14px;
                }
            }
           
        }
        .owl-nav{
            top: 50%; 
            position: absolute;
            transform: translateY(-50%);
            width: 100%;
            .owl-prev{
                position: absolute;
                margin-left: -40px;
                border-radius: 50px;
            }
            .owl-next{
                position: absolute;
                margin-right: -40px;
                border-radius: 50px;
                right: 0;
            }
        }
    } 

    .shop-content{
        .breadcum-area{
            padding: 30px;
            .breadcrumb-item {
                padding-left: 0;
                width: 100%;
            }
        }
    }
    
    .shipping-content{
        .list-group{
            margin-bottom: 15px;
        } 
        .shipping-table{
         
            tbody{
                tr{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .main-form{
            form{
                .btn{
                    width: 100%;
                }
            }
          
        }
    } 
   
    .order-table{
        thead{
            display: none;
        }
        tbody{
            tr{
                background-color: #f4f4f4;
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                td{
                    display: flex;
                    flex-direction: column;
                    padding-left: 15px;
                    span{
                        font-weight: 700;
                    }
                }
            }
        }
    }
    
    

    .aboutus-content{
        .media-col{
            margin-bottom: 25px;
            border: none;
        }
        .media{
            display: block;
            text-align: center;

            .rounded-circle{
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        .accordion-container{
            padding-bottom: 30px;
            padding-top: 30px;
            .accordions{
                padding-bottom: 35px;
            }
            }
    }
    
      
       
    .order-detail-content{
        .table{
            border-bottom: none;
            thead{
                display: none;
            }
            tbody{
                
                .d-flex{
                    flex-direction: column;
                    
                }
                td{
                    padding-right: 0;

                    h2{
                        margin-bottom: 0;
                    }

                    .input-group {
                        justify-content: center;
                    }
                }
                td:first-child{
                    border: none;
                }
                
            }
        }
        .order-id{
            tbody tr:last-child{
                td:first-child{
                    padding-bottom: 0;
                }
            } 
        }
        .top-table{
            text-align: center;
        }
    }
}
@media only screen and (max-width:576px){
    
    
    .pro-category-content{
        padding-top: 0;
        padding-bottom: 30px;
        .pro-heading-subtitle {
            padding-top: 30px;

        }
    }
    .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-info{
        font-size: 11px;
    }
    .slider-wrapper .slider-for {
        margin-bottom: 0;
    }
    
    
    .profile-content .media .media-body p {
        font-size: 12px;
    }
    .slick-slider{
        padding-bottom: 0px;
    }
   
    
    .pro-tab-content{
        .nav{
            .nav-link{
                font-size: $font-size-sm;
                padding: 0.5rem 6px;
            }
        }
    }
  
    .sticky-header{
        display: none !important;
    }
    .registration-socials{
        .btn{
            width: 100%;
        }
    
    }
    

    
    
    .pagination{
        display: block;
        text-align: center;
        .loader-page{
            padding-bottom: calc(0.375rem + 1px);
        }
    }
  
  .product-detail-info{
    .pro-sub-buttons{
     #st-1.st-animated{
         display: flex;
     }
      }
    .vertical-slider {
        .slider-nav {
            .slick-track{
                .slider-nav__item {
                    img{
                        height: 115px !important;
                    }
                }
            }
        }
    }

      .pro-counter{
            .btn-secondary{
                min-width: 150px;
            }
      }
      .nav{
          .nav-item{
            width: 33.33%;
            margin-right: 0 !important;
            padding: 3px 1px !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
      }
      
  
  }
  


}

@media only screen and (max-width:465px){
    .product-detail-info{
        .vertical-slider {
            .slider-nav {
                .slick-track{
                    .slider-nav__item {
                        img{
                            height: 70px !important;
                        }
                    }
                }
            }
        }
          .pro-counter{
                .btn-secondary{
                    min-width: 150px;
                }
          }
      }
    }


