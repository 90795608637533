.animation-s1 {
  /* Zoom In #1 */
  .group-banners {
    .banner-image img {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.8s ease-in-out;
      transition: 0.8s ease-in-out;
    }
    .banner-image:hover img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  .pro-main {
    .banner-image img {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.8s ease-in-out;
      transition: 0.8s ease-in-out;
    }
    .banner-image:hover img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .banner-category img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out;
  }
  .banner-category:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .full-width-banner {
    .banner-image img {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    .banner-image:hover img {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  .full-screen-banner {
    .banner-image img {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    .banner-image:hover img {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
  .cat-thumb {
    overflow: hidden;
    position: relative;
  }
  .cat-thumb img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .cat-thumb:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.animation-s2 {
  /* Flashing */

  .group-banners {
    .banner-image:hover img {
      opacity: 1;
      -webkit-animation: flash 1.5s;
      animation: flash 1.5s;
    }
  }

  .full-width-banner {
    .banner-image:hover img {
      opacity: 1;
      -webkit-animation: flash 1.5s;
      animation: flash 1.5s;
    }
  }
  .full-screen-banner {
    .banner-image:hover img {
      opacity: 1;
      -webkit-animation: flash 1.5s;
      animation: flash 1.5s;
    }
  }

  .cat-thumb:hover img {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.animation-s3 {
  /* Shine */

  .group-banners {
    .banner-image {
      position: relative;
    }
    .banner-image::before {
      position: absolute;
      top: 0;
      left: -95%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
      -webkit-transform: skewX(-25deg);
      transform: skewX(-25deg);
    }
    .banner-image:hover::before {
      -webkit-animation: shine 0.95s;
      animation: shine 0.95s;
    }
  }

  .full-width-banner {
    .banner-image {
      position: relative;
    }
    .banner-image::before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
      -webkit-transform: skewX(-25deg);
      transform: skewX(-25deg);
    }
    .banner-image:hover::before {
      -webkit-animation: shine 0.75s;
      animation: shine 0.75s;
    }
  }
  .full-screen-banner {
    .banner-image {
      position: relative;
    }
    .banner-image::before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
      -webkit-transform: skewX(-25deg);
      transform: skewX(-25deg);
    }
    .banner-image:hover::before {
      -webkit-animation: shine 0.75s;
      animation: shine 0.75s;
    }
  }

  .cat-thumb {
    overflow: hidden;
    position: relative;
  }
  .cat-thumb::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }
  .cat-thumb:hover::before {
    -webkit-animation: shine 0.75s;
    animation: shine 0.75s;
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.animation-s5 {
  /* Opacity #2 */

  .group-banners {
    .banner-image img {
      opacity: 1;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    .banner-image:hover img {
      opacity: 0.5;
    }
  }

  .full-width-banner {
    .banner-image img {
      opacity: 1;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    .banner-image:hover img {
      opacity: 0.5;
    }
  }
  .full-screen-banner {
    .banner-image img {
      opacity: 1;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    .banner-image:hover img {
      opacity: 0.5;
    }
  }

  .cat-thumb img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .cat-thumb:hover img {
    opacity: 0.5;
  }
}

.animation-s4 {
  /* Circle */

  .group-banners {
    .banner-image {
      position: relative;
    }
    .banner-image::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: block;
      content: '';
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    .banner-image:hover::before {
      -webkit-animation: circle 1s;
      animation: circle 1s;
    }
  }

  .full-width-banner {
    .banner-image {
      position: relative;
    }
    .banner-image::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: block;
      content: '';
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    .banner-image:hover::before {
      -webkit-animation: circle 0.75s;
      animation: circle 0.75s;
    }
  }
  .full-screen-banner {
    .banner-image {
      position: relative;
    }
    .banner-image::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: block;
      content: '';
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    .banner-image:hover::before {
      -webkit-animation: circle 0.75s;
      animation: circle 0.75s;
    }
  }

  .cat-thumb {
    position: relative;
    overflow: hidden;
  }
  .cat-thumb::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .cat-thumb:hover::before {
    -webkit-animation: circle 0.75s;
    animation: circle 0.75s;
  }
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes animationmenu {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(0px, 10px);
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: translate(0px, 0px);
  }
}
@keyframes animationcartmenu {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    top: 10px;
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    top: 0;
  }
}

@keyframes animationselect {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(0px, 60px);
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: translate(0, 50px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes imageScroll {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

// bottomtotop

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes slidedown {
  0% {
    transform: translateY(-24rem);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-origin: top bottom;
  }
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

//  header slide down fixed
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}
