// ....compare....

.order-content {
  margin-bottom: 30px;
  .pro-heading-title {
    padding-bottom: 35px;
  }
  .table {
    border-bottom: 1px solid #ddd;
    th {
      border-top: none;
    }
  }
}
