// ...blog side bar...

.blog-menu {
  .block {
    background-color: white;
    margin-bottom: 30px;

    .pro-heading-subtitle {
      padding-bottom: 15px;

      h4 {
        font-family: $font-family-base-bold;
      }
    }
    .media {
      margin-bottom: 15px;
      img {
        margin-right: 15px;
      }
      .fas {
        color: $gray-700;
        font-size: 40px;
        margin-right: 15px;
      }
      .media-body {
        h6 {
          font-family: $font-family-base-bold;
          margin-bottom: 5px;
        }
        p {
          font-size: $font-size-base;
          color: $body-color-secondary;
          margin-bottom: 5px;

          a {
            font-size: $font-size-base;
          }
        }
        span {
          font-size: 12px;
          color: #aaa;
          .far {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .blog-menu {
    .pro-socials {
      ul {
        margin-bottom: 0;
      }
    }
    .block:last-child {
      margin-bottom: 0;
    }
  }
}
