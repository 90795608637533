// ....compare....

.profile-content {
  margin-bottom: 30px;
  h5 {
    font-size: $font-size-lg;
    font-family: $font-family-base-bold;
  }
  .pro-heading-title {
    padding-bottom: 35px;
  }

  .media {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 50px;
      height: 50px;
      border: $border-width solid #ddd;
      border-radius: 200px;
    }

    .media-body {
      margin-left: 15px;
      h5 {
        font-size: $font-size-lg;
        margin-bottom: 0;
        small {
          span {
            color: $secondary;
          }
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  form {
    float: left;
    width: 100%;
    background-color: #f4f4f4;
    padding: 30px;

    label.error {
      right: 15px;
    }

    .input-group.date {
      .input-group-prepend {
        margin-right: 0;
        .input-group-text {
          border-left-width: 0;
        }
      }
      .datepicker-inline {
        position: absolute;
        left: 0;
        top: 100%;
        background-color: white;
        z-index: 999;
      }
    }
    ul {
      padding-left: 10px;
      margin: 40px 0 0 0;
      li {
        margin-bottom: 15px;
      }
    }
    .btn {
      float: right;
    }
  }
}

.bodyrtl {
  .profile-content form label.error {
    left: 15px;
    right: auto;
    text-align: left;
  }
}

#datepicker {
  width: 100%;
}
#datepicker > span:hover {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .profile-content .media .media-body {
    a {
      display: block;
    }
  }
  .profile-content form {
    padding: 15px;
  }
  .profile-content form .btn.mt-3 {
    margin-top: 0 !important;
  }
  .profile-content form ul {
    padding-left: 15px;
    margin-top: 30px;
  }
}
