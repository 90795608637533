.slider-nav-vertical {
  .slick-slide {
    opacity: 0.7;
    cursor: pointer;
  }
  .slick-current {
    opacity: 1;
  }
  .slick-prev,
  .slick-next {
    top: 104%;
    &::before {
      color: $primary;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    left: 25px;
    right: auto;
  }
}
.slider-nav {
  .slick-slide {
    opacity: 0.7;
    cursor: pointer;
  }
  .slick-current {
    opacity: 1;
  }
  .slick-prev,
  .slick-next {
    top: 120%;
    &::before {
      color: $primary;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    left: 25px;
    right: auto;
  }
}
.slick-slide {
  outline: none;
  padding: 0 15px;
  img {
    width: 100%;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}
.slick-dots {
  position: static !important;
  margin-top: 35px !important;

  li {
    margin: 0 !important;
    button {
      position: relative;
      width: 16px !important;
      height: 16px !important;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s;
      margin: 2.5px;
      border: 2px solid $secondary !important;
      outline: none;

      &:before {
        content: none !important;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 0;
        left: 0;
        width: 100%;
        background-color: $secondary;
        -webkit-transition: height 0.3s ease;
        transition: height 0.3s ease;
      }
      &:focus,
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  li.slick-active {
    button {
      &::after {
        height: 100%;
      }
    }
  }
}

.slider-for-vertical {
  float: right;
  width: calc(80% - 30px);
  margin-left: 30px;

  .slick-slide.slick-current {
    opacity: 1 !important;
  }
}
.slider-nav-vertical {
  float: left;
  width: 20%;
  margin-top: 0;
}

.slider-wrapper {
  float: left;
  width: 100%;

  .slider-for {
    margin-bottom: 30px;

    .slider-for__item {
      padding: 0;
      img {
        width: 100%;
      }
    }
  }
  .slider-nav {
    .slick-prev,
    .slick-next {
      &::before {
        color: black;
      }
    }
    .slider-nav__item {
      margin-right: 15px;
      img {
        width: 100%;
      }
    }
  }
}

// .Modern-Slider{
//     .slick-dots{
//         position: absolute !important;
//         margin-top: 0 !important;
//         bottom: 25px;
//     }
// }

@media only screen and (max-width: 767px) {
  .slick-dots {
    margin-top: 25px !important;
  }
}

// / ==== main CSS === /
.img-fill {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.img-fill img {
  min-height: 100%;
  min-width: 100%;
  position: relative;
  display: inline-block;
  max-width: none;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.04);
}

.Grid1k {
  padding: 0 15px;
  max-width: 1200px;
  margin: auto;
}

.blocks-box,
.slick-slider {
  margin: 0;
  padding: 0 !important;
}

.Modern-Slider .slick-slide {
  // / If RTL Make This Right /
  float: left;
  padding: 0;
}

// / ==== Slider style === /

.Modern-Slider {
  height: 482px;
  //background: #000;
}

// .info {
//   height: 482px;
//   //background: #000;
// }
.Modern-Slider .img-fill {
  height: 482px;
  //background: #000;
}

.Modern-Slider .item .img-fill {
  height: 482px;
  background: #000;
}

// .Modern-Slider .item .img-fill .info {
//   position: absolute;
//   width: 100%;
//   height: 10%;
//   top: 0px;
//   left: 0px;

//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
//   opacity: 1;

//   //text-align:center;
// }

.Modern-Slider .item .img-fill .info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 1;

  //text-align:center;
}

.Modern-Slider .item .img-fill .info_left,
.Modern-Slider .item .img-fill .info_center,
.Modern-Slider .item .img-fill .info_right {
  width: 100%;
}
.Modern-Slider .item .img-fill .info_left {
  text-align: left;
}

.Modern-Slider .item .img-fill .info_center {
  text-align: center;
}

.Modern-Slider .item .img-fill .info_right {
  text-align: right;
}
//   .Modern-Slider .item .img-fill img{
//     filter:blur(5px);
//   }

.Modern-Slider .item .info > div {
  display: flex;
  // align-items: center;
  margin-top: 106px;
  height: 100%;
}

.Modern-Slider .NextArrow {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, 0.5);
  border: 0 none;
  margin-top: -22.5px;
  text-align: center;
  font: 20px/45px FontAwesome;
  color: #fff;
  z-index: 5;
}

.Modern-Slider .NextArrow:before {
  content: '\f105';
}

.Modern-Slider .PrevArrow {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, 0.5);
  border: 0 none;
  margin-top: -22.5px;
  text-align: center;
  font: 20px/45px FontAwesome;
  color: #fff;
  z-index: 5;
}

.Modern-Slider .PrevArrow:before {
  content: '\f104';
}
.Modern-Slider .slick-dots {
  position: absolute !important;
  margin-top: 0 !important;
  bottom: 28px;
}

.Modern-Slider .item h5 {
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  animation: fadeOutRight 1s both;
  margin: 0 0 0 0;
  padding: 0;
}

.Modern-Slider .item h2 {
  margin: 0;
  padding: 0;
  font-family: $font-family-base-extrabold;
  font-size: 50px;
  line-height: 70px;
  margin-bottom: 20px;
  margin-top: 7px;
  color: #fff;
  overflow: hidden;
  animation: fadeOutLeft 1s both;
}
.Modern-Slider .item button {
  min-width: 150px;
  max-width: 150px;
  // box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1) !important;
}
.Modern-Slider .slick-active h5 {
  animation: fadeInDown 1s both 1s;
}

.Modern-Slider .slick-active h2 {
  animation: fadeInLeft 1s both 1.5s;
}
.Modern-Slider .slick-active .btn {
  animation: fadeInUp 1s both 2s;
}

// .Modern-Slider .slick-active {
//   animation: Slick-FastSwipeIn 1s both;
// }

.Modern-Slider {
  background: #242424;
}

// / ==== Slider image Transition === /
@keyframes Slick-FastSwipeIn {
  0% {
    transform: rotate3d(0, 1, 0, 150deg) scale(0) perspective(400px);
  }
  100% {
    transform: rotate3d(0, 1, 0, 0deg) scale(1) perspective(400px);
  }
}

@-webkit-keyframes ProgressDots {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}
@keyframes ProgressDots {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

// / ==== Slick Slider Css Ruls === /
.slick-slider {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  // / If RTL Make This Right /
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

@media only screen and (max-width: 1199px) {
  .Modern-Slider {
    height: 380px;
  }
  .Modern-Slider {
    .item {
      .img-fill {
        height: 380px;

        .info > div {
          margin-top: 90px;
        }

        h5 {
          font-size: 18px;
        }
        h2 {
          font-size: 40px;
          line-height: normal;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .Modern-Slider {
    height: 300px;
  }
  .Modern-Slider {
    .slick-dots {
      display: none !important;
    }
    .item {
      .img-fill {
        height: 300px;

        .info > div {
          margin-top: 60px;
        }

        h5 {
          font-size: 18px;
        }
        h2 {
          font-size: 30px;
          line-height: normal;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .Modern-Slider {
    height: 220px;
  }
  .Modern-Slider {
    .item {
      .img-fill {
        height: 220px;

        .info > div {
          margin-top: 40px;
        }

        h5 {
          font-size: 14px;
        }
        h2 {
          font-size: 24px;
          line-height: normal;
        }
      }
    }
  }
}
