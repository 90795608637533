// ....compare....

.compare-content {
  margin-bottom: 30px;

  .pro-heading-title {
    padding-bottom: 35px;
  }
  .table {
    background-color: $white;
    margin-bottom: 0;
    thead {
      td {
        border: none;
        padding-left: 0;
        padding-right: 0;

        .img-div {
          padding: 15px;
          background-color: $gray-100;
          border: $border-width solid $gray-300;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: $font-size-base;
          color: $body-color-secondary;
          padding-right: 15px;
          border-bottom: $border-width solid $gray-300;
          border-top: none;

          h4 {
            font-family: $font-family-base-bold;
            font-size: $font-size-lg;
            color: $body-color;
            margin: 0;
          }

          span {
            display: inline-block;
            font-size: $font-size-base;
            font-family: $font-family-base-bold;
          }
          .price-tag {
            font-size: $font-size-lg;
            color: $secondary;
          }

          .detail-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      tr:last-child td {
        border: none;
        padding-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .compare-content .pro-heading-title {
    padding-bottom: 10px;

    h1 {
      font-size: 20px !important;
    }
  }
}
