/*-----------
/----- Header Top bar -------- */

$bg-header-bar: #fff;
$bg-menu-bar: #fff;
$bg-nav-bar: #fff;

.header-three {
  position: relative;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;

  /*-----------
    /----- Header bar -------- */

  .bg-header-bar {
    background-color: $bg-header-bar;
    border-bottom: 1px solid $border-color;
  }
  .header-maxi {
    padding: 25px 0;

    .navbar-lang {
      display: flex;
      align-items: center;

      .dropdown {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 15px;
        padding-right: 12px;

        .dropdown-toggle {
          font-size: $font-size-sm;
          color: color-yiq($bg-top-bar);

          &::after {
            color: color-yiq($bg-top-bar);
          }
        }

        .dropdown-menu {
          animation: animationmenu ease 0.5s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-fill-mode: forwards;

          background-color: $white;

          font-size: $font-size-sm;

          margin: 0 0 0 -28px;
          padding: 1.2rem 0;
          border-width: 0;

          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);

          .dropdown-item {
            color: color-yiq($white);
            padding: 5px 1.8rem;

            &:hover,
            &.active,
            &:active {
              background-color: transparent;
              color: $secondary;
            }
          }
        }
        &:hover > .dropdown-menu {
          display: block;
        }
      }
    }

    .pro-logo {
      text-align: center;
    }

    .pro-header-right-options {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .dropdown {
        .dropdown-searchbar {
          animation: animationmenu ease 0.5s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-fill-mode: forwards;
          position: fixed;
          top: 0;
          left: 0;
          width: 100% !important;
          background-color: #f8f9fa;
          z-index: 9;
          margin-top: 0 !important;
          form {
            position: relative;
          }
        }
      }

      li {
        position: relative;
        display: inline-block;
        list-style: none;
        margin-left: 35px;

        > a,
        button {
          position: relative;
          padding: 0;
          text-decoration: none;
          outline: none;
          z-index: 1;
          .fas,
          .far {
            font-size: 22px;
            color: color-yiq($bg-header-bar);

            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:hover {
              color: $secondary;
            }
          }

          .badge {
            padding: 3px 2px 2px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
            height: 16px;
            min-width: 16px;
            color: color-yiq($secondary);
            border-radius: $border-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99;
          }
        }

        .dropdown-searchbar {
          padding: 28px 0;

          .close {
            outline: none;
            font-size: 40px;
            font-weight: normal;
            line-height: 22px;
            text-shadow: none;
          }
          .pro-col {
            .pro-info-text {
              position: relative;
            }
            .pro-search-wrap {
              position: relative;
              .pro-search-input {
                width: 100%;
                text-decoration: none;
                outline: none;
                font-family: $font-family-base-bold;
                font-size: 24px;
                border: none;
                padding: 11px 12px 13px 0;
                background-color: #f8f9fa;
                border-bottom: 1px solid $border-color;
              }
              .pro-btn-search {
                float: right;
                padding-top: 3px;
                font-size: 30px;
                line-height: 1;
                position: absolute;
                cursor: pointer;
                bottom: 16px;
                right: 0;
              }
            }
          }
        }
      }
      .dropdown {
        .dropdown-toggle::after {
          content: none;
        }

        .dropdown-menu {
          animation: animationcartmenu ease 0.5s;
          animation-iteration-count: 1;

          animation-fill-mode: forwards;

          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          width: 270px;
          border-radius: $border-radius;
          border-color: $white;
          z-index: 99999;
          margin-top: 28px;

          .shopping-cart-items {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 17px;
            max-height: 360px;
            overflow-y: auto;
            li:last-child {
              border-bottom: none;
            }
            li {
              float: left;
              position: relative;
              width: 100%;
              margin-bottom: 10px;
              border-bottom: $border-width solid $border-color;
              padding-bottom: 10px;
              margin-left: 0;

              .item-thumb {
                position: absolute;
                left: 0;
                top: 0;

                .image {
                  width: 50px;
                  height: 50px;
                  border: $border-width solid $border-color;
                  border-radius: $border-radius;
                  overflow: hidden;
                }
              }
              .item-detail {
                float: left;
                padding-left: 64px;

                h2 {
                  display: block;
                  font-family: $font-family-base-bold;
                  font-size: $font-size-base;
                  white-space: normal;
                  text-transform: capitalize;
                }

                .item-s {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: $font-size-base;
                  color: $body-color;

                  .fas {
                    color: $body-color-secondary;
                    float: right;
                    margin-left: 8px;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    cursor: pointer;

                    &:hover {
                      color: $secondary;
                    }
                  }
                }
              }
            }
            .item-summary {
              font-family: $font-family-base-bold;
              font-size: $font-size-lg;
              color: $body-color;
              margin-bottom: 0;
              margin-top: 5px;
              white-space: normal;
            }
            .btn {
              padding: 0.675rem 1rem;
            }
          }
        }
      }
    }
  }

  //----- Menu bar -------- */
  .bg-menu-bar {
    background-color: $bg-menu-bar;
  }
  .bg-nav-bar {
    background-color: $bg-nav-bar;
  }
  .header-navbar {
    nav {
      width: 100%;
      padding: 0;
      .navbar-collapse {
        justify-content: center;
        ul {
          li {
            padding: 15px 0;
            margin-right: 45px;

            .nav-link {
              position: relative;
              font-family: $font-family-base-bold;
              font-size: $font-size-sm;
              color: color-yiq($bg-menu-bar);
              text-transform: uppercase;
              padding: 0;

              .badge-secondary {
                position: absolute;
                top: -13px;
                left: -12px;
                border-radius: 0;
                padding: 3px 3px 2px 3px;
                text-align: center;

                &:after {
                  content: '';
                  position: absolute;
                  left: 14px;
                  top: 12px;
                  width: 0;
                  height: 0;
                  border: 3px solid transparent;
                  border-top: 10px solid $secondary;
                  transform: rotate(-28deg);
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }
            .dropdown-menu {
              animation: animationmenu ease 0.5s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
              background-color: $white;

              font-size: $font-size-base;

              margin: 0 0 0 -28px;
              padding: 1.2rem 0;
              border-width: 0;

              -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
              box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);

              .dropdown-item {
                color: color-yiq($white);
                padding: 5px 1.8rem;

                &:hover,
                &.active,
                &:active {
                  background-color: transparent;
                  color: $secondary;
                }
              }
            }
          }

          .dropdown {
            .dropdown-toggle::after {
              color: $body-color-secondary;
              bottom: 6px;
            }

            &:hover > .dropdown-menu {
              display: block;
            }
          }

          .mega-dropdown {
            position: static !important;

            .mega-dropdown-menu {
              padding-left: 1.2rem;
              padding-right: 1.2rem;

              margin-left: 0;

              width: 100%;

              .dropdown-header {
                padding-left: 0;
                font-family: $font-family-base-bold;
                font-size: $font-size-base;
                margin-bottom: 10px;
                color: $body-color;
                padding-top: 0;
              }
              ul {
                padding-left: 0;
                li {
                  padding: 0;
                  margin-right: 0;
                  list-style: none;
                  a {
                    padding-left: 0;
                  }
                }
              }
            }
            .mega-dropdown-menu[aria-expanded='true'] {
              display: flex !important;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-three
    .header-navbar
    nav
    .navbar-collapse
    ul
    .mega-dropdown
    .mega-dropdown-menu
    ul
    li
    a {
    white-space: normal;
  }
}
