$bg-sticky-bar: $white;

.bg-sticky-bar {
  background-color: $bg-sticky-bar;
}
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  opacity: 1;
  display: block !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 0;
  animation: slide-down 0.7s;

  .header-maxi {
    padding: 0 0;

    .pro-header-right-options {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;

      li {
        position: relative;
        display: inline-block;
        list-style: none;
        margin-left: 35px;

        > a,
        button {
          position: relative;
          padding: 0;
          text-decoration: none;

          .fas,
          .far {
            font-size: 22px;
            color: color-yiq($bg-sticky-bar);

            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:hover {
              color: $secondary;
            }
          }

          .badge {
            padding: 3px 2px 2px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
            height: 16px;
            min-width: 16px;
            color: color-yiq($secondary);
            border-radius: $border-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99;
          }
        }
        .search-field-module {
          display: flex;
          align-items: center;
          padding: 0 15px;
          input {
            float: left;
            padding: 10px;
            height: 40px;
            width: 100%;
            border: 1px solid $border-color;
            border-radius: 0;
            outline: none !important;
          }
          .btn {
            padding: 10px;
            height: 40px;
          }
        }
      }
      .dropdown {
        float: right;

        .dropdown-toggle::after {
          content: none;
        }

        .dropdown-menu {
          animation: animationcartmenu ease 0.5s;
          animation-iteration-count: 1;

          animation-fill-mode: forwards;

          -webkit-box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1);
          width: 270px;
          border-radius: $border-radius;
          border-color: $white;
          z-index: 99999;
          margin-top: 33px;

          .shopping-cart-items {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 17px;
            max-height: 360px;
            overflow-y: auto;
            li:last-child {
              border-bottom: none;
            }
            li {
              float: left;
              position: relative;
              width: 100%;
              margin-bottom: 10px;
              border-bottom: $border-width solid $border-color;
              padding-bottom: 10px;
              margin-left: 0;

              .item-thumb {
                position: absolute;
                left: 0;
                top: 0;

                .image {
                  width: 50px;
                  height: 50px;
                  border: $border-width solid $border-color;
                  border-radius: $border-radius;
                  overflow: hidden;

                  img {
                    transform: translateY(-10%);
                  }
                }
              }
              .item-detail {
                float: left;
                padding-left: 74px;

                h2 {
                  display: block;
                  font-family: $font-family-base-bold;
                  font-size: $font-size-base;
                  white-space: normal;
                  text-transform: capitalize;
                }

                .item-s {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: $font-size-base;
                  color: $body-color;

                  .fas {
                    color: $body-color-secondary;
                    float: right;
                    margin-left: 8px;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    cursor: pointer;

                    &:hover {
                      color: $secondary;
                    }
                  }
                }
              }
            }
            .item-summary {
              font-family: $font-family-base-bold;
              font-size: $font-size-lg;
              color: $body-color;
              margin-bottom: 0;
              margin-top: 5px;
              white-space: normal;
            }
            .btn {
              padding: 0.675rem 1rem;
            }
          }
        }
      }
    }
    nav {
      width: 100%;
      padding: 0;
      position: static;
      .navbar-collapse {
        justify-content: center;
        ul {
          li {
            padding: 34px 0;
            margin-right: 45px;

            .nav-link {
              position: relative;
              font-family: $font-family-base-bold;
              font-size: $font-size-sm;
              color: color-yiq($bg-sticky-bar);
              font-weight: $font-weight-bold;
              text-transform: uppercase;
              padding: 0;

              .badge-secondary {
                font-size: 8px;
                position: absolute;
                top: -12px;
                left: -12px;
                border-radius: $border-radius;
                padding: 2px 3px 2px 3px;
                text-align: center;

                &:after {
                  content: '';
                  position: absolute;
                  left: 14px;
                  top: 10px;
                  width: 0;
                  height: 0;
                  border: 3px solid transparent;
                  border-top: 10px solid $secondary;
                  transform: rotate(-28deg);
                }
              }
            }

            .dropdown-menu {
              animation: animationmenu ease 0.5s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
              background-color: $white;

              margin: 0 0 0 -28px;
              padding: 1.2rem 0;
              border-width: 0;

              -webkit-box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1);
              box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1);

              .dropdown-item {
                color: color-yiq($white);
                padding: 5px 1.8rem;

                &:hover,
                &.active,
                &:active {
                  background-color: transparent;
                  color: $secondary;
                }
              }
            }
          }

          .dropdown {
            .dropdown-toggle::after {
              color: $body-color-secondary;
              bottom: 6px;
            }

            &:hover > .dropdown-menu {
              display: block;
            }
          }

          .mega-dropdown {
            position: static !important;

            .mega-dropdown-menu {
              padding-left: 1.2rem;
              padding-right: 1.2rem;
              margin-left: 0;

              border-top: $border-width solid $border-color !important;
              border-bottom: $border-width solid $border-color !important;
              width: 100%;
              box-shadow: none;

              .dropdown-header {
                padding-left: 0;
                font-family: $font-family-base-bold;
                font-size: $font-size-base;
                margin-bottom: 10px;
                color: $body-color;
                padding-top: 0;
              }
              ul {
                padding-left: 0;
                li {
                  padding: 0;
                  margin-right: 0;
                  list-style: none;
                  a {
                    padding-left: 0;
                  }
                }
              }
            }
            .mega-dropdown-menu[aria-expanded='true'] {
              display: flex !important;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
