// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default; // All Border Color
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1d1d1d !default; // All Text Color
$black: #000 !default;

$blue: #479af1 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary-dark: red;
$primary-light: #444;

$primary: #333 !default; // Main color or Primary color of the website
$secondary: #b38741 !default; // Main color for buttons
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default; // For only use background color of the website
$dark: #141414 !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 170 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

$body-bg: $white;
$body-bg-secondary: $gray-200;

$body-color: $black;
$body-color-secondary: $black;

// Links
//
// Style anchor elements.

$link-color: $gray-900 !default;
$link-decoration: none !default;
$link-hover-color: lighten($link-color, 20%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

$border-width: 1px !default;
$border-color: #ddd !default;

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

$btn-font-size: 0.8125rem !default;
$btn-padding-y: 0.475rem !default;
$btn-padding-x: 1rem !default;

$btn-border-width: 0 !default;

$input-color: $body-color-secondary !default;
$input-border-color: $border-color !default;

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'),
    url('../../fonts/Montserrat-Regular/Montserrat-Regular.ttf')
      format('truetype'),
    url('../../fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff')
      format('woff'),
    url('../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.ttf')
      format('truetype'),
    url('../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.svg#Montserrat-ExtraBold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'),
    url('../../fonts/Montserrat-Bold/Montserrat-Bold.ttf') format('truetype'),
    url('../../fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,800,900&display=swap');

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Montserrat-Regular', sans-serif !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-family-base-bold: 'Montserrat-Bold', sans-serif !default;
$font-family-base-extrabold: 'Montserrat-ExtraBold', sans-serif !default;

$font-size-base: 0.875rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base + 0.125) !default;
$font-size-sm: 0.8125rem !default;

$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
