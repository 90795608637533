@charset "UTF-8";
@font-face {
  font-family: 'Montserrat-Regular';
  src: url("../../fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.woff") format("woff"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.ttf") format("truetype"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff") format("woff"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.ttf") format("truetype"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("../../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.woff") format("woff"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.ttf") format("truetype"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #479af1;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #333;
  --secondary: #b38741;
  --success: #28a745;
  --info: #479af1;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #e9ecef;
  --dark: #141414;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Montserrat-Regular", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 800; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #1d1d1d;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #505050;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #1d1d1d;
  border-radius: 0; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #1d1d1d; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  .table tbody + tbody {
    border-top: 2px solid #ddd; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #ddd; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6c6c6; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #959595; }

.table-hover .table-primary:hover {
  background-color: #b9b9b9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b9b9b9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #eaddca; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #d7c19c; }

.table-hover .table-secondary:hover {
  background-color: #e3d1b8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e3d1b8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #cbe3fb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9fcaf8; }

.table-hover .table-info:hover {
  background-color: #b3d6f9; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b3d6f9; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9fafb; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f5f7; }

.table-hover .table-light:hover {
  background-color: #eaedf1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaedf1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bdbdbd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #858585; }

.table-hover .table-dark:hover {
  background-color: #b0b0b0; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b0b0b0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ddd; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  .form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: #737373;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.8125rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0.475rem 1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #000;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #333;
  border-color: #333; }
  .btn-primary:hover {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #b38741;
  border-color: #b38741; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #977237;
    border-color: #8e6b33; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #977237;
    border-color: #8e6b33;
    box-shadow: 0 0 0 0.2rem rgba(190, 153, 94, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #b38741;
    border-color: #b38741; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #8e6b33;
    border-color: #846430; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(190, 153, 94, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #479af1;
  border-color: #479af1; }
  .btn-info:hover {
    color: #fff;
    background-color: #2386ee;
    border-color: #1880ed; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #2386ee;
    border-color: #1880ed;
    box-shadow: 0 0 0 0.2rem rgba(99, 169, 243, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #479af1;
    border-color: #479af1; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1880ed;
    border-color: #127ae7; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 169, 243, 0.5); }

.btn-warning {
  color: #1d1d1d;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #fff;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(221, 168, 10, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #1d1d1d;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 168, 10, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #1d1d1d;
  background-color: #e9ecef;
  border-color: #e9ecef; }
  .btn-light:hover {
    color: #1d1d1d;
    background-color: #d3d9df;
    border-color: #cbd3da; }
  .btn-light:focus, .btn-light.focus {
    color: #1d1d1d;
    background-color: #d3d9df;
    border-color: #cbd3da;
    box-shadow: 0 0 0 0.2rem rgba(202, 205, 208, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #1d1d1d;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #1d1d1d;
    background-color: #cbd3da;
    border-color: #c4ccd4; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(202, 205, 208, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #141414;
  border-color: #141414; }
  .btn-dark:hover {
    color: #fff;
    background-color: #010101;
    border-color: black; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #010101;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(55, 55, 55, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #141414;
    border-color: #141414; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 55, 55, 0.5); }

.btn-outline-primary {
  color: #333;
  border-color: #333; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #333;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #333;
    border-color: #333; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.btn-outline-secondary {
  color: #b38741;
  border-color: #b38741; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #b38741;
    border-color: #b38741; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(179, 135, 65, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #b38741;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #b38741;
    border-color: #b38741; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(179, 135, 65, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #479af1;
  border-color: #479af1; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #479af1;
    border-color: #479af1; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 154, 241, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #479af1;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #479af1;
    border-color: #479af1; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(71, 154, 241, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #1d1d1d;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #1d1d1d;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #e9ecef;
  border-color: #e9ecef; }
  .btn-outline-light:hover {
    color: #1d1d1d;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e9ecef;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #1d1d1d;
    background-color: #e9ecef;
    border-color: #e9ecef; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.btn-outline-dark {
  color: #141414;
  border-color: #141414; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #141414;
    border-color: #141414; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(20, 20, 20, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #141414;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #141414;
    border-color: #141414; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(20, 20, 20, 0.5); }

.btn-link {
  font-weight: 400;
  color: #1d1d1d;
  text-decoration: none; }
  .btn-link:hover {
    color: #505050;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1d1d1d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #101010;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #333; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #1d1d1d; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ddd;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #333;
    background-color: #333; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #737373; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #8c8c8c;
    border-color: #8c8c8c; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #333;
  background-color: #333; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 51, 51, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(51, 51, 51, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 51, 51, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(51, 51, 51, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ddd;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #737373;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
    .custom-select:focus::-ms-value {
      color: #000;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.8125rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #737373;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #333;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #8c8c8c; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #333;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #8c8c8c; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #333;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #8c8c8c; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #333; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: -1px -1px 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 -1px -1px; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: -1px;
  border-top-right-radius: -1px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1d1d1d;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #505050;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #333;
  border-color: #333; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #333; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1a1a1a; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #b38741; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #8e6b33; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(179, 135, 65, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #479af1; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1880ed; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 154, 241, 0.5); }

.badge-warning {
  color: #1d1d1d;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #1d1d1d;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #1d1d1d;
  background-color: #e9ecef; }
  a.badge-light:hover, a.badge-light:focus {
    color: #1d1d1d;
    background-color: #cbd3da; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #141414; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: black; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(20, 20, 20, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6; }
  .alert-primary hr {
    border-top-color: #b9b9b9; }
  .alert-primary .alert-link {
    color: #020202; }

.alert-secondary {
  color: #5d4622;
  background-color: #f0e7d9;
  border-color: #eaddca; }
  .alert-secondary hr {
    border-top-color: #e3d1b8; }
  .alert-secondary .alert-link {
    color: #382a14; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #25507d;
  background-color: #daebfc;
  border-color: #cbe3fb; }
  .alert-info hr {
    border-top-color: #b3d6f9; }
  .alert-info .alert-link {
    color: #193756; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #797b7c;
  background-color: #fbfbfc;
  border-color: #f9fafb; }
  .alert-light hr {
    border-top-color: #eaedf1; }
  .alert-light .alert-link {
    color: #606162; }

.alert-dark {
  color: #0a0a0a;
  background-color: #d0d0d0;
  border-color: #bdbdbd; }
  .alert-dark hr {
    border-top-color: #b0b0b0; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #333;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #000;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #1b1b1b;
  background-color: #c6c6c6; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1b1b1b;
    background-color: #b9b9b9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1b1b1b;
    border-color: #1b1b1b; }

.list-group-item-secondary {
  color: #5d4622;
  background-color: #eaddca; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #5d4622;
    background-color: #e3d1b8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #5d4622;
    border-color: #5d4622; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #25507d;
  background-color: #cbe3fb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #25507d;
    background-color: #b3d6f9; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #25507d;
    border-color: #25507d; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #797b7c;
  background-color: #f9fafb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #797b7c;
    background-color: #eaedf1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #797b7c;
    border-color: #797b7c; }

.list-group-item-dark {
  color: #0a0a0a;
  background-color: #bdbdbd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0a0a0a;
    background-color: #b0b0b0; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0a0a0a;
    border-color: #0a0a0a; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: -1px;
  border-top-right-radius: -1px; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: -1px;
  border-top-right-radius: -1px; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #333 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1a1a1a !important; }

.bg-secondary {
  background-color: #b38741 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #8e6b33 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #479af1 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1880ed !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #e9ecef !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cbd3da !important; }

.bg-dark {
  background-color: #141414 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #ddd !important; }

.border-top {
  border-top: 1px solid #ddd !important; }

.border-right {
  border-right: 1px solid #ddd !important; }

.border-bottom {
  border-bottom: 1px solid #ddd !important; }

.border-left {
  border-left: 1px solid #ddd !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #333 !important; }

.border-secondary {
  border-color: #b38741 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #479af1 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #e9ecef !important; }

.border-dark {
  border-color: #141414 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0 !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-lg {
  border-radius: 0 !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: 800 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #333 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0d0d0d !important; }

.text-secondary {
  color: #b38741 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #7b5d2d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #479af1 !important; }

a.text-info:hover, a.text-info:focus {
  color: #1173db !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #e9ecef !important; }

a.text-light:hover, a.text-light:focus {
  color: #bdc6cf !important; }

.text-dark {
  color: #141414 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ddd; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ddd; } }

.animation-s1 {
  /* Zoom In #1 */ }
  .animation-s1 .group-banners .banner-image img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out; }
  .animation-s1 .group-banners .banner-image:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  .animation-s1 .pro-main .banner-image img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out; }
  .animation-s1 .pro-main .banner-image:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  .animation-s1 .banner-category img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out; }
  .animation-s1 .banner-category:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  .animation-s1 .full-width-banner .banner-image img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .animation-s1 .full-width-banner .banner-image:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  .animation-s1 .full-screen-banner .banner-image img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .animation-s1 .full-screen-banner .banner-image:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  .animation-s1 .cat-thumb {
    overflow: hidden;
    position: relative; }
  .animation-s1 .cat-thumb img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .animation-s1 .cat-thumb:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }

.animation-s2 {
  /* Flashing */ }
  .animation-s2 .group-banners .banner-image:hover img {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s; }
  .animation-s2 .full-width-banner .banner-image:hover img {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s; }
  .animation-s2 .full-screen-banner .banner-image:hover img {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s; }
  .animation-s2 .cat-thumb:hover img {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s; }

@-webkit-keyframes flash {
  0% {
    opacity: 0.4; }
  100% {
    opacity: 1; } }

@keyframes flash {
  0% {
    opacity: 0.4; }
  100% {
    opacity: 1; } }

.animation-s3 {
  /* Shine */ }
  .animation-s3 .group-banners .banner-image {
    position: relative; }
  .animation-s3 .group-banners .banner-image::before {
    position: absolute;
    top: 0;
    left: -95%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }
  .animation-s3 .group-banners .banner-image:hover::before {
    -webkit-animation: shine 0.95s;
    animation: shine 0.95s; }
  .animation-s3 .full-width-banner .banner-image {
    position: relative; }
  .animation-s3 .full-width-banner .banner-image::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }
  .animation-s3 .full-width-banner .banner-image:hover::before {
    -webkit-animation: shine 0.75s;
    animation: shine 0.75s; }
  .animation-s3 .full-screen-banner .banner-image {
    position: relative; }
  .animation-s3 .full-screen-banner .banner-image::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }
  .animation-s3 .full-screen-banner .banner-image:hover::before {
    -webkit-animation: shine 0.75s;
    animation: shine 0.75s; }
  .animation-s3 .cat-thumb {
    overflow: hidden;
    position: relative; }
  .animation-s3 .cat-thumb::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }
  .animation-s3 .cat-thumb:hover::before {
    -webkit-animation: shine 0.75s;
    animation: shine 0.75s; }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

.animation-s5 {
  /* Opacity #2 */ }
  .animation-s5 .group-banners .banner-image img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .animation-s5 .group-banners .banner-image:hover img {
    opacity: 0.5; }
  .animation-s5 .full-width-banner .banner-image img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .animation-s5 .full-width-banner .banner-image:hover img {
    opacity: 0.5; }
  .animation-s5 .full-screen-banner .banner-image img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .animation-s5 .full-screen-banner .banner-image:hover img {
    opacity: 0.5; }
  .animation-s5 .cat-thumb img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
  .animation-s5 .cat-thumb:hover img {
    opacity: 0.5; }

.animation-s4 {
  /* Circle */ }
  .animation-s4 .group-banners .banner-image {
    position: relative; }
  .animation-s4 .group-banners .banner-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0; }
  .animation-s4 .group-banners .banner-image:hover::before {
    -webkit-animation: circle 1s;
    animation: circle 1s; }
  .animation-s4 .full-width-banner .banner-image {
    position: relative; }
  .animation-s4 .full-width-banner .banner-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0; }
  .animation-s4 .full-width-banner .banner-image:hover::before {
    -webkit-animation: circle 0.75s;
    animation: circle 0.75s; }
  .animation-s4 .full-screen-banner .banner-image {
    position: relative; }
  .animation-s4 .full-screen-banner .banner-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0; }
  .animation-s4 .full-screen-banner .banner-image:hover::before {
    -webkit-animation: circle 0.75s;
    animation: circle 0.75s; }
  .animation-s4 .cat-thumb {
    position: relative;
    overflow: hidden; }
  .animation-s4 .cat-thumb::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0; }
  .animation-s4 .cat-thumb:hover::before {
    -webkit-animation: circle 0.75s;
    animation: circle 0.75s; }

@-webkit-keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

@keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

@keyframes animationmenu {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(0px, 10px); }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: translate(0px, 0px); } }

@keyframes animationcartmenu {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    top: 10px; }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    top: 0; } }

@keyframes animationselect {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(0px, 60px); }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    transform: translate(0, 50px); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes imageScroll {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

@keyframes slidedown {
  0% {
    transform: translateY(-24rem);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-origin: top bottom; }
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 0.9;
    transform: translateY(0); } }

body {
  line-height: 1.5;
  text-align: left;
  direction: ltr; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.5; }

html.boxed body {
  margin: 0 auto;
  -webkit-box-shadow: 0 4px 30px rgba(51, 51, 51, 0.1);
  box-shadow: 0 4px 30px rgba(51, 51, 51, 0.1); }

.table th,
.table td {
  padding-left: 0; }

@media only screen and (min-width: 1200px) {
  html.boxed body {
    max-width: 1200px; }
  html.boxed #stickyHeader {
    max-width: 1200px; }
  .container {
    max-width: 1200px; } }

* {
  letter-spacing: 1px; }

#alert-box {
  display: none; }

p {
  font-size: 1rem;
  color: #000; }

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  box-shadow: none !important; }
  select option {
    font-size: 0.875rem;
    color: #000; }
  select:focus {
    box-shadow: none; }

.select-control {
  float: left;
  position: relative; }
  .select-control::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    /* regular style/weight */
    content: '\f107';
    position: absolute;
    color: #000;
    bottom: 8px;
    right: 0;
    z-index: 0;
    font-size: 12px; }

.form-control:focus {
  z-index: 0 !important;
  box-shadow: none; }

.dropdown-toggle {
  position: relative;
  display: inline-block; }
  .dropdown-toggle::after {
    position: absolute;
    right: -14px;
    bottom: 6px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    /* regular style/weight */
    content: '\f107';
    border: none;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 10px; }

.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased; }

ul li a:focus {
  outline: none; }

label.error {
  position: absolute;
  width: 100%;
  color: #dc3545;
  bottom: -28px;
  font-size: 0.8125rem;
  text-align: right; }

.logo {
  display: inline-block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s; }
  .logo img {
    width: 120px; }

.logo:hover:before,
.logo:focus:before,
.logo:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1); }

.switcher {
  -webkit-box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.06);
  box-shadow: 0 0 4px 2px rgba(51, 51, 51, 0.06);
  padding: 25px 30px 27px 29px;
  z-index: 100;
  position: fixed;
  top: 260px;
  background-color: #fff;
  width: 280px;
  right: -280px;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out; }
  .switcher .slide-toggle {
    position: absolute;
    left: -40px;
    background-color: #333;
    color: #fff;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 0; }
  .switcher a {
    display: block;
    text-align: left;
    float: left;
    width: 100%;
    margin-bottom: 15px; }
    .switcher a.active .toggle {
      background-color: #daf4ef;
      border-color: #b38741;
      text-align: right; }
      .switcher a.active .toggle .circle {
        border-color: #b38741;
        background-color: #fff;
        right: 0;
        left: auto; }
  .switcher .text {
    float: left; }
  .switcher .toggle {
    float: right;
    position: relative;
    background-color: #f8f8f8;
    border: 1px solid #ebebeb;
    border-radius: 11px;
    width: 48px;
    min-width: 48px;
    height: 22px;
    line-height: 0;
    padding: 0 3px;
    text-align: left;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear; }
    .switcher .toggle .circle {
      display: inline-block;
      width: 20px;
      height: 14px;
      position: relative;
      top: 3px;
      background-color: transparent;
      border: 2px solid #777;
      border-radius: 10px; }
  .switcher .swicher-color {
    float: left;
    text-align: left; }
    .switcher .swicher-color .text {
      float: left;
      width: 100%; }
    .switcher .swicher-color ul {
      float: left;
      width: 100%;
      padding-left: 0;
      padding-right: 0; }
      .switcher .swicher-color ul li {
        float: left;
        display: flex;
        align-items: center;
        margin-top: 15px; }
        .switcher .swicher-color ul li #default {
          background-color: #c29958; }
        .switcher .swicher-color ul li #yellow {
          background-color: #28b293; }
        .switcher .swicher-color ul li #blue {
          background-color: #b3182a; }
        .switcher .swicher-color ul li #green {
          background-color: #3e5902; }
        .switcher .swicher-color ul li #navy-blue {
          background-color: #483a6f; }
        .switcher .swicher-color ul li #red {
          background-color: #621529; }
        .switcher .swicher-color ul li #pink {
          background-color: #f2b47e; }
        .switcher .swicher-color ul li a {
          float: left;
          height: 20px;
          width: 20px;
          margin-right: 10px;
          margin-bottom: 0;
          outline-width: 2px;
          outline-style: solid;
          outline-color: transparent;
          outline-offset: 2px; }
          .switcher .swicher-color ul li a:hover {
            outline-color: #333; }
          .switcher .swicher-color ul li a img {
            display: none; }
        .switcher .swicher-color ul li.active a {
          outline-color: #333; }
        .switcher .swicher-color ul li:last-child a {
          margin-right: 0; }
  .switcher.active {
    right: 0; }

nav[aria-label='breadcrumb'] {
  background-color: #f2f2f2; }
  nav[aria-label='breadcrumb'] .breadcrumb {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent; }
    nav[aria-label='breadcrumb'] .breadcrumb .breadcrumb-item a {
      font-size: 0.8125rem;
      color: #000; }
      nav[aria-label='breadcrumb'] .breadcrumb .breadcrumb-item a:hover {
        color: #b38741; }

#back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9;
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  line-height: 35px;
  background-color: #b38741;
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  animation-name: slidedown, bounce; }
  #back-to-top::before {
    background-color: #8e6b33; }

#back-to-top.show {
  opacity: 1; }

.list-group .list-group-item {
  padding: 0; }
  .list-group .list-group-item .nav-link .fas {
    color: #000;
    width: 20px;
    text-align: center;
    margin-right: 7px; }

.pro-content {
  padding-top: 100px; }
  .pro-content .pro-heading-title {
    display: block;
    padding-left: 15px;
    padding-bottom: 0;
    margin-top: -11px; }
    .pro-content .pro-heading-title h1 {
      font-family: "Montserrat-Bold", sans-serif;
      text-align: center;
      font-size: 28px;
      line-height: 1.5;
      margin-bottom: 0; }
    .pro-content .pro-heading-title h2 {
      font-family: "Montserrat-Bold", sans-serif;
      text-align: center;
      font-size: 28px;
      line-height: 1.5;
      margin-bottom: 0; }
    .pro-content .pro-heading-title p {
      line-height: 1.5;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 0; }

.pro-socials h4 {
  font-weight: 800; }

.pro-socials ul {
  display: flex;
  padding-left: 0; }
  .pro-socials ul li {
    font-size: 1.5rem;
    width: auto;
    margin-right: 15px;
    list-style: none; }
    .pro-socials ul li a {
      color: #000; }
      .pro-socials ul li a.fb:hover {
        color: #3b5998; }
      .pro-socials ul li a.tw:hover {
        color: #00acee; }
      .pro-socials ul li a.sk:hover {
        color: #00aff0; }
      .pro-socials ul li a.ln:hover {
        color: #0077b5; }
      .pro-socials ul li a.ig:hover {
        color: #3f729b; }

.pro-options .color-selection,
.pro-options .size-selection {
  margin-bottom: 30px; }
  .pro-options .color-selection h4,
  .pro-options .size-selection h4 {
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 0.875rem;
    color: #000;
    margin-bottom: 15px; }
    .pro-options .color-selection h4 b,
    .pro-options .size-selection h4 b {
      font-family: "Montserrat-Bold", sans-serif;
      color: #000; }
  .pro-options .color-selection ul,
  .pro-options .size-selection ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none; }
    .pro-options .color-selection ul li,
    .pro-options .size-selection ul li {
      margin-right: 10px;
      margin-left: 4px; }
      .pro-options .color-selection ul li.active a,
      .pro-options .size-selection ul li.active a {
        outline-color: #333; }
      .pro-options .color-selection ul li a,
      .pro-options .size-selection ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        outline-width: 2px;
        outline-style: solid;
        outline-color: transparent;
        outline-offset: 2px;
        font-size: 0.8125rem;
        color: #000; }
        .pro-options .color-selection ul li a:hover,
        .pro-options .size-selection ul li a:hover {
          outline-color: #333; }
      .pro-options .color-selection ul li .green,
      .pro-options .size-selection ul li .green {
        background-color: #c0c0c0; }
      .pro-options .color-selection ul li .red,
      .pro-options .size-selection ul li .red {
        background-color: #d4af37; }
      .pro-options .color-selection ul li .yellow,
      .pro-options .size-selection ul li .yellow {
        background-color: lightcoral; }

.pro-options .size-selection ul li a {
  outline-color: #ddd;
  line-height: 22px; }

.pro-counter {
  display: flex;
  align-items: center; }
  .pro-counter .btn-secondary {
    min-width: 230px;
    text-transform: uppercase;
    font-weight: 800; }

.pro-timer {
  z-index: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  padding: 10px 0; }
  .pro-timer .days,
  .pro-timer .hours,
  .pro-timer .mintues,
  .pro-timer .seconds {
    float: left;
    padding: 10px 12px;
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1rem;
    color: #fff;
    text-align: center; }
    .pro-timer .days small,
    .pro-timer .hours small,
    .pro-timer .mintues small,
    .pro-timer .seconds small {
      display: block;
      font-family: "Montserrat-Regular", sans-serif;
      font-size: 0.8125rem; }

.item-quantity {
  margin-right: 15px;
  width: 100px;
  height: 44px; }
  .item-quantity input {
    background-color: #e9ecef;
    font-size: 1rem;
    font-weight: 800;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    border: none;
    box-shadow: none; }
    .item-quantity input:focus {
      background-color: #e9ecef;
      box-shadow: none;
      border: none; }
  .item-quantity .input-group-btn {
    float: left;
    width: 30px; }
    .item-quantity .input-group-btn .btn {
      float: left;
      display: flex;
      align-items: center;
      height: 22px;
      padding: 0 10px;
      margin-bottom: 0;
      background-color: #dadfe4;
      color: #000;
      font-size: 10px;
      border: none; }

.notifications {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: 230px;
  background-color: black;
  margin-left: -115px;
  color: white;
  padding: 20px;
  text-align: center; }

@media only screen and (max-width: 991px) {
  .pro-content {
    padding-top: 70px; }
    .pro-content .pro-heading-title h1,
    .pro-content .pro-heading-title h2 {
      font-size: 25px; } }

.btn {
  padding-top: 12px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 12px; }
  .btn:focus {
    box-shadow: none !important; }

a,
.btn-link {
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  a:hover,
  .btn-link:hover {
    text-decoration: none;
    color: #b38741; }
  a:focus,
  .btn-link:focus {
    outline: none; }

button.btn,
a.btn {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-transform: uppercase; }

.btn-secondary:hover {
  background-color: #b38741; }

.btn-secondary:before {
  background-color: #8e6b33; }

.btn-light:hover {
  background-color: #cbd3da; }

.btn-light:before {
  background-color: #cbd3da; }

.btn-primary:hover {
  background-color: #333; }

.btn-primary:before {
  background-color: #1a1a1a; }

.btn-danger:hover {
  background-color: #dc3545; }

.btn-danger:before {
  background-color: #bd2130; }

.swipe-to-top {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s; }

.swipe-to-top:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swipe-to-top:hover:before,
.swipe-to-top:focus:before,
.swipe-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1); }

.btn-google {
  background-color: #000;
  color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .btn-google:hover, .btn-google:focus {
    background-color: #d53f22;
    color: #fff; }

.btn-facebook {
  background-color: #000;
  color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .btn-facebook:hover, .btn-facebook:focus {
    background-color: #334e89;
    color: #fff; }

.btn-twitter {
  background-color: #000;
  color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .btn-twitter:hover, .btn-twitter:focus {
    background-color: #1798ca;
    color: #fff; }

.swatch-primary {
  color: #fff; }

.swatch-secondary {
  color: #fff; }

.swatch-success {
  color: #fff; }

.swatch-info {
  color: #fff; }

.swatch-warning {
  color: #1d1d1d; }

.swatch-danger {
  color: #fff; }

.swatch-light {
  color: #1d1d1d; }

.swatch-dark {
  color: #fff; }

.banners-content {
  padding-top: 10px; }
  .banners-content .container-fluid, .banners-content .container-sm, .banners-content .container-md, .banners-content .container-lg, .banners-content .container-xl {
    padding-left: 18px;
    padding-right: 18px; }
    .banners-content .container-fluid [class^='col'], .banners-content .container-sm [class^='col'], .banners-content .container-md [class^='col'], .banners-content .container-lg [class^='col'], .banners-content .container-xl [class^='col'] {
      padding-right: 5px;
      padding-left: 5px; }
    .banners-content .container-fluid figure, .banners-content .container-sm figure, .banners-content .container-md figure, .banners-content .container-lg figure, .banners-content .container-xl figure {
      position: relative;
      border-radius: 0;
      overflow: hidden;
      margin-bottom: 0px;
      width: 100%; }
      .banners-content .container-fluid figure .captions, .banners-content .container-sm figure .captions, .banners-content .container-md figure .captions, .banners-content .container-lg figure .captions, .banners-content .container-xl figure .captions {
        position: relative; }
        .banners-content .container-fluid figure .captions > a, .banners-content .container-sm figure .captions > a, .banners-content .container-md figure .captions > a, .banners-content .container-lg figure .captions > a, .banners-content .container-xl figure .captions > a {
          display: block; }
        .banners-content .container-fluid figure .captions img, .banners-content .container-sm figure .captions img, .banners-content .container-md figure .captions img, .banners-content .container-lg figure .captions img, .banners-content .container-xl figure .captions img {
          width: 100%;
          transition: transform 1s;
          /* Animation */ }
        .banners-content .container-fluid figure .captions:hover img, .banners-content .container-sm figure .captions:hover img, .banners-content .container-md figure .captions:hover img, .banners-content .container-lg figure .captions:hover img, .banners-content .container-xl figure .captions:hover img {
          -webkit-transform: scale3d(1.1, 1.1, 1);
          transform: scale3d(1.1, 1.1, 1); }
      .banners-content .container-fluid figure.mt-image, .banners-content .container-sm figure.mt-image, .banners-content .container-md figure.mt-image, .banners-content .container-lg figure.mt-image, .banners-content .container-xl figure.mt-image {
        margin-top: 10px; }
    .banners-content .container-fluid .banner-caption, .banners-content .container-sm .banner-caption, .banners-content .container-md .banner-caption, .banners-content .container-lg .banner-caption, .banners-content .container-xl .banner-caption {
      position: absolute;
      top: 30px;
      width: 100%;
      z-index: 2; }
      .banners-content .container-fluid .banner-caption h2, .banners-content .container-sm .banner-caption h2, .banners-content .container-md .banner-caption h2, .banners-content .container-lg .banner-caption h2, .banners-content .container-xl .banner-caption h2 {
        font-family: "Montserrat-Bold", sans-serif;
        color: #fff;
        font-size: 28px;
        margin-top: -10px;
        margin-bottom: -5px; }
        .banners-content .container-fluid .banner-caption h2 small, .banners-content .container-sm .banner-caption h2 small, .banners-content .container-md .banner-caption h2 small, .banners-content .container-lg .banner-caption h2 small, .banners-content .container-xl .banner-caption h2 small {
          display: block;
          font-family: "Montserrat-Regular", sans-serif;
          font-size: 1rem;
          margin-top: 10px; }
      .banners-content .container-fluid .banner-caption.left-caption, .banners-content .container-sm .banner-caption.left-caption, .banners-content .container-md .banner-caption.left-caption, .banners-content .container-lg .banner-caption.left-caption, .banners-content .container-xl .banner-caption.left-caption {
        left: 0;
        text-align: left;
        padding-left: 30px;
        padding-right: 30px; }
        .banners-content .container-fluid .banner-caption.left-caption .btn, .banners-content .container-sm .banner-caption.left-caption .btn, .banners-content .container-md .banner-caption.left-caption .btn, .banners-content .container-lg .banner-caption.left-caption .btn, .banners-content .container-xl .banner-caption.left-caption .btn {
          margin-top: 30px; }

.pro-full-screen-banner {
  position: relative;
  overflow: hidden;
  margin-top: 100px;
  padding-top: 0;
  height: 550px; }
  .pro-full-screen-banner .pro-full-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all 1s;
    height: 100%;
    width: 100%; }
  .pro-full-screen-banner:hover .pro-full-bg {
    transform: scale(1.1); }
  .pro-full-screen-banner .pro-full-screen-overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%; }
    .pro-full-screen-banner .pro-full-screen-overlay .pro-full-screen {
      text-align: center; }
    .pro-full-screen-banner .pro-full-screen-overlay .pro-heading-title {
      padding-left: 0;
      margin-bottom: 35px; }
      .pro-full-screen-banner .pro-full-screen-overlay .pro-heading-title h2,
      .pro-full-screen-banner .pro-full-screen-overlay .pro-heading-title p {
        color: #fff; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banners-content .container-fluid .banner-caption h2, .banners-content .container-sm .banner-caption h2, .banners-content .container-md .banner-caption h2, .banners-content .container-lg .banner-caption h2, .banners-content .container-xl .banner-caption h2 {
    font-size: 16px;
    margin-top: -4px; }
    .banners-content .container-fluid .banner-caption h2 small, .banners-content .container-sm .banner-caption h2 small, .banners-content .container-md .banner-caption h2 small, .banners-content .container-lg .banner-caption h2 small, .banners-content .container-xl .banner-caption h2 small {
      margin-top: 0; }
  .banners-content .container-fluid .banner-caption .btn, .banners-content .container-sm .banner-caption .btn, .banners-content .container-md .banner-caption .btn, .banners-content .container-lg .banner-caption .btn, .banners-content .container-xl .banner-caption .btn {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px; } }

@media only screen and (max-width: 991px) {
  .banners-content .container-fluid .banner-caption.left-caption, .banners-content .container-sm .banner-caption.left-caption, .banners-content .container-md .banner-caption.left-caption, .banners-content .container-lg .banner-caption.left-caption, .banners-content .container-xl .banner-caption.left-caption {
    top: 50%;
    transform: translateY(-50%);
    text-align: center; }
    .banners-content .container-fluid .banner-caption.left-caption h2, .banners-content .container-sm .banner-caption.left-caption h2, .banners-content .container-md .banner-caption.left-caption h2, .banners-content .container-lg .banner-caption.left-caption h2, .banners-content .container-xl .banner-caption.left-caption h2 {
      font-size: 16px;
      margin-top: -4px; }
      .banners-content .container-fluid .banner-caption.left-caption h2 small, .banners-content .container-sm .banner-caption.left-caption h2 small, .banners-content .container-md .banner-caption.left-caption h2 small, .banners-content .container-lg .banner-caption.left-caption h2 small, .banners-content .container-xl .banner-caption.left-caption h2 small {
        margin-top: 0; }
  .banners-content .container-fluid .mb0-image, .banners-content .container-sm .mb0-image, .banners-content .container-md .mb0-image, .banners-content .container-lg .mb0-image, .banners-content .container-xl .mb0-image {
    margin-bottom: 0; } }

.bodyrtl .banners-content .container-fluid .banner-caption.left-caption, .bodyrtl .banners-content .container-sm .banner-caption.left-caption, .bodyrtl .banners-content .container-md .banner-caption.left-caption, .bodyrtl .banners-content .container-lg .banner-caption.left-caption, .bodyrtl .banners-content .container-xl .banner-caption.left-caption {
  right: 15px;
  left: auto;
  text-align: right; }

.categories-panel {
  padding: 0;
  border: none;
  border-left: 1px solid #dee2e6; }
  .categories-panel ul {
    width: 100%; }
    .categories-panel ul .nav-item {
      margin-bottom: 0; }
      .categories-panel ul .nav-item a {
        border: none;
        background-color: #fff;
        border-bottom: 1px solid #dee2e6;
        color: #1d1d1d !important; }
        .categories-panel ul .nav-item a:hover {
          color: #6c757d; }
        .categories-panel ul .nav-item a img {
          width: 16px;
          margin-right: 7px; }
        .categories-panel ul .nav-item a .fas {
          float: right;
          padding-top: 1px;
          font-size: 20px;
          color: #e9ecef; }
      .categories-panel ul .nav-item:hover .dropdown-menu {
        display: block; }
      .categories-panel ul .nav-item .dropdown-menu {
        margin: 0;
        padding: 0;
        border-color: #dee2e6;
        border-top: none;
        border-bottom: none; }
        .categories-panel ul .nav-item .dropdown-menu .dropdown-item {
          padding: 0.5rem; }

body:not(.touch-device).modal-open {
  overflow-x: hidden; }

.modal .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%; }
  .modal .modal-dialog .modal-body .pro-description .pro-title {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1.5rem;
    color: #000;
    font-weight: 800;
    line-height: 28px; }
  .modal .modal-dialog .modal-body .pro-description .pro-infos {
    margin-top: 10px; }
    .modal .modal-dialog .modal-body .pro-description .pro-infos .pro-single-info {
      display: flex;
      line-height: 24px; }
      .modal .modal-dialog .modal-body .pro-description .pro-infos .pro-single-info b {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 0.875rem;
        color: #000;
        margin-right: 10px; }
      .modal .modal-dialog .modal-body .pro-description .pro-infos .pro-single-info ul {
        display: inline-block;
        padding-left: 0;
        margin-bottom: 0; }
        .modal .modal-dialog .modal-body .pro-description .pro-infos .pro-single-info ul li {
          display: inline-block; }
          .modal .modal-dialog .modal-body .pro-description .pro-infos .pro-single-info ul li:after {
            content: ','; }
  .modal .modal-dialog .modal-body .pro-description p {
    font-size: 0.875rem;
    color: #000;
    margin-top: 10px;
    padding: 8px 0;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4; }
  .modal .modal-dialog .modal-body .pro-description .pro-price {
    display: flex;
    align-items: center; }
    .modal .modal-dialog .modal-body .pro-description .pro-price ins {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 1.35rem;
      color: #b38741;
      text-decoration: none; }
    .modal .modal-dialog .modal-body .pro-description .pro-price del {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 1rem;
      color: #000;
      text-decoration: line-through;
      margin-right: 10px; }
  .modal .modal-dialog .modal-body .close {
    outline: none;
    font-size: 40px;
    font-weight: normal;
    position: absolute;
    top: 5px;
    right: 12px; }

.modal .carousel .carousel-inner .carousel-item {
  border: 1px solid #e1e1e1;
  background-color: #e9ecef;
  text-align: center; }
  .modal .carousel .carousel-inner .carousel-item a {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 18px;
    color: #c8c8c8; }

.modal .carousel .carousel-control-prev,
.modal .carousel .carousel-control-next {
  background-color: #333;
  height: 40px;
  width: 40px;
  top: 50%;
  margin-top: -20px; }
  .modal .carousel .carousel-control-prev .fas,
  .modal .carousel .carousel-control-next .fas {
    font-size: 18px; }

#newsletterModal .promo-box {
  padding: 30px 0 30px 15px;
  text-align: center;
  z-index: 999; }
  #newsletterModal .promo-box .text-01 {
    z-index: 999;
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 5px; }
  #newsletterModal .promo-box .text-03 {
    z-index: 999;
    font-size: 1rem;
    color: #000;
    margin-bottom: 15px;
    line-height: 28px; }
  #newsletterModal .promo-box #mc_embed_signup .form-control {
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 0.8125rem;
    border: 1px solid #ddd;
    color: #000 !important;
    padding: 0 10px !important;
    line-height: normal !important;
    width: 100% !important; }
    #newsletterModal .promo-box #mc_embed_signup .form-control:focus {
      border-right: 1px solid #666 !important; }
  #newsletterModal .promo-box #mc_embed_signup .response {
    text-align: left;
    margin-bottom: 15px; }

#mc_embed_signup .mc-field-group {
  margin-bottom: 15px; }

#mc_embed_signup div.mce_inline_error {
  position: relative;
  text-indent: -488px;
  overflow: hidden;
  padding: 0 !important;
  margin: 15px 0 0 0 !important;
  font-weight: normal !important;
  background-color: transparent !important;
  line-height: 20px;
  height: 20px; }
  #mc_embed_signup div.mce_inline_error:after {
    content: 'Please enter a valid email';
    left: 0;
    position: absolute;
    top: 0;
    text-indent: 0;
    color: #dc3545;
    line-height: 20px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #newsletterModal .promo-box {
    padding: 15px 0px 0 15px; }
    #newsletterModal .promo-box .text-01 {
      font-size: 34px;
      line-height: 40px; }
  .modal .pro-description .btn-secondary {
    min-width: auto;
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (max-width: 767px) {
  .carousel .carousel-control-prev {
    left: 1px; }
  .carousel .carousel-control-next {
    right: 1px; }
  .modal .modal-dialog {
    z-index: 25;
    margin: 0;
    width: calc(100% - 1rem); }
    .modal .modal-dialog .pro-image {
      display: none; }
    .modal .modal-dialog .modal-body {
      z-index: 25; }
      .modal .modal-dialog .modal-body .close {
        top: -35px;
        right: -2px;
        color: white;
        opacity: 1;
        text-shadow: none; }
  .modal .pro-description {
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 15px; }
    .modal .pro-description .btn-secondary {
      min-width: auto;
      padding-left: 15px;
      padding-right: 15px; }
  #quickViewModal .modal-content .container {
    height: 450px;
    overflow-y: auto; } }

.bodyrtl .modal .modal-dialog .modal-body .pro-description .pro-price del {
  margin-left: 10px;
  margin-right: 0; }

.pagination {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
  padding: 2px 15px;
  width: 100%;
  text-align: right; }
  .pagination div {
    padding-left: 0;
    padding-right: 0; }
  .pagination .mainbar-link {
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-decoration: none; }
  .pagination .form-group {
    padding-left: 0;
    padding-right: 0; }
    .pagination .form-group .col-form-label {
      text-align: right; }
  .pagination .loader-page {
    display: inline-block;
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
    margin-top: 6px; }
    .pagination .loader-page .loader-page-item {
      font-size: 0.875rem;
      display: inline-block;
      border: 1px solid #ddd;
      text-align: center; }
      .pagination .loader-page .loader-page-item a {
        text-decoration: none;
        cursor: pointer;
        line-height: 12px;
        padding: 3px 5px; }

.product article {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  width: 100%; }
  .product article .pro-thumb {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0); }
    .product article .pro-thumb:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-color: rgba(255, 255, 255, 0.4);
      border-style: solid;
      border-width: 0;
      -webkit-transition-property: border-width;
      transition-property: border-width;
      -webkit-transition-duration: 0.1s;
      transition-duration: 0.1s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out; }
    .product article .pro-thumb:hover:after, .product article .pro-thumb:focus:after, .product article .pro-thumb:active:after {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      border-width: 6px;
      animation: imageScroll 1s linear 0s 1 both; }
    .product article .pro-thumb a[href='product-page1.html'] {
      cursor: default; }
    .product article .pro-thumb .img-fluid {
      display: inherit;
      width: inherit;
      margin-left: auto;
      margin-right: auto; }
    .product article .pro-thumb .pro-image {
      -webkit-transition: opacity 0.75s ease-out;
      transition: opacity 0.75s ease-out; }
    .product article .pro-thumb .pro-image-hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      -webkit-transition: opacity 0.75s ease-out;
      transition: opacity 0.75s ease-out; }
    .product article .pro-thumb .pro-buttons {
      z-index: 9;
      position: absolute;
      bottom: 35px;
      transition-timing-function: ease-in;
      /* Quick on the way out */
      transition: 0.75s;
      /* Hide thing by pushing it outside by default */
      transform: translateY(130%); }
      .product article .pro-thumb .pro-buttons .pro-icons {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center; }
        .product article .pro-thumb .pro-buttons .pro-icons .icon {
          position: relative;
          background-color: #b38741;
          color: #fff;
          display: flex;
          width: 40px;
          margin: 10px;
          height: 40px;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          overflow: visible; }
          .product article .pro-thumb .pro-buttons .pro-icons .icon:hover {
            background-color: #b38741;
            color: #fff; }
          .product article .pro-thumb .pro-buttons .pro-icons .icon::before {
            background-color: #8e6b33; }
      .product article .pro-thumb .pro-buttons .btn {
        margin: 10px;
        width: calc(100% - 20px); }
    .product article .pro-thumb .pro-tag {
      background-color: #dc3545;
      left: 5px;
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 0.8125rem;
      color: #fff;
      text-transform: uppercase;
      border-radius: 0;
      position: absolute;
      top: 5px;
      padding: 2px 7px 2px 7px;
      min-width: 40px;
      z-index: 9; }
    .product article .pro-thumb .pro-tag.bg-primary {
      background-color: #28a745 !important; }
    .product article .pro-thumb .pro-tag.bg-success {
      background-color: #479af1 !important; }
  .product article .pro-description {
    margin-top: 10px; }
    .product article .pro-description .pro-info {
      font-size: 0.8125rem;
      color: #000;
      text-transform: uppercase; }
    .product article .pro-description .pro-title {
      line-height: 20px;
      margin-bottom: 10px; }
      .product article .pro-description .pro-title a {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 0.875rem;
        color: #000;
        text-decoration: none; }
        .product article .pro-description .pro-title a:hover {
          color: #b38741; }
    .product article .pro-description .pro-btns {
      display: flex;
      justify-content: space-between; }
    .product article .pro-description .pro-price {
      display: flex;
      align-items: center; }
      .product article .pro-description .pro-price ins {
        font-size: 1.2rem;
        font-family: "Montserrat-Bold", sans-serif;
        color: #b38741;
        text-decoration: none; }
      .product article .pro-description .pro-price del {
        font-weight: 800;
        font-family: "Montserrat-Regular", sans-serif;
        font-size: 1rem;
        color: #000;
        text-decoration: line-through;
        margin-right: 10px; }
    .product article .pro-description .discription {
      display: none; }
    .product article .pro-description .list2 {
      display: none;
      margin-top: 18px; }
  .product article .pro-mobile-buttons {
    margin-top: 10px; }
  .product article .detail-product {
    display: flex;
    font-size: 1.35rem;
    font-weight: 800;
    color: #b38741; }
  .product article .listview-btn {
    display: none; }
  .product article:hover .pro-thumb .pro-image-hover {
    opacity: 1; }
  .product article:hover .pro-thumb .pro-buttons {
    transition-timing-function: ease-out;
    /* A litttttle slower on the way in */
    transition: 0.75s;
    /* Move into place */
    transform: translateY(0); }

.product .module {
  position: relative; }
  .product .module .cat-thumb {
    display: block; }
    .product .module .cat-thumb .img-fluid {
      width: 100%; }
  .product .module .cat-title {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%; }
    .product .module .cat-title:hover {
      text-decoration: none;
      color: #b38741; }

.product:hover .product-hover {
  opacity: 1; }

.mobile-pro-icons {
  position: absolute;
  top: 40px;
  right: 5px;
  top: 5px;
  z-index: 9; }
  .mobile-pro-icons .icon {
    position: relative;
    background-color: #c29958;
    color: #fff;
    display: flex;
    width: 40px;
    margin-bottom: 10px;
    height: 40px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    overflow: visible; }

.listing .list2 {
  display: block !important; }

.listing .product {
  padding-bottom: 30px; }
  .listing .product article {
    display: flex; }
    .listing .product article .pro-description {
      padding-left: 15px;
      width: 100%;
      border: 1px solid #ddd;
      margin: 0;
      padding: 30px; }
    .listing .product article .discount-tag {
      position: absolute;
      left: 0;
      right: auto; }
    .listing .product article .tag {
      display: block; }
    .listing .product article .discription {
      display: block; }
      .listing .product article .discription p {
        margin-bottom: 0.5rem; }
      .listing .product article .discription .pre-detial {
        display: flex; }
        .listing .product article .discription .pre-detial ul {
          list-style: none;
          display: flex;
          padding-left: 0;
          margin: 0; }
          .listing .product article .discription .pre-detial ul li {
            margin-right: 15px;
            display: flex; }
            .listing .product article .discription .pre-detial ul li p {
              margin-bottom: 0.5rem; }
      .listing .product article .discription .pro-infos {
        font-size: 0.8125rem; }
        .listing .product article .discription .pro-infos .pro-tags {
          display: flex; }
          .listing .product article .discription .pro-infos .pro-tags ul {
            list-style: none;
            display: flex;
            padding-left: 10px;
            margin-bottom: 0.5rem; }
            .listing .product article .discription .pro-infos .pro-tags ul li {
              margin-right: 5px; }
        .listing .product article .discription .pro-infos .pro-avail-info {
          padding-bottom: 0.5rem; }
    .listing .product article .title {
      display: flex;
      justify-content: start;
      align-items: start; }
    .listing .product article .price {
      display: flex;
      justify-content: start;
      align-items: start; }
    .listing .product article .listview-btn {
      display: block;
      margin-left: 15px; }
    .listing .product article .product-hover {
      float: left;
      width: 220px;
      height: 100%; }
      .listing .product article .product-hover .buttons {
        display: none; }

.rev_slider_wrapper .rev_slider li .BigBold-Title {
  font-family: "Montserrat-ExtraBold", sans-serif; }

.rev_slider_wrapper .rev_slider li .BigBold-SubTitle {
  font-family: "Montserrat-Bold", sans-serif;
  text-transform: uppercase; }

.rev_slider_wrapper .rev_slider li .BigBold-Button {
  font-family: "Montserrat-Bold", sans-serif;
  background-color: #b38741;
  color: #fff !important;
  border: none !important;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1) !important; }
  .rev_slider_wrapper .rev_slider li .BigBold-Button:hover, .rev_slider_wrapper .rev_slider li .BigBold-Button:focus, .rev_slider_wrapper .rev_slider li .BigBold-Button:active {
    color: #fff !important; }
  .rev_slider_wrapper .rev_slider li .BigBold-Button:before {
    background-color: #8e6b33; }

.rev_slider_wrapper .rev_slider li[data-index='rs-3043'] .tp-parallax-wrap:last-child {
  left: 50% !important;
  margin-left: -75px; }

.rev_slider_wrapper .hermes .tp-bullet {
  border-radius: 0;
  box-shadow: inset 0 0 0 2px #b38741; }
  .rev_slider_wrapper .hermes .tp-bullet.selected {
    background: #b38741; }
  .rev_slider_wrapper .hermes .tp-bullet:after {
    background: #b38741; }

.slider-nav-vertical .slick-slide {
  opacity: 0.7;
  cursor: pointer; }

.slider-nav-vertical .slick-current {
  opacity: 1; }

.slider-nav-vertical .slick-prev,
.slider-nav-vertical .slick-next {
  top: 104%; }
  .slider-nav-vertical .slick-prev::before,
  .slider-nav-vertical .slick-next::before {
    color: #333; }

.slider-nav-vertical .slick-prev {
  left: 0; }

.slider-nav-vertical .slick-next {
  left: 25px;
  right: auto; }

.slider-nav .slick-slide {
  opacity: 0.7;
  cursor: pointer; }

.slider-nav .slick-current {
  opacity: 1; }

.slider-nav .slick-prev,
.slider-nav .slick-next {
  top: 120%; }
  .slider-nav .slick-prev::before,
  .slider-nav .slick-next::before {
    color: #333; }

.slider-nav .slick-prev {
  left: 0; }

.slider-nav .slick-next {
  left: 25px;
  right: auto; }

.slick-slide {
  outline: none;
  padding: 0 15px; }
  .slick-slide img {
    width: 100%; }

.slick-dotted.slick-slider {
  margin-bottom: 0 !important; }

.slick-dots {
  position: static !important;
  margin-top: 35px !important; }
  .slick-dots li {
    margin: 0 !important; }
    .slick-dots li button {
      position: relative;
      width: 16px !important;
      height: 16px !important;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s;
      margin: 2.5px;
      border: 2px solid #b38741 !important;
      outline: none; }
      .slick-dots li button:before {
        content: none !important; }
      .slick-dots li button::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 0;
        left: 0;
        width: 100%;
        background-color: #b38741;
        -webkit-transition: height 0.3s ease;
        transition: height 0.3s ease; }
      .slick-dots li button:focus, .slick-dots li button:hover {
        background-color: rgba(0, 0, 0, 0.2); }
  .slick-dots li.slick-active button::after {
    height: 100%; }

.slider-for-vertical {
  float: right;
  width: calc(80% - 30px);
  margin-left: 30px; }
  .slider-for-vertical .slick-slide.slick-current {
    opacity: 1 !important; }

.slider-nav-vertical {
  float: left;
  width: 20%;
  margin-top: 0; }

.slider-wrapper {
  float: left;
  width: 100%; }
  .slider-wrapper .slider-for {
    margin-bottom: 30px; }
    .slider-wrapper .slider-for .slider-for__item {
      padding: 0; }
      .slider-wrapper .slider-for .slider-for__item img {
        width: 100%; }
  .slider-wrapper .slider-nav .slick-prev::before,
  .slider-wrapper .slider-nav .slick-next::before {
    color: black; }
  .slider-wrapper .slider-nav .slider-nav__item {
    margin-right: 15px; }
    .slider-wrapper .slider-nav .slider-nav__item img {
      width: 100%; }

@media only screen and (max-width: 767px) {
  .slick-dots {
    margin-top: 25px !important; } }

.img-fill {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center; }

.img-fill img {
  min-height: 100%;
  min-width: 100%;
  position: relative;
  display: inline-block;
  max-width: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.04); }

.Grid1k {
  padding: 0 15px;
  max-width: 1200px;
  margin: auto; }

.blocks-box,
.slick-slider {
  margin: 0;
  padding: 0 !important; }

.Modern-Slider .slick-slide {
  float: left;
  padding: 0; }

.Modern-Slider {
  height: 482px; }

.Modern-Slider .img-fill {
  height: 482px; }

.Modern-Slider .item .img-fill {
  height: 482px;
  background: #000; }

.Modern-Slider .item .img-fill .info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 1; }

.Modern-Slider .item .img-fill .info_left,
.Modern-Slider .item .img-fill .info_center,
.Modern-Slider .item .img-fill .info_right {
  width: 100%; }

.Modern-Slider .item .img-fill .info_left {
  text-align: left; }

.Modern-Slider .item .img-fill .info_center {
  text-align: center; }

.Modern-Slider .item .img-fill .info_right {
  text-align: right; }

.Modern-Slider .item .info > div {
  display: flex;
  margin-top: 106px;
  height: 100%; }

.Modern-Slider .NextArrow {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, 0.5);
  border: 0 none;
  margin-top: -22.5px;
  text-align: center;
  font: 20px/45px FontAwesome;
  color: #fff;
  z-index: 5; }

.Modern-Slider .NextArrow:before {
  content: '\f105'; }

.Modern-Slider .PrevArrow {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, 0.5);
  border: 0 none;
  margin-top: -22.5px;
  text-align: center;
  font: 20px/45px FontAwesome;
  color: #fff;
  z-index: 5; }

.Modern-Slider .PrevArrow:before {
  content: '\f104'; }

.Modern-Slider .slick-dots {
  position: absolute !important;
  margin-top: 0 !important;
  bottom: 28px; }

.Modern-Slider .item h5 {
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  animation: fadeOutRight 1s both;
  margin: 0 0 0 0;
  padding: 0; }

.Modern-Slider .item h2 {
  margin: 0;
  padding: 0;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 50px;
  line-height: 70px;
  margin-bottom: 20px;
  margin-top: 7px;
  color: #fff;
  overflow: hidden;
  animation: fadeOutLeft 1s both; }

.Modern-Slider .item button {
  min-width: 150px;
  max-width: 150px; }

.Modern-Slider .slick-active h5 {
  animation: fadeInDown 1s both 1s; }

.Modern-Slider .slick-active h2 {
  animation: fadeInLeft 1s both 1.5s; }

.Modern-Slider .slick-active .btn {
  animation: fadeInUp 1s both 2s; }

.Modern-Slider {
  background: #242424; }

@keyframes Slick-FastSwipeIn {
  0% {
    transform: rotate3d(0, 1, 0, 150deg) scale(0) perspective(400px); }
  100% {
    transform: rotate3d(0, 1, 0, 0deg) scale(1) perspective(400px); } }

@-webkit-keyframes ProgressDots {
  from {
    width: 0px; }
  to {
    width: 100%; } }

@keyframes ProgressDots {
  from {
    width: 0px; }
  to {
    width: 100%; } }

.slick-slider {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

@media only screen and (max-width: 1199px) {
  .Modern-Slider {
    height: 380px; }
  .Modern-Slider .item .img-fill {
    height: 380px; }
    .Modern-Slider .item .img-fill .info > div {
      margin-top: 90px; }
    .Modern-Slider .item .img-fill h5 {
      font-size: 18px; }
    .Modern-Slider .item .img-fill h2 {
      font-size: 40px;
      line-height: normal; } }

@media only screen and (max-width: 991px) {
  .Modern-Slider {
    height: 300px; }
  .Modern-Slider .slick-dots {
    display: none !important; }
  .Modern-Slider .item .img-fill {
    height: 300px; }
    .Modern-Slider .item .img-fill .info > div {
      margin-top: 60px; }
    .Modern-Slider .item .img-fill h5 {
      font-size: 18px; }
    .Modern-Slider .item .img-fill h2 {
      font-size: 30px;
      line-height: normal; } }

@media only screen and (max-width: 575px) {
  .Modern-Slider {
    height: 220px; }
  .Modern-Slider .item .img-fill {
    height: 220px; }
    .Modern-Slider .item .img-fill .info > div {
      margin-top: 40px; }
    .Modern-Slider .item .img-fill h5 {
      font-size: 14px; }
    .Modern-Slider .item .img-fill h2 {
      font-size: 24px;
      line-height: normal; } }

.bg-sticky-bar {
  background-color: #fff; }

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  opacity: 1;
  display: block !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 0;
  animation: slide-down 0.7s; }
  .sticky-header .header-maxi {
    padding: 0 0; }
    .sticky-header .header-maxi .pro-header-right-options {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px; }
      .sticky-header .header-maxi .pro-header-right-options li {
        position: relative;
        display: inline-block;
        list-style: none;
        margin-left: 35px; }
        .sticky-header .header-maxi .pro-header-right-options li > a,
        .sticky-header .header-maxi .pro-header-right-options li button {
          position: relative;
          padding: 0;
          text-decoration: none; }
          .sticky-header .header-maxi .pro-header-right-options li > a .fas,
          .sticky-header .header-maxi .pro-header-right-options li > a .far,
          .sticky-header .header-maxi .pro-header-right-options li button .fas,
          .sticky-header .header-maxi .pro-header-right-options li button .far {
            font-size: 22px;
            color: #1d1d1d;
            -webkit-transition: 0.4s;
            transition: 0.4s; }
            .sticky-header .header-maxi .pro-header-right-options li > a .fas:hover,
            .sticky-header .header-maxi .pro-header-right-options li > a .far:hover,
            .sticky-header .header-maxi .pro-header-right-options li button .fas:hover,
            .sticky-header .header-maxi .pro-header-right-options li button .far:hover {
              color: #b38741; }
          .sticky-header .header-maxi .pro-header-right-options li > a .badge,
          .sticky-header .header-maxi .pro-header-right-options li button .badge {
            padding: 3px 2px 2px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
            height: 16px;
            min-width: 16px;
            color: #fff;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99; }
        .sticky-header .header-maxi .pro-header-right-options li .search-field-module {
          display: flex;
          align-items: center;
          padding: 0 15px; }
          .sticky-header .header-maxi .pro-header-right-options li .search-field-module input {
            float: left;
            padding: 10px;
            height: 40px;
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 0;
            outline: none !important; }
          .sticky-header .header-maxi .pro-header-right-options li .search-field-module .btn {
            padding: 10px;
            height: 40px; }
      .sticky-header .header-maxi .pro-header-right-options .dropdown {
        float: right; }
        .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-toggle::after {
          content: none; }
        .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu {
          animation: animationcartmenu ease 0.5s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
          -webkit-box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1);
          width: 270px;
          border-radius: 0;
          border-color: #fff;
          z-index: 99999;
          margin-top: 33px; }
          .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 17px;
            max-height: 360px;
            overflow-y: auto; }
            .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li:last-child {
              border-bottom: none; }
            .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li {
              float: left;
              position: relative;
              width: 100%;
              margin-bottom: 10px;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;
              margin-left: 0; }
              .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb {
                position: absolute;
                left: 0;
                top: 0; }
                .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image {
                  width: 50px;
                  height: 50px;
                  border: 1px solid #ddd;
                  border-radius: 0;
                  overflow: hidden; }
                  .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image img {
                    transform: translateY(-10%); }
              .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail {
                float: left;
                padding-left: 74px; }
                .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail h2 {
                  display: block;
                  font-family: "Montserrat-Bold", sans-serif;
                  font-size: 0.875rem;
                  white-space: normal;
                  text-transform: capitalize; }
                .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 0.875rem;
                  color: #000; }
                  .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas {
                    color: #000;
                    float: right;
                    margin-left: 8px;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    cursor: pointer; }
                    .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas:hover {
                      color: #b38741; }
            .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .item-summary {
              font-family: "Montserrat-Bold", sans-serif;
              font-size: 1rem;
              color: #000;
              margin-bottom: 0;
              margin-top: 5px;
              white-space: normal; }
            .sticky-header .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .btn {
              padding: 0.675rem 1rem; }
    .sticky-header .header-maxi nav {
      width: 100%;
      padding: 0;
      position: static; }
      .sticky-header .header-maxi nav .navbar-collapse {
        justify-content: center; }
        .sticky-header .header-maxi nav .navbar-collapse ul li {
          padding: 34px 0;
          margin-right: 45px; }
          .sticky-header .header-maxi nav .navbar-collapse ul li .nav-link {
            position: relative;
            font-family: "Montserrat-Bold", sans-serif;
            font-size: 0.8125rem;
            color: #1d1d1d;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0; }
            .sticky-header .header-maxi nav .navbar-collapse ul li .nav-link .badge-secondary {
              font-size: 8px;
              position: absolute;
              top: -12px;
              left: -12px;
              border-radius: 0;
              padding: 2px 3px 2px 3px;
              text-align: center; }
              .sticky-header .header-maxi nav .navbar-collapse ul li .nav-link .badge-secondary:after {
                content: '';
                position: absolute;
                left: 14px;
                top: 10px;
                width: 0;
                height: 0;
                border: 3px solid transparent;
                border-top: 10px solid #b38741;
                transform: rotate(-28deg); }
          .sticky-header .header-maxi nav .navbar-collapse ul li .dropdown-menu {
            animation: animationmenu ease 0.5s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            animation-fill-mode: forwards;
            background-color: #fff;
            margin: 0 0 0 -28px;
            padding: 1.2rem 0;
            border-width: 0;
            -webkit-box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.1); }
            .sticky-header .header-maxi nav .navbar-collapse ul li .dropdown-menu .dropdown-item {
              color: #1d1d1d;
              padding: 5px 1.8rem; }
              .sticky-header .header-maxi nav .navbar-collapse ul li .dropdown-menu .dropdown-item:hover, .sticky-header .header-maxi nav .navbar-collapse ul li .dropdown-menu .dropdown-item.active, .sticky-header .header-maxi nav .navbar-collapse ul li .dropdown-menu .dropdown-item:active {
                background-color: transparent;
                color: #b38741; }
        .sticky-header .header-maxi nav .navbar-collapse ul .dropdown .dropdown-toggle::after {
          color: #000;
          bottom: 6px; }
        .sticky-header .header-maxi nav .navbar-collapse ul .dropdown:hover > .dropdown-menu {
          display: block; }
        .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown {
          position: static !important; }
          .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            margin-left: 0;
            border-top: 1px solid #ddd !important;
            border-bottom: 1px solid #ddd !important;
            width: 100%;
            box-shadow: none; }
            .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu .dropdown-header {
              padding-left: 0;
              font-family: "Montserrat-Bold", sans-serif;
              font-size: 0.875rem;
              margin-bottom: 10px;
              color: #000;
              padding-top: 0; }
            .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul {
              padding-left: 0; }
              .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li {
                padding: 0;
                margin-right: 0;
                list-style: none; }
                .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
                  padding-left: 0; }
          .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu[aria-expanded='true'] {
            display: flex !important;
            align-items: center; }

/*-----------
/----- Header Top bar -------- */
.header-area .alert {
  background-color: #b38741;
  position: relative;
  z-index: 8;
  min-height: 50px;
  padding: 13px 35px 12px;
  margin-bottom: 0;
  border: none; }
  .header-area .alert .pro-description {
    position: relative;
    text-align: center;
    width: 100%; }
    .header-area .alert .pro-description .pro-info {
      font-size: 20px;
      color: #fff;
      line-height: 25px; }
      .header-area .alert .pro-description .pro-info strong {
        font-family: "Montserrat-Bold", sans-serif; }
      .header-area .alert .pro-description .pro-info .pro-link-dropdown.js-toppanel-link-dropdown {
        display: inline-block;
        z-index: 8; }
        .header-area .alert .pro-description .pro-info .pro-link-dropdown.js-toppanel-link-dropdown .pro-dropdown-toggle {
          display: inline-block;
          position: relative;
          background: 0 0;
          border: none;
          font-family: "Montserrat-Bold", sans-serif;
          color: #fff;
          text-decoration: underline;
          padding: 0;
          outline: 0;
          cursor: help; }
    .header-area .alert .pro-description .close {
      font-size: 40px;
      color: #fff;
      font-weight: normal;
      line-height: 24px;
      padding: 0;
      right: -5px;
      text-shadow: none; }

.header-one {
  position: relative;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
  /*-----------
    /----- Header bar -------- */ }
  .header-one .bg-top-bar {
    background-color: #333; }
  .header-one .header-mini .dropdown {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 15px;
    padding-right: 12px; }
    .header-one .header-mini .dropdown .dropdown-toggle {
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: 0.8125rem;
      color: #fff; }
      .header-one .header-mini .dropdown .dropdown-toggle::after {
        color: #fff; }
    .header-one .header-mini .dropdown .dropdown-menu {
      animation: animationmenu ease 0.5s;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards;
      background-color: #fff;
      font-size: 0.8125rem;
      margin: 0 0 0 -28px;
      padding: 1.2rem 0;
      border-width: 0;
      -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
      .header-one .header-mini .dropdown .dropdown-menu .dropdown-item {
        color: #1d1d1d;
        padding: 5px 1.8rem; }
        .header-one .header-mini .dropdown .dropdown-menu .dropdown-item:hover, .header-one .header-mini .dropdown .dropdown-menu .dropdown-item.active, .header-one .header-mini .dropdown .dropdown-menu .dropdown-item:active {
          background-color: transparent;
          color: #b38741; }
    .header-one .header-mini .dropdown:hover > .dropdown-menu {
      display: block; }
  .header-one .header-mini .navbar-lang,
  .header-one .header-mini .pro-header-options {
    display: flex;
    align-items: center; }
  .header-one .header-mini .pro-header-options {
    justify-content: flex-end; }
    .header-one .header-mini .pro-header-options .pro-avatar {
      font-size: 0.8125rem;
      color: #fff; }
    .header-one .header-mini .pro-header-options .dropdown:first-child {
      padding-right: 0; }
    .header-one .header-mini .pro-header-options .dropdown {
      margin-left: 15px;
      margin-right: 0; }
      .header-one .header-mini .pro-header-options .dropdown .dropdown-menu {
        right: 0;
        left: auto; }
  .header-one .bg-header-bar {
    background-color: #fff;
    border-bottom: 1px solid #ddd; }
  .header-one .header-maxi {
    padding: 15px 0; }
    .header-one .header-maxi .search-field-module {
      position: relative;
      background-color: #fff;
      border: 1px solid #ddd;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      width: 80%;
      margin-left: auto;
      margin-right: auto; }
      .header-one .header-maxi .search-field-module:focus {
        border-color: #b38741; }
      .header-one .header-maxi .search-field-module .search-field-select {
        float: left;
        position: relative;
        width: 160px !important;
        height: 50px; }
        .header-one .header-maxi .search-field-module .search-field-select .dropdown {
          padding-left: 1.4rem;
          margin-right: 15px; }
          .header-one .header-maxi .search-field-module .search-field-select .dropdown .dropdown-toggle {
            margin-top: 15px;
            margin-bottom: 15px;
            line-height: 20px;
            font-size: 0.8125rem;
            color: #000; }
            .header-one .header-maxi .search-field-module .search-field-select .dropdown .dropdown-toggle::after {
              color: #000;
              right: -23px; }
          .header-one .header-maxi .search-field-module .search-field-select .dropdown .dropdown-menu {
            animation: animationmenu ease 0.5s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            animation-fill-mode: forwards;
            background-color: #fff;
            font-size: 0.8125rem;
            margin: 0 0 0 0;
            padding: 1.2rem 0;
            border-width: 0;
            -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
            box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
            .header-one .header-maxi .search-field-module .search-field-select .dropdown .dropdown-menu .dropdown-item {
              color: #1d1d1d;
              padding: 5px 1.4rem; }
              .header-one .header-maxi .search-field-module .search-field-select .dropdown .dropdown-menu .dropdown-item:hover, .header-one .header-maxi .search-field-module .search-field-select .dropdown .dropdown-menu .dropdown-item.active, .header-one .header-maxi .search-field-module .search-field-select .dropdown .dropdown-menu .dropdown-item:active {
                background-color: transparent;
                color: #b38741; }
          .header-one .header-maxi .search-field-module .search-field-select .dropdown:hover > .dropdown-menu {
            display: block; }
        .header-one .header-maxi .search-field-module .search-field-select .bootstrap-select {
          width: 100% !important; }
          .header-one .header-maxi .search-field-module .search-field-select .bootstrap-select .btn.dropdown-toggle {
            background-color: #fff;
            padding-left: 1.4rem;
            padding-right: 1.4rem;
            padding-bottom: 0;
            padding-top: 0;
            margin-top: 15px;
            margin-bottom: 15px;
            line-height: 20px;
            font-family: "Montserrat-Regular", sans-serif;
            color: #000;
            text-transform: none;
            width: 100%; }
            .header-one .header-maxi .search-field-module .search-field-select .bootstrap-select .btn.dropdown-toggle .filter-option-inner-inner {
              text-overflow: ellipsis; }
            .header-one .header-maxi .search-field-module .search-field-select .bootstrap-select .btn.dropdown-toggle:focus {
              outline: none !important; }
            .header-one .header-maxi .search-field-module .search-field-select .bootstrap-select .btn.dropdown-toggle::after {
              bottom: 6px;
              right: 11px;
              color: #000; }
        .header-one .header-maxi .search-field-module .search-field-select::before {
          content: '';
          width: 1px;
          height: 25px;
          background: #ddd;
          position: absolute;
          top: 50%;
          margin-top: -12px;
          right: -10px;
          z-index: 1; }
      .header-one .header-maxi .search-field-module .search-field-wrap {
        float: left;
        height: 50px;
        width: calc(100% - 160px); }
        .header-one .header-maxi .search-field-module .search-field-wrap input {
          font-size: 15px;
          float: left;
          padding: 0 55px 0 30px;
          height: 50px;
          width: 100%;
          border-radius: 0;
          border: none;
          outline: none !important;
          font-family: "Montserrat-Regular", sans-serif;
          font-size: 0.8125rem;
          color: #000; }
        .header-one .header-maxi .search-field-module .search-field-wrap .btn-secondary {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 52px;
          padding: 0; }
          .header-one .header-maxi .search-field-module .search-field-wrap .btn-secondary .fa {
            font-size: 15px; }
    .header-one .header-maxi .pro-header-right-options {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px; }
      .header-one .header-maxi .pro-header-right-options li {
        position: relative;
        display: inline-block;
        list-style: none;
        margin-left: 35px; }
        .header-one .header-maxi .pro-header-right-options li a.btn,
        .header-one .header-maxi .pro-header-right-options li button {
          display: flex;
          position: relative;
          padding: 0;
          text-decoration: none; }
          .header-one .header-maxi .pro-header-right-options li a.btn .fas,
          .header-one .header-maxi .pro-header-right-options li a.btn .far,
          .header-one .header-maxi .pro-header-right-options li button .fas,
          .header-one .header-maxi .pro-header-right-options li button .far {
            font-size: 22px;
            color: #1d1d1d;
            -webkit-transition: 0.4s;
            transition: 0.4s; }
            .header-one .header-maxi .pro-header-right-options li a.btn .fas:hover,
            .header-one .header-maxi .pro-header-right-options li a.btn .far:hover,
            .header-one .header-maxi .pro-header-right-options li button .fas:hover,
            .header-one .header-maxi .pro-header-right-options li button .far:hover {
              color: #b38741; }
          .header-one .header-maxi .pro-header-right-options li a.btn .badge,
          .header-one .header-maxi .pro-header-right-options li button .badge {
            padding: 3px 2px 2px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
            height: 16px;
            min-width: 16px;
            color: #fff;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99; }
      .header-one .header-maxi .pro-header-right-options .dropdown {
        float: right; }
        .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-toggle::after {
          content: none; }
        .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu {
          animation: animationcartmenu ease 0.5s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          width: 270px;
          border-radius: 0;
          border-color: #fff;
          z-index: 99999;
          margin-top: 30px; }
          .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 17px;
            max-height: 360px;
            overflow-y: auto; }
            .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li:last-child {
              border-bottom: none; }
            .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li {
              float: left;
              position: relative;
              width: 100%;
              margin-bottom: 10px;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;
              margin-left: 0; }
              .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb {
                position: absolute;
                left: 0;
                top: 0; }
                .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image {
                  width: 50px;
                  height: 50px;
                  border: 1px solid #ddd;
                  border-radius: 0;
                  overflow: hidden; }
                  .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image img {
                    transform: translateY(-10%); }
              .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail {
                float: left;
                padding-left: 64px; }
                .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail h2 {
                  display: block;
                  font-family: "Montserrat-Bold", sans-serif;
                  font-size: 0.875rem;
                  white-space: normal;
                  text-transform: capitalize; }
                .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 0.875rem;
                  color: #000; }
                  .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas {
                    color: #000;
                    float: right;
                    margin-left: 8px;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    cursor: pointer; }
                    .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas:hover {
                      color: #b38741; }
            .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .item-summary {
              font-family: "Montserrat-Bold", sans-serif;
              font-size: 1rem;
              color: #000;
              margin-bottom: 0;
              margin-top: 5px;
              white-space: normal; }
            .header-one .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .btn {
              display: block;
              padding: 0.675rem 1rem; }
  .header-one .bg-menu-bar {
    background-color: #fff; }
  .header-one .bg-nav-bar {
    background-color: #fff; }
  .header-one .header-navbar nav {
    width: 100%;
    padding: 0; }
    .header-one .header-navbar nav .navbar-collapse {
      justify-content: center; }
      .header-one .header-navbar nav .navbar-collapse ul li {
        padding: 15px 14px 15px 0;
        margin-right: 45px; }
        .header-one .header-navbar nav .navbar-collapse ul li .nav-link {
          position: relative;
          font-family: "Montserrat-Bold", sans-serif;
          font-size: 0.8125rem;
          color: #1d1d1d;
          text-transform: uppercase;
          padding: 0; }
          .header-one .header-navbar nav .navbar-collapse ul li .nav-link .badge-secondary {
            position: absolute;
            top: -13px;
            left: -12px;
            border-radius: 0;
            padding: 3px 3px 2px 3px;
            text-align: center; }
            .header-one .header-navbar nav .navbar-collapse ul li .nav-link .badge-secondary:after {
              content: '';
              position: absolute;
              left: 14px;
              top: 12px;
              width: 0;
              height: 0;
              border: 3px solid transparent;
              border-top: 10px solid #b38741;
              transform: rotate(-28deg); }
        .header-one .header-navbar nav .navbar-collapse ul li:last-child {
          margin-right: 0; }
        .header-one .header-navbar nav .navbar-collapse ul li .dropdown-menu {
          animation: animationmenu ease 0.5s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-fill-mode: forwards;
          background-color: #fff;
          font-size: 0.875rem;
          margin: 0 0 0 -28px;
          padding: 1.2rem 0;
          border-width: 0;
          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
          .header-one .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item {
            color: #1d1d1d;
            padding: 5px 1.8rem; }
            .header-one .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item:hover, .header-one .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item.active, .header-one .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item:active {
              background-color: transparent;
              color: #b38741; }
      .header-one .header-navbar nav .navbar-collapse ul .dropdown .dropdown-toggle::after {
        color: #000;
        bottom: 6px; }
      .header-one .header-navbar nav .navbar-collapse ul .dropdown:hover > .dropdown-menu {
        display: block; }
      .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown {
        position: static !important; }
        .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu {
          padding-left: 1.2rem;
          padding-right: 1.2rem;
          margin-left: 0;
          width: 100%; }
          .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu .dropdown-header {
            padding-left: 0;
            font-family: "Montserrat-Bold", sans-serif;
            font-size: 0.875rem;
            margin-bottom: 10px;
            color: #000;
            padding-top: 0; }
          .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul {
            padding-left: 0; }
            .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li {
              padding: 0;
              margin-right: 0;
              list-style: none; }
              .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
                padding-left: 0; }
        .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu[aria-expanded='true'] {
          display: flex !important;
          align-items: center; }

.dropdown-submenu {
  position: relative; }
  .dropdown-submenu .fas {
    font-size: 13px;
    color: #fff;
    margin-top: 5px;
    float: right; }
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 0 !important;
    margin-top: -19px !important; }
  .dropdown-submenu:hover > .dropdown-menu {
    display: block; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-one .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
    white-space: normal; } }

@media only screen and (max-width: 991px) {
  .header-area .alert .pro-description .pro-info {
    font-size: 14px; }
  .header-area .alert .pro-description .close {
    right: -40px; } }

/*---------------- Header Top bar -------- */
.header-two {
  /*-----------
        /----- Header bar -------- */ }
  .header-two .bg-top-bar {
    background-color: #f8f9fa; }
  .header-two .header-mini h4 {
    font-size: 0.8125rem;
    color: #1d1d1d;
    margin: 0; }
  .header-two .header-mini .dropdown {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
    padding-right: 12px; }
    .header-two .header-mini .dropdown .dropdown-toggle {
      font-size: 0.8125rem;
      color: #1d1d1d; }
      .header-two .header-mini .dropdown .dropdown-toggle::after {
        color: #1d1d1d; }
    .header-two .header-mini .dropdown .dropdown-menu {
      animation: animationmenu ease 0.5s;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards;
      background-color: #fff;
      font-size: 0.8125rem;
      margin: 0 0 0 -28px;
      padding: 1.2rem 0;
      border-width: 0;
      -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
      .header-two .header-mini .dropdown .dropdown-menu .dropdown-item {
        color: #1d1d1d;
        padding: 5px 1.8rem; }
        .header-two .header-mini .dropdown .dropdown-menu .dropdown-item:hover, .header-two .header-mini .dropdown .dropdown-menu .dropdown-item.active, .header-two .header-mini .dropdown .dropdown-menu .dropdown-item:active {
          background-color: transparent;
          color: #b38741; }
    .header-two .header-mini .dropdown:hover > .dropdown-menu {
      display: block; }
  .header-two .header-mini .navbar-lang,
  .header-two .header-mini .pro-header-options {
    display: flex;
    align-items: center; }
  .header-two .header-mini .pro-header-options {
    justify-content: flex-end; }
    .header-two .header-mini .pro-header-options .dropdown .dropdown-menu {
      right: 0;
      left: auto; }
  .header-two .bg-header-bar {
    background-color: #fff; }
  .header-two .header-maxi {
    padding: 0 0; }
    .header-two .header-maxi .pro-header-right-options {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px; }
      .header-two .header-maxi .pro-header-right-options li {
        position: relative;
        display: inline-block;
        list-style: none;
        margin-left: 35px; }
        .header-two .header-maxi .pro-header-right-options li > a,
        .header-two .header-maxi .pro-header-right-options li button {
          position: relative;
          padding: 0;
          text-decoration: none; }
          .header-two .header-maxi .pro-header-right-options li > a .fas,
          .header-two .header-maxi .pro-header-right-options li > a .far,
          .header-two .header-maxi .pro-header-right-options li button .fas,
          .header-two .header-maxi .pro-header-right-options li button .far {
            font-size: 22px;
            color: #1d1d1d;
            -webkit-transition: 0.4s;
            transition: 0.4s; }
            .header-two .header-maxi .pro-header-right-options li > a .fas:hover,
            .header-two .header-maxi .pro-header-right-options li > a .far:hover,
            .header-two .header-maxi .pro-header-right-options li button .fas:hover,
            .header-two .header-maxi .pro-header-right-options li button .far:hover {
              color: #b38741; }
          .header-two .header-maxi .pro-header-right-options li > a .badge,
          .header-two .header-maxi .pro-header-right-options li button .badge {
            padding: 3px 2px 2px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
            height: 16px;
            min-width: 16px;
            color: #fff;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99; }
        .header-two .header-maxi .pro-header-right-options li .pt-col {
          display: flex;
          align-items: center;
          padding: 5px 15px; }
          .header-two .header-maxi .pro-header-right-options li .pt-col input {
            width: 88%;
            text-decoration: none;
            outline: none;
            padding: 2px 5px; }
          .header-two .header-maxi .pro-header-right-options li .pt-col .pt-btn-search {
            margin-left: 15px; }
      .header-two .header-maxi .pro-header-right-options .dropdown {
        float: right; }
        .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-toggle::after {
          content: none; }
        .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu {
          animation: animationcartmenu ease 0.5s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          width: 270px;
          border-radius: 0;
          border-color: #fff;
          z-index: 99999;
          margin-top: 33px; }
          .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 17px;
            max-height: 360px;
            overflow-y: auto; }
            .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li:last-child {
              border-bottom: none; }
            .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li {
              float: left;
              position: relative;
              width: 100%;
              margin-bottom: 10px;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;
              margin-left: 0; }
              .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb {
                position: absolute;
                left: 0;
                top: 0; }
                .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image {
                  width: 50px;
                  height: 50px;
                  border: 1px solid #ddd;
                  border-radius: 0;
                  overflow: hidden; }
              .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail {
                float: left;
                padding-left: 64px; }
                .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail h2 {
                  display: block;
                  font-family: "Montserrat-Bold", sans-serif;
                  font-size: 0.875rem;
                  white-space: normal;
                  text-transform: capitalize; }
                .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 0.875rem;
                  color: #000; }
                  .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas {
                    color: #000;
                    float: right;
                    margin-left: 8px;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    cursor: pointer; }
                    .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas:hover {
                      color: #b38741; }
            .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .item-summary {
              font-family: "Montserrat-Bold", sans-serif;
              font-size: 1rem;
              color: #000;
              margin-bottom: 0;
              margin-top: 5px;
              white-space: normal; }
            .header-two .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .btn {
              padding: 0.675rem 1rem; }
    .header-two .header-maxi .nav-start {
      position: static; }
      .header-two .header-maxi .nav-start nav {
        width: 100%;
        padding: 0; }
        .header-two .header-maxi .nav-start nav .navbar-collapse {
          justify-content: center; }
          .header-two .header-maxi .nav-start nav .navbar-collapse ul li {
            padding: 34px 0;
            margin-right: 45px; }
            .header-two .header-maxi .nav-start nav .navbar-collapse ul li .nav-link {
              position: relative;
              font-family: "Montserrat-Bold", sans-serif;
              font-size: 0.8125rem;
              color: #1d1d1d;
              text-transform: uppercase;
              padding: 0; }
              .header-two .header-maxi .nav-start nav .navbar-collapse ul li .nav-link .badge-secondary {
                position: absolute;
                top: -13px;
                left: -12px;
                border-radius: 0;
                padding: 3px 3px 2px 3px;
                text-align: center; }
                .header-two .header-maxi .nav-start nav .navbar-collapse ul li .nav-link .badge-secondary:after {
                  content: '';
                  position: absolute;
                  left: 14px;
                  top: 12px;
                  width: 0;
                  height: 0;
                  border: 3px solid transparent;
                  border-top: 10px solid #b38741;
                  transform: rotate(-28deg); }
            .header-two .header-maxi .nav-start nav .navbar-collapse ul li:last-child {
              margin-right: 0; }
            .header-two .header-maxi .nav-start nav .navbar-collapse ul li .dropdown-menu {
              animation: animationmenu ease 0.5s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
              background-color: #fff;
              font-size: 0.875rem;
              margin: 0 0 0 -28px;
              padding: 1.2rem 0;
              border-width: 0;
              -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
              box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
              .header-two .header-maxi .nav-start nav .navbar-collapse ul li .dropdown-menu .dropdown-item {
                color: #1d1d1d;
                padding: 5px 1.8rem; }
                .header-two .header-maxi .nav-start nav .navbar-collapse ul li .dropdown-menu .dropdown-item:hover, .header-two .header-maxi .nav-start nav .navbar-collapse ul li .dropdown-menu .dropdown-item.active, .header-two .header-maxi .nav-start nav .navbar-collapse ul li .dropdown-menu .dropdown-item:active {
                  background-color: transparent;
                  color: #b38741; }
          .header-two .header-maxi .nav-start nav .navbar-collapse ul .dropdown .dropdown-toggle::after {
            color: #000;
            bottom: 6px; }
          .header-two .header-maxi .nav-start nav .navbar-collapse ul .dropdown:hover > .dropdown-menu {
            display: block; }
          .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown {
            position: static !important; }
            .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu {
              padding-left: 1.2rem;
              padding-right: 1.2rem;
              width: 152%;
              margin-left: -26%; }
              .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu .dropdown-header {
                padding-left: 0;
                font-family: "Montserrat-Bold", sans-serif;
                font-size: 0.875rem;
                margin-bottom: 10px;
                color: #000;
                padding-top: 0; }
              .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul {
                padding-left: 0; }
                .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li {
                  padding: 0;
                  margin-right: 0;
                  list-style: none; }
                  .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
                    padding-left: 0; }
            .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu[aria-expanded='true'] {
              display: flex !important;
              align-items: center; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-two .header-maxi .nav-start nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
    white-space: normal; } }

/*-----------
/----- Header Top bar -------- */
.header-three {
  position: relative;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
  /*-----------
    /----- Header bar -------- */ }
  .header-three .bg-header-bar {
    background-color: #fff;
    border-bottom: 1px solid #ddd; }
  .header-three .header-maxi {
    padding: 25px 0; }
    .header-three .header-maxi .navbar-lang {
      display: flex;
      align-items: center; }
      .header-three .header-maxi .navbar-lang .dropdown {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 15px;
        padding-right: 12px; }
        .header-three .header-maxi .navbar-lang .dropdown .dropdown-toggle {
          font-size: 0.8125rem;
          color: #1d1d1d; }
          .header-three .header-maxi .navbar-lang .dropdown .dropdown-toggle::after {
            color: #1d1d1d; }
        .header-three .header-maxi .navbar-lang .dropdown .dropdown-menu {
          animation: animationmenu ease 0.5s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-fill-mode: forwards;
          background-color: #fff;
          font-size: 0.8125rem;
          margin: 0 0 0 -28px;
          padding: 1.2rem 0;
          border-width: 0;
          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
          .header-three .header-maxi .navbar-lang .dropdown .dropdown-menu .dropdown-item {
            color: #1d1d1d;
            padding: 5px 1.8rem; }
            .header-three .header-maxi .navbar-lang .dropdown .dropdown-menu .dropdown-item:hover, .header-three .header-maxi .navbar-lang .dropdown .dropdown-menu .dropdown-item.active, .header-three .header-maxi .navbar-lang .dropdown .dropdown-menu .dropdown-item:active {
              background-color: transparent;
              color: #b38741; }
        .header-three .header-maxi .navbar-lang .dropdown:hover > .dropdown-menu {
          display: block; }
    .header-three .header-maxi .pro-logo {
      text-align: center; }
    .header-three .header-maxi .pro-header-right-options {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-searchbar {
        animation: animationmenu ease 0.5s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards;
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important;
        background-color: #f8f9fa;
        z-index: 9;
        margin-top: 0 !important; }
        .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-searchbar form {
          position: relative; }
      .header-three .header-maxi .pro-header-right-options li {
        position: relative;
        display: inline-block;
        list-style: none;
        margin-left: 35px; }
        .header-three .header-maxi .pro-header-right-options li > a,
        .header-three .header-maxi .pro-header-right-options li button {
          position: relative;
          padding: 0;
          text-decoration: none;
          outline: none;
          z-index: 1; }
          .header-three .header-maxi .pro-header-right-options li > a .fas,
          .header-three .header-maxi .pro-header-right-options li > a .far,
          .header-three .header-maxi .pro-header-right-options li button .fas,
          .header-three .header-maxi .pro-header-right-options li button .far {
            font-size: 22px;
            color: #1d1d1d;
            -webkit-transition: 0.4s;
            transition: 0.4s; }
            .header-three .header-maxi .pro-header-right-options li > a .fas:hover,
            .header-three .header-maxi .pro-header-right-options li > a .far:hover,
            .header-three .header-maxi .pro-header-right-options li button .fas:hover,
            .header-three .header-maxi .pro-header-right-options li button .far:hover {
              color: #b38741; }
          .header-three .header-maxi .pro-header-right-options li > a .badge,
          .header-three .header-maxi .pro-header-right-options li button .badge {
            padding: 3px 2px 2px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
            height: 16px;
            min-width: 16px;
            color: #fff;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99; }
        .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar {
          padding: 28px 0; }
          .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar .close {
            outline: none;
            font-size: 40px;
            font-weight: normal;
            line-height: 22px;
            text-shadow: none; }
          .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar .pro-col .pro-info-text {
            position: relative; }
          .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar .pro-col .pro-search-wrap {
            position: relative; }
            .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar .pro-col .pro-search-wrap .pro-search-input {
              width: 100%;
              text-decoration: none;
              outline: none;
              font-family: "Montserrat-Bold", sans-serif;
              font-size: 24px;
              border: none;
              padding: 11px 12px 13px 0;
              background-color: #f8f9fa;
              border-bottom: 1px solid #ddd; }
            .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar .pro-col .pro-search-wrap .pro-btn-search {
              float: right;
              padding-top: 3px;
              font-size: 30px;
              line-height: 1;
              position: absolute;
              cursor: pointer;
              bottom: 16px;
              right: 0; }
      .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-toggle::after {
        content: none; }
      .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu {
        animation: animationcartmenu ease 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
        width: 270px;
        border-radius: 0;
        border-color: #fff;
        z-index: 99999;
        margin-top: 28px; }
        .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items {
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 17px;
          max-height: 360px;
          overflow-y: auto; }
          .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li:last-child {
            border-bottom: none; }
          .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li {
            float: left;
            position: relative;
            width: 100%;
            margin-bottom: 10px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 10px;
            margin-left: 0; }
            .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb {
              position: absolute;
              left: 0;
              top: 0; }
              .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image {
                width: 50px;
                height: 50px;
                border: 1px solid #ddd;
                border-radius: 0;
                overflow: hidden; }
            .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail {
              float: left;
              padding-left: 64px; }
              .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail h2 {
                display: block;
                font-family: "Montserrat-Bold", sans-serif;
                font-size: 0.875rem;
                white-space: normal;
                text-transform: capitalize; }
              .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.875rem;
                color: #000; }
                .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas {
                  color: #000;
                  float: right;
                  margin-left: 8px;
                  -webkit-transition: 0.4s;
                  transition: 0.4s;
                  cursor: pointer; }
                  .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas:hover {
                    color: #b38741; }
          .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .item-summary {
            font-family: "Montserrat-Bold", sans-serif;
            font-size: 1rem;
            color: #000;
            margin-bottom: 0;
            margin-top: 5px;
            white-space: normal; }
          .header-three .header-maxi .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items .btn {
            padding: 0.675rem 1rem; }
  .header-three .bg-menu-bar {
    background-color: #fff; }
  .header-three .bg-nav-bar {
    background-color: #fff; }
  .header-three .header-navbar nav {
    width: 100%;
    padding: 0; }
    .header-three .header-navbar nav .navbar-collapse {
      justify-content: center; }
      .header-three .header-navbar nav .navbar-collapse ul li {
        padding: 15px 0;
        margin-right: 45px; }
        .header-three .header-navbar nav .navbar-collapse ul li .nav-link {
          position: relative;
          font-family: "Montserrat-Bold", sans-serif;
          font-size: 0.8125rem;
          color: #1d1d1d;
          text-transform: uppercase;
          padding: 0; }
          .header-three .header-navbar nav .navbar-collapse ul li .nav-link .badge-secondary {
            position: absolute;
            top: -13px;
            left: -12px;
            border-radius: 0;
            padding: 3px 3px 2px 3px;
            text-align: center; }
            .header-three .header-navbar nav .navbar-collapse ul li .nav-link .badge-secondary:after {
              content: '';
              position: absolute;
              left: 14px;
              top: 12px;
              width: 0;
              height: 0;
              border: 3px solid transparent;
              border-top: 10px solid #b38741;
              transform: rotate(-28deg); }
        .header-three .header-navbar nav .navbar-collapse ul li:last-child {
          margin-right: 0; }
        .header-three .header-navbar nav .navbar-collapse ul li .dropdown-menu {
          animation: animationmenu ease 0.5s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-fill-mode: forwards;
          background-color: #fff;
          font-size: 0.875rem;
          margin: 0 0 0 -28px;
          padding: 1.2rem 0;
          border-width: 0;
          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
          .header-three .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item {
            color: #1d1d1d;
            padding: 5px 1.8rem; }
            .header-three .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item:hover, .header-three .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item.active, .header-three .header-navbar nav .navbar-collapse ul li .dropdown-menu .dropdown-item:active {
              background-color: transparent;
              color: #b38741; }
      .header-three .header-navbar nav .navbar-collapse ul .dropdown .dropdown-toggle::after {
        color: #000;
        bottom: 6px; }
      .header-three .header-navbar nav .navbar-collapse ul .dropdown:hover > .dropdown-menu {
        display: block; }
      .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown {
        position: static !important; }
        .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu {
          padding-left: 1.2rem;
          padding-right: 1.2rem;
          margin-left: 0;
          width: 100%; }
          .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu .dropdown-header {
            padding-left: 0;
            font-family: "Montserrat-Bold", sans-serif;
            font-size: 0.875rem;
            margin-bottom: 10px;
            color: #000;
            padding-top: 0; }
          .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul {
            padding-left: 0; }
            .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li {
              padding: 0;
              margin-right: 0;
              list-style: none; }
              .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
                padding-left: 0; }
        .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu[aria-expanded='true'] {
          display: flex !important;
          align-items: center; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-three .header-navbar nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
    white-space: normal; } }

/*-----------
/----- Header Top bar -------- */
.header-mobile {
  /*-----------
    /----- Header bar -------- */ }
  .header-mobile .bg-top-bar {
    background-color: #333; }
  .header-mobile .header-mini {
    min-height: 26px; }
    .header-mobile .header-mini .navbar-lang {
      display: flex;
      justify-content: center; }
      .header-mobile .header-mini .navbar-lang .dropdown {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 15px;
        padding-right: 12px; }
        .header-mobile .header-mini .navbar-lang .dropdown .dropdown-toggle {
          font-size: 0.8125rem;
          color: #fff; }
          .header-mobile .header-mini .navbar-lang .dropdown .dropdown-toggle::after {
            color: #fff; }
        .header-mobile .header-mini .navbar-lang .dropdown .dropdown-menu {
          animation: animationmenu ease 0.5s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-fill-mode: forwards;
          background-color: #fff;
          font-size: 0.8125rem;
          margin: 0 0 0 -28px;
          padding: 1.2rem 0;
          border-width: 0;
          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1); }
          .header-mobile .header-mini .navbar-lang .dropdown .dropdown-menu .dropdown-item {
            color: #1d1d1d;
            padding: 5px 1.8rem; }
            .header-mobile .header-mini .navbar-lang .dropdown .dropdown-menu .dropdown-item:hover, .header-mobile .header-mini .navbar-lang .dropdown .dropdown-menu .dropdown-item.active, .header-mobile .header-mini .navbar-lang .dropdown .dropdown-menu .dropdown-item:active {
              background-color: transparent;
              color: #b38741; }
        .header-mobile .header-mini .navbar-lang .dropdown:hover .dropdown-menu {
          display: block; }
  .header-mobile .bg-header-bar {
    background-color: #fff; }
  .header-mobile .header-maxi {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
    .header-mobile .header-maxi .navigation-mobile-container {
      margin-left: -8px; }
      .header-mobile .header-maxi .navigation-mobile-container .navigation-mobile-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        padding: 0.55rem 0.75rem;
        font-size: 1rem;
        line-height: 1;
        border-radius: 0; }
        .header-mobile .header-maxi .navigation-mobile-container .navigation-mobile-toggler .fas {
          font-size: 20px;
          color: #1d1d1d; }
      .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile {
        height: 100%;
        width: 250px;
        position: fixed;
        z-index: 999;
        top: 0;
        left: -250px;
        background-color: #fafafa;
        overflow-x: hidden;
        transition: 0.5s; }
        .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .logout-main {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 26px 15px;
          position: relative;
          background-color: #fff;
          width: 250px;
          border-bottom: 1px solid #ddd; }
          .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .logout-main .welcome {
            font-family: "Montserrat-Bold", sans-serif;
            color: #1d1d1d;
            font-size: 0.824rem; }
          .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .logout-main .logout a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e6e6e6;
            font-size: 0.8125rem;
            color: #1d1d1d;
            width: 70px;
            height: 26px;
            border-radius: 15px; }
        .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile a[aria-expanded='true']:after {
          content: '\f077';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color: #000;
          font-size: 0.8125rem;
          position: absolute;
          right: 20px; }
        .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile a[aria-expanded='false']:after {
          content: '\f078';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color: #000;
          font-size: 0.8125rem;
          position: absolute;
          right: 20px; }
        .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .main-manu {
          font-family: "Montserrat-Regular", sans-serif;
          font-size: 0.8125rem;
          width: 250px;
          text-align: left;
          border-bottom: 1px solid #ddd; }
        .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu .unorder-list {
          padding: 0;
          margin-bottom: 0; }
          .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu .unorder-list li {
            list-style: none; }
            .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu .unorder-list li a {
              width: 100%;
              text-align: left;
              padding-left: 45px; }
            .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu .unorder-list li .sub-manu1 a {
              padding-left: 60px; }
        .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile.navigation-active {
          left: 0; }
    .header-mobile .header-maxi .logo {
      width: 176px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
      padding-top: 4px;
      text-decoration: none;
      margin-left: auto;
      margin-right: auto; }
      .header-mobile .header-maxi .logo strong {
        color: #b38741; }
    .header-mobile .header-maxi .cart-dropdown {
      float: right;
      position: relative;
      z-index: 9; }
      .header-mobile .header-maxi .cart-dropdown .cart-dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        padding: 0.55rem 0.75rem;
        font-size: 1rem;
        line-height: 1;
        border-radius: 0; }
        .header-mobile .header-maxi .cart-dropdown .cart-dropdown-toggle .fas {
          color: #1d1d1d;
          font-size: 20px; }
        .header-mobile .header-maxi .cart-dropdown .cart-dropdown-toggle .badge {
          position: absolute;
          right: -3px;
          top: -2px; }
      .header-mobile .header-maxi .cart-dropdown .dropdown-menu {
        animation: animationcartmenu ease 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
        width: 270px;
        border-radius: 0;
        border-color: #fff;
        z-index: 99999;
        margin-top: 30px; }
        .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items {
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 17px;
          max-height: 350px;
          overflow-y: auto;
          margin-bottom: 0; }
          .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li:last-child {
            border-bottom: none; }
          .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li {
            list-style: none;
            float: left;
            position: relative;
            width: 100%;
            margin-bottom: 10px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 10px;
            margin-left: 0; }
            .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-thumb {
              position: absolute;
              left: 0;
              top: 0; }
              .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image {
                width: 50px;
                height: 50px;
                border: 1px solid #ddd;
                border-radius: 0;
                overflow: hidden; }
                .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-thumb .image img {
                  transform: translateY(-10%); }
            .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-detail {
              float: left;
              padding-left: 64px; }
              .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-detail h2 {
                display: block;
                font-family: "Montserrat-Bold", sans-serif;
                font-size: 0.875rem;
                white-space: normal;
                text-transform: capitalize; }
              .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.875rem;
                color: #000; }
                .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas {
                  color: #000;
                  float: right;
                  margin-left: 8px;
                  -webkit-transition: 0.4s;
                  transition: 0.4s; }
                  .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas:hover {
                    color: #b38741; }
          .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items .item-summary {
            font-family: "Montserrat-Bold", sans-serif;
            font-size: 1rem;
            color: #000;
            margin-bottom: 0;
            margin-top: 5px;
            white-space: normal; }
          .header-mobile .header-maxi .cart-dropdown .dropdown-menu .shopping-cart-items .btn {
            padding: 0.675rem 1rem; }
  .header-mobile .bg-menu-bar {
    background-color: #fff; }
  .header-mobile .bg-nav-bar {
    background-color: #fff; }
  .header-mobile .header-navbar {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
    .header-mobile .header-navbar .form-inline {
      display: flex;
      flex-flow: row wrap;
      align-items: center; }
      .header-mobile .header-navbar .form-inline .search-field-module {
        position: relative;
        background-color: #fff;
        border: 1px solid #ddd;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        width: 100%; }
        .header-mobile .header-navbar .form-inline .search-field-module:focus {
          border-color: #b38741; }
        .header-mobile .header-navbar .form-inline .search-field-module .search-field-select {
          float: left;
          position: relative;
          width: 80px !important;
          height: 50px; }
          .header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
            height: 100%;
            border: none;
            padding: 0 0 0 15px;
            width: 80px; }
          .header-mobile .header-navbar .form-inline .search-field-module .search-field-select::before {
            content: '';
            width: 1px;
            height: 25px;
            background: #ddd;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: -10px;
            z-index: 1; }
        .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap {
          float: left;
          height: 50px;
          width: calc(100% - 80px); }
          .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap input {
            float: left;
            padding: 0 55px 0 20px;
            height: 50px;
            width: 100%;
            border-radius: 0;
            border: none;
            outline: none !important; }
          .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap .btn-secondary {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 52px;
            padding: 0; }

.mobile-overlay {
  display: none;
  top: 0;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 998;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out; }

/* display .overlay when it has the .active class */
.mobile-overlay.active {
  display: block;
  opacity: 1; }

.rtl .header-mobile .header-mini .navbar-lang .select-control.currency {
  border-right: 1px solid #dee2e6;
  border-left: none; }

.rtl .header-mobile .header-mini .navbar-lang .select-control {
  margin-right: 10px !important;
  margin-left: 0 !important; }
  .rtl .header-mobile .header-mini .navbar-lang .select-control .form-control {
    padding-left: 0 !important;
    padding-right: 0.75rem !important; }

.rtl .header-mobile .header-maxi .navigation-mobile-container {
  margin-right: 15px; }
  .rtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile {
    right: -250px;
    left: auto; }
    .rtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .logout-main .logout a {
      left: 0.75rem;
      right: auto; }
    .rtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .main-manu {
      text-align: right; }
      .rtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .main-manu span {
        float: left; }
    .rtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu a {
      text-align: right;
      padding-left: 0.75rem;
      padding-right: 30px; }
      .rtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu a span {
        float: left; }
    .rtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile.navigation-active {
      left: auto;
      right: 0; }

.rtl .header-mobile .header-maxi .cart-dropdown {
  margin-left: 15px;
  float: left; }
  .rtl .header-mobile .header-maxi .cart-dropdown .cart-dropdown-toggle .badge {
    left: -5px;
    right: auto; }
  .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items {
    padding-right: 0; }
    .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .item-thumb {
      right: 0;
      left: auto; }
      .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .item-thumb .icon {
        left: -5px;
        right: auto; }
    .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .item-detail {
      padding-left: 0;
      padding-right: 74px;
      text-align: right; }
      .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .item-detail .item-quantity {
        float: right;
        margin-right: 0;
        margin-left: 10px; }
      .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .item-detail .item-price {
        float: left;
        margin-left: 0; }
    .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .tt-summary {
      text-align: right; }
      .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .tt-summary span {
        float: left; }
    .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .buttons .btn:first-child {
      margin-right: 0;
      margin-left: 5px; }
    .rtl .header-mobile .header-maxi .cart-dropdown .shopping-cart-items .buttons .btn:last-child {
      margin-right: 5px;
      margin-left: 0; }

.rtl .header-mobile .header-navbar .form-inline .search .select-control {
  float: right;
  border-right: none;
  border-left: 1px solid #dee2e6; }

.rtl .header-mobile .header-navbar .form-inline .search .select-control::before {
  left: 10px;
  right: auto; }

.rtl .header-mobile .header-navbar .form-inline .search input {
  font-size: 12px;
  float: right;
  height: 30px;
  padding: 8px 12px 8px 12px;
  width: calc(100% - 98px);
  border-radius: 0;
  border: none;
  outline: none !important; }

.rtl .header-mobile .header-navbar .form-inline .search .btn-secondary {
  left: 1px;
  right: auto; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-mobile .header-navbar .form-inline .search-field-module .search-field-select {
    width: 150px !important; }
    .header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
      width: 150px; }
  .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap {
    width: calc(100% - 150px); } }

@media only screen and (max-width: 1024px) {
  .header-mobile .header-maxi .navigation-mobile-container .navigation-mobile-toggler {
    margin-right: 8px; }
  .header-mobile .header-maxi .cart-dropdown .cart-dropdown-toggle .badge {
    right: 0; }
  .bodyrtl .header-mobile .header-maxi .cart-dropdown {
    float: left;
    margin-left: 15px; }
    .bodyrtl .header-mobile .header-maxi .cart-dropdown .cart-dropdown-toggle .badge {
      left: 0;
      right: auto; }
  .bodyrtl .header-mobile .header-mini .navbar-lang .dropdown {
    margin-left: 15px;
    margin-right: 0;
    padding-right: 0;
    padding-left: 12px; }
    .bodyrtl .header-mobile .header-mini .navbar-lang .dropdown .dropdown-menu {
      text-align: right;
      right: -28px;
      left: auto; } }

.footer-one {
  margin-top: 100px;
  background-color: #f8f9fa; }
  .footer-one .single-footer {
    padding-bottom: 100px;
    padding-top: 100px; }
    .footer-one .single-footer h5 {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 1rem;
      color: #000;
      margin-bottom: 15px;
      margin-top: -4px; }
    .footer-one .single-footer .pro-about ul li {
      color: #000;
      font-size: 0.875rem; }
      .footer-one .single-footer .pro-about ul li img {
        width: 120px;
        margin-bottom: 8px; }
    .footer-one .single-footer ul {
      margin-bottom: -5px !important; }
      .footer-one .single-footer ul li {
        display: block;
        width: 100%;
        list-style: none;
        font-size: 0.875rem;
        margin-bottom: 10px; }
        .footer-one .single-footer ul li:last-child {
          margin-bottom: 0; }
        .footer-one .single-footer ul li img {
          margin-bottom: 5px; }
        .footer-one .single-footer ul li span {
          display: block; }
        .footer-one .single-footer ul li a {
          display: block;
          color: #000; }
          .footer-one .single-footer ul li a:hover {
            text-decoration: none;
            color: #b38741; }
    .footer-one .single-footer .pro-socials ul {
      display: flex;
      padding-left: 0;
      margin-bottom: 0 !important; }
      .footer-one .single-footer .pro-socials ul li {
        font-size: 1.5rem;
        width: auto;
        margin-right: 15px;
        list-style: none;
        margin-bottom: 0; }
        .footer-one .single-footer .pro-socials ul li a {
          color: #000; }
          .footer-one .single-footer .pro-socials ul li a.fb:hover {
            color: #3b5998; }
          .footer-one .single-footer .pro-socials ul li a.tw:hover {
            color: #00acee; }
          .footer-one .single-footer .pro-socials ul li a.sk:hover {
            color: #00aff0; }
          .footer-one .single-footer .pro-socials ul li a.ln:hover {
            color: #0077b5; }
          .footer-one .single-footer .pro-socials ul li a.ig:hover {
            color: #3f729b; }
    .footer-one .single-footer .pro-newsletter {
      margin-bottom: 24px; }
      .footer-one .single-footer .pro-newsletter #mc_embed_Newletter_footer {
        margin-bottom: 15px; }
        .footer-one .single-footer .pro-newsletter #mc_embed_Newletter_footer .response,
        .footer-one .single-footer .pro-newsletter #mc_embed_Newletter_footer .mce_inline_error {
          margin-top: 15px; }
        .footer-one .single-footer .pro-newsletter #mc_embed_Newletter_footer #mc_embed_Newletter_footer_scroll {
          position: relative; }
          .footer-one .single-footer .pro-newsletter #mc_embed_Newletter_footer #mc_embed_Newletter_footer_scroll .btn {
            position: absolute;
            right: 0;
            top: 0; }
      .footer-one .single-footer .pro-newsletter .search {
        position: relative;
        margin-bottom: 15px;
        width: 100%; }
        .footer-one .single-footer .pro-newsletter .search input {
          float: right;
          height: 40px;
          padding: 8px 98px 8px 10px;
          width: 100%;
          border-radius: 0;
          outline: none !important;
          border: 1px solid #ddd; }
        .footer-one .single-footer .pro-newsletter .search .btn {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 46px;
          padding: 0; }
      .footer-one .single-footer .pro-newsletter p {
        font-size: 0.8125rem;
        color: #000; }
  .footer-one .copyright-content {
    background-color: #333;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px; }
    .footer-one .copyright-content .footer-info {
      text-align: center;
      font-size: 0.8125rem; }
      .footer-one .copyright-content .footer-info a {
        color: #fff; }
        .footer-one .copyright-content .footer-info a:hover {
          color: #b38741; }

.alert-cookie {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #333;
  color: #fff;
  margin-bottom: 0;
  width: 100%;
  padding: 30px;
  border: none;
  z-index: 10; }
  .alert-cookie .btn-link {
    color: #fff;
    text-decoration: underline; }
    .alert-cookie .btn-link:hover, .alert-cookie .btn-link:focus {
      color: #b38741; }
  .alert-cookie .btn {
    float: right; }

.bodyrtl .alert-cookie .btn {
  float: left; }

@media only screen and (max-width: 767px) {
  .alert-cookie {
    text-align: center; }
    .alert-cookie .btn {
      margin-top: 15px;
      float: none; } }

.footer-two {
  margin-top: 100px;
  background-color: #f8f9fa; }
  .footer-two .newsletter-content {
    background-color: #fff; }
    .footer-two .newsletter-content .pro-heading-title {
      padding-bottom: 35px;
      margin-top: -7px; }
      .footer-two .newsletter-content .pro-heading-title h2 {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 32px;
        text-align: center;
        line-height: 38px;
        margin-bottom: 0; }
      .footer-two .newsletter-content .pro-heading-title p {
        color: #000;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0; }
    .footer-two .newsletter-content .newsletter-form {
      display: flex; }
      .footer-two .newsletter-content .newsletter-form input {
        height: 55px; }
      .footer-two .newsletter-content .newsletter-form .btn {
        min-width: 160px; }
  .footer-two .single-footer {
    padding-bottom: 100px;
    padding-top: 100px; }
    .footer-two .single-footer h5 {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 1rem;
      color: #000;
      margin-bottom: 15px;
      margin-top: -4px; }
    .footer-two .single-footer .pro-about ul li {
      color: #000;
      font-size: 0.875rem; }
    .footer-two .single-footer ul {
      margin-bottom: -5px !important; }
      .footer-two .single-footer ul li {
        display: block;
        width: 100%;
        list-style: none;
        font-size: 0.875rem;
        margin-bottom: 10px; }
        .footer-two .single-footer ul li:last-child {
          margin-bottom: 0; }
        .footer-two .single-footer ul li span {
          display: block; }
        .footer-two .single-footer ul li a {
          color: #000;
          -webkit-transition: 0.4s;
          transition: 0.4s; }
          .footer-two .single-footer ul li a:hover {
            text-decoration: none;
            color: #b38741; }
    .footer-two .single-footer .pro-socials p {
      color: #000;
      font-size: 0.875rem; }
    .footer-two .single-footer .pro-socials ul {
      display: flex;
      padding-left: 0;
      margin-bottom: -8px !important; }
      .footer-two .single-footer .pro-socials ul li {
        font-size: 1.5rem;
        width: auto;
        list-style: none;
        margin-right: 15px;
        margin-bottom: 0; }
        .footer-two .single-footer .pro-socials ul li a {
          color: #000; }
          .footer-two .single-footer .pro-socials ul li a.fb:hover {
            color: #3b5998; }
          .footer-two .single-footer .pro-socials ul li a.tw:hover {
            color: #00acee; }
          .footer-two .single-footer .pro-socials ul li a.sk:hover {
            color: #00aff0; }
          .footer-two .single-footer .pro-socials ul li a.ln:hover {
            color: #0077b5; }
          .footer-two .single-footer .pro-socials ul li a.ig:hover {
            color: #3f729b; }
  .footer-two .copyright-content {
    background-color: #333;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px; }
    .footer-two .copyright-content .footer-info {
      text-align: center;
      font-size: 0.8125rem; }
      .footer-two .copyright-content .footer-info a {
        color: #fff; }
        .footer-two .copyright-content .footer-info a:hover {
          color: #b38741; }

.bodyrtl .footer-two .single-footer .pro-socials p {
  direction: ltr; }

@media only screen and (max-width: 1025px) {
  .pro-about ul li .img-fluid {
    max-width: 48%; } }

@media only screen and (max-width: 991px) {
  .footer-two {
    margin-top: 70px; } }

.footer-three {
  padding-top: 100px;
  margin-top: 100px;
  background-color: #f8f9fa; }
  .footer-three .single-footer h5 {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1rem;
    color: #000;
    margin-bottom: 15px;
    margin-top: -4px; }
  .footer-three .single-footer .pro-about ul li {
    color: #000;
    font-size: 0.875rem; }
    .footer-three .single-footer .pro-about ul li img {
      width: 120px;
      margin-bottom: 8px; }
  .footer-three .single-footer ul li {
    display: block;
    width: 100%;
    list-style: none;
    font-size: 0.875rem;
    margin-bottom: 10px; }
    .footer-three .single-footer ul li:last-child {
      margin-bottom: -4px; }
    .footer-three .single-footer ul li span {
      display: block; }
    .footer-three .single-footer ul li a {
      color: #000;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
      .footer-three .single-footer ul li a:hover {
        text-decoration: none;
        color: #b38741; }
  .footer-three .footer-top-content {
    padding-bottom: 100px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
    margin-bottom: 100px; }
    .footer-three .footer-top-content h4 {
      font-family: "Montserrat-Bold", sans-serif;
      margin-top: -7px; }
    .footer-three .footer-top-content p {
      font-size: 0.875rem;
      margin-bottom: -4px; }
    .footer-three .footer-top-content .search {
      position: relative;
      width: 100%; }
      .footer-three .footer-top-content .search input {
        float: right;
        height: 40px;
        padding: 8px 98px 8px 8px;
        width: 100%;
        border-radius: 0;
        outline: none !important;
        border: 1px solid #ddd; }
      .footer-three .footer-top-content .search .btn {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0; }
  .footer-three .copyright-content {
    background-color: #f8f9fa;
    color: #1d1d1d;
    padding-top: 50px;
    padding-bottom: 50px; }
    .footer-three .copyright-content .footer-info {
      text-align: center;
      font-size: 0.8125rem;
      margin-bottom: -4px;
      margin-top: -4px; }
      .footer-three .copyright-content .footer-info a {
        color: #1d1d1d; }
        .footer-three .copyright-content .footer-info a:hover {
          color: #b38741; }

.footer-mobile {
  padding-top: 70px;
  margin-top: 70px;
  background-color: #f8f9fa; }
  .footer-mobile .single-footer {
    width: 100%;
    display: block; }
    .footer-mobile .single-footer a[data-toggle='collapse'],
    .footer-mobile .single-footer h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      font-weight: 800;
      color: #000;
      margin-bottom: 15px;
      width: 100%; }
    .footer-mobile .single-footer h5 {
      margin-bottom: 20px; }
    .footer-mobile .single-footer a[aria-expanded='true']:after {
      content: '\f077';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: #000;
      font-size: 0.8125rem;
      float: right; }
    .footer-mobile .single-footer a[aria-expanded='false']:after {
      content: '\f078';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: #000;
      font-size: 0.8125rem;
      float: right; }
    .footer-mobile .single-footer .card {
      padding: 0;
      border: none;
      background-color: transparent;
      margin-bottom: 15px; }
    .footer-mobile .single-footer .pro-about ul li {
      color: #000;
      font-size: 0.875rem; }
      .footer-mobile .single-footer .pro-about ul li img {
        width: 120px;
        margin-bottom: 8px; }
    .footer-mobile .single-footer ul li {
      float: left;
      width: 100%;
      list-style: none;
      margin-bottom: 10px; }
      .footer-mobile .single-footer ul li span {
        display: block; }
      .footer-mobile .single-footer ul li a {
        color: #000;
        -webkit-transition: 0.4s;
        transition: 0.4s; }
        .footer-mobile .single-footer ul li a:hover {
          text-decoration: none;
          color: #b38741; }
    .footer-mobile .single-footer .pro-socials {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .footer-mobile .single-footer .pro-socials ul {
        display: flex;
        padding-left: 0; }
        .footer-mobile .single-footer .pro-socials ul li {
          font-size: 1.5rem;
          width: auto;
          margin-left: 15px;
          margin-right: 0;
          margin-bottom: 0;
          list-style: none; }
          .footer-mobile .single-footer .pro-socials ul li a {
            color: #000; }
            .footer-mobile .single-footer .pro-socials ul li a.fb:hover {
              color: #3b5998; }
            .footer-mobile .single-footer .pro-socials ul li a.tw:hover {
              color: #00acee; }
            .footer-mobile .single-footer .pro-socials ul li a.sk:hover {
              color: #00aff0; }
            .footer-mobile .single-footer .pro-socials ul li a.ln:hover {
              color: #0077b5; }
            .footer-mobile .single-footer .pro-socials ul li a.ig:hover {
              color: #3f729b; }
    .footer-mobile .single-footer .pro-newsletter {
      margin-bottom: 30px; }
      .footer-mobile .single-footer .pro-newsletter .search {
        position: relative;
        margin-bottom: 15px;
        width: 100%; }
        .footer-mobile .single-footer .pro-newsletter .search input {
          float: right;
          height: 40px;
          padding: 8px 98px 8px 8px;
          width: 100%;
          border-radius: 0;
          outline: none !important;
          border: 1px solid #ddd; }
        .footer-mobile .single-footer .pro-newsletter .search .btn {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 46px;
          padding: 0; }
      .footer-mobile .single-footer .pro-newsletter p {
        font-size: 0.8125rem;
        color: #000; }
  .footer-mobile .copyright-content {
    background-color: #333;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px; }
    .footer-mobile .copyright-content .footer-info {
      text-align: center; }
      .footer-mobile .copyright-content .footer-info a {
        color: #fff; }
        .footer-mobile .copyright-content .footer-info a:hover {
          color: #b38741; }

.shop-bar-categories .card {
  border: none;
  margin-bottom: 15px; }
  .shop-bar-categories .card .card-header {
    padding: 0;
    background-color: transparent;
    border: none; }
    .shop-bar-categories .card .card-header a {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 1.1875rem; }
    .shop-bar-categories .card .card-header a[aria-expanded='true']:after {
      content: '\f077';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: #000;
      font-size: 0.8125rem; }
    .shop-bar-categories .card .card-header a[aria-expanded='false']:after {
      content: '\f078';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: #000;
      font-size: 0.8125rem; }
  .shop-bar-categories .card .card-body ul {
    padding-left: 0;
    margin-bottom: 0; }
  .shop-bar-categories .card .card-body .extra-controls.form-inline .form-group {
    display: flex; }
    .shop-bar-categories .card .card-body .extra-controls.form-inline .form-group span span {
      margin-top: 0;
      margin-right: 7px;
      margin-left: 7px; }
    .shop-bar-categories .card .card-body .extra-controls.form-inline .form-group span .numaric1 .numaric2 {
      float: left; }
    .shop-bar-categories .card .card-body .extra-controls.form-inline .form-group span .form-control {
      width: 80px; }
    .shop-bar-categories .card .card-body .extra-controls.form-inline .form-group .numaric2 {
      margin: 0 0 5px 8px; }
  .shop-bar-categories .card .card-body .pro-options .color-selection,
  .shop-bar-categories .card .card-body .pro-options .size-selection {
    margin-bottom: 0; }
  .shop-bar-categories .card .card-body .pro-options .color-selection ul,
  .shop-bar-categories .card .card-body .pro-options .size-selection ul {
    display: block; }
    .shop-bar-categories .card .card-body .pro-options .color-selection ul li,
    .shop-bar-categories .card .card-body .pro-options .size-selection ul li {
      display: inline-block;
      margin-bottom: 15px; }
  .shop-bar-categories .card .card-body .brands {
    list-style: none;
    margin: 0;
    padding: 0; }
    .shop-bar-categories .card .card-body .brands li a {
      padding-top: 0;
      padding-bottom: 0; }
      .shop-bar-categories .card .card-body .brands li a .fas {
        margin-right: 7px; }
      .shop-bar-categories .card .card-body .brands li a:hover {
        color: #b38741; }

.right-menu .brands-main {
  border: 1px solid #dee2e6;
  padding: 20px 15px; }
  .right-menu .brands-main h2 {
    font-size: 0.875rem;
    margin: 0; }
  .right-menu .brands-main .brands {
    margin-top: 7px; }
    .right-menu .brands-main .brands ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .right-menu .brands-main .brands ul li a {
        padding-top: 0;
        padding-bottom: 0; }
        .right-menu .brands-main .brands ul li a .fas {
          margin-right: 7px; }
        .right-menu .brands-main .brands ul li a:hover {
          color: #b38741; }

.right-menu .img-main {
  border: 1px solid #dee2e6;
  margin-top: 15px; }

.top-bar {
  margin-bottom: 30px;
  border: 1px solid #c1c9d0; }
  .top-bar label {
    font-weight: 800;
    margin: 0 5px 0 15px; }
  .top-bar .block {
    display: flex;
    align-items: center; }
    .top-bar .block .buttons {
      margin: 0 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .top-bar .block .buttons a {
        padding: 5px; }
      .top-bar .block .buttons .active {
        color: #b38741; }
  .top-bar .form-group {
    position: relative;
    margin-right: 15px; }
    .top-bar .form-group .form-control {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 15px;
      padding-left: 10px;
      border: none; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .top-bar .form-inline .form-group {
    margin-right: 10px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .top-bar {
    margin-bottom: 15px; }
    .top-bar .justify-content-center,
    .top-bar .justify-content-end {
      justify-content: flex-start !important; }
    .top-bar .block {
      margin-top: 10px;
      margin-bottom: 8px; }
    .top-bar .form-inline {
      margin-top: 0; } }

@media only screen and (max-width: 767px) {
  .top-bar {
    padding-top: 15px;
    margin-bottom: 15px; }
    .top-bar .block {
      margin-bottom: 1rem; }
    .top-bar .form-inline .form-group {
      width: 100%;
      margin-left: 0;
      margin-right: 0; }
      .top-bar .form-inline .form-group:last-child {
        margin-bottom: 0; }
      .top-bar .form-inline .form-group .select-control::before {
        right: 15px; }
      .top-bar .form-inline .form-group .form-control {
        width: 100%;
        padding-left: 15px;
        margin-top: 5px; }
      .top-bar .form-inline .form-group .select-control {
        width: 100%; }
      .top-bar .form-inline .form-group span {
        width: 100%;
        display: block;
        padding-left: 10px; } }

.blog-menu .block {
  background-color: white;
  margin-bottom: 30px; }
  .blog-menu .block .pro-heading-subtitle {
    padding-bottom: 15px; }
    .blog-menu .block .pro-heading-subtitle h4 {
      font-family: "Montserrat-Bold", sans-serif; }
  .blog-menu .block .media {
    margin-bottom: 15px; }
    .blog-menu .block .media img {
      margin-right: 15px; }
    .blog-menu .block .media .fas {
      color: #495057;
      font-size: 40px;
      margin-right: 15px; }
    .blog-menu .block .media .media-body h6 {
      font-family: "Montserrat-Bold", sans-serif;
      margin-bottom: 5px; }
    .blog-menu .block .media .media-body p {
      font-size: 0.875rem;
      color: #000;
      margin-bottom: 5px; }
      .blog-menu .block .media .media-body p a {
        font-size: 0.875rem; }
    .blog-menu .block .media .media-body span {
      font-size: 12px;
      color: #aaa; }
      .blog-menu .block .media .media-body span .far {
        margin-right: 5px; }

@media only screen and (max-width: 767px) {
  .blog-menu .pro-socials ul {
    margin-bottom: 0; }
  .blog-menu .block:last-child {
    margin-bottom: 0; } }

.pro-tab-content .pro-heading-title {
  padding-left: 0;
  padding-bottom: 35px; }

.pro-tab-content .nav {
  justify-content: center;
  margin-top: -25px;
  margin-bottom: 25px; }
  .pro-tab-content .nav .nav-link {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: normal; }
    .pro-tab-content .nav .nav-link:hover {
      color: #000; }
    .pro-tab-content .nav .nav-link.active {
      color: #b38741; }

.popular-product article {
  position: relative;
  display: flex;
  background-color: #fff;
  border-radius: 0;
  width: 100%; }
  .popular-product article .pro-thumb {
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0); }
    .popular-product article .pro-thumb:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-color: rgba(255, 255, 255, 0.2);
      border-style: solid;
      border-width: 0;
      -webkit-transition-property: border-width;
      transition-property: border-width;
      -webkit-transition-duration: 0.1s;
      transition-duration: 0.1s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out; }
    .popular-product article .pro-thumb .img-fluid {
      display: inherit;
      width: inherit;
      margin-left: auto;
      margin-right: auto; }
    .popular-product article .pro-thumb .pro-image {
      -webkit-transition: opacity 0.75s ease-out;
      transition: opacity 0.75s ease-out; }
    .popular-product article .pro-thumb .pro-image-hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      -webkit-transition: opacity 0.75s ease-out;
      transition: opacity 0.75s ease-out; }
    .popular-product article .pro-thumb .pro-buttons {
      z-index: 9;
      position: absolute;
      bottom: 35px;
      transition-timing-function: ease-in;
      /* Quick on the way out */
      transition: 0.75s;
      /* Hide thing by pushing it outside by default */
      transform: translateY(144%); }
      .popular-product article .pro-thumb .pro-buttons .pro-icons {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center; }
        .popular-product article .pro-thumb .pro-buttons .pro-icons .icon {
          position: relative;
          background-color: #b38741;
          color: #fff;
          display: flex;
          width: 40px;
          margin: 10px;
          height: 40px;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          overflow: visible; }
          .popular-product article .pro-thumb .pro-buttons .pro-icons .icon:hover {
            background-color: #b38741;
            color: #fff; }
          .popular-product article .pro-thumb .pro-buttons .pro-icons .icon::before {
            background-color: #8e6b33; }
      .popular-product article .pro-thumb .pro-buttons .btn {
        margin: 10px;
        width: calc(100% - 20px); }
    .popular-product article .pro-thumb .pro-tag {
      background-color: #479af1;
      left: 5px;
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 0.8125rem;
      color: #fff;
      text-transform: uppercase;
      border-radius: 0;
      position: absolute;
      top: 5px;
      padding: 2px 7px 2px 7px;
      min-width: 40px; }
  .popular-product article .pro-description {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    width: 50%;
    padding: 15px; }
    .popular-product article .pro-description .pro-info {
      font-size: 0.8125rem;
      color: #000;
      text-transform: uppercase; }
    .popular-product article .pro-description .pro-title {
      line-height: 20px; }
      .popular-product article .pro-description .pro-title a {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 0.875rem;
        color: #000;
        text-decoration: none; }
        .popular-product article .pro-description .pro-title a:hover {
          color: #b38741; }
    .popular-product article .pro-description .pro-price {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      line-height: 28px; }
      .popular-product article .pro-description .pro-price ins {
        font-size: 1.2rem;
        font-family: "Montserrat-Bold", sans-serif;
        color: #b38741;
        text-decoration: none; }
      .popular-product article .pro-description .pro-price del {
        font-size: 0.875rem;
        font-family: "Montserrat-Regular", sans-serif;
        color: #000;
        text-decoration: line-through;
        margin-right: 10px; }
    .popular-product article .pro-description .color-selection,
    .popular-product article .pro-description .size-selection {
      margin-bottom: 34px; }
    .popular-product article .pro-description .btn {
      max-width: 164px; }
  .popular-product article .discription {
    display: none; }
  .popular-product article .detail-product {
    display: flex;
    font-size: 1.35rem;
    font-weight: 800;
    color: #b38741; }
  .popular-product article .listview-btn {
    display: none; }
  .popular-product article:hover .pro-thumb:after {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    border-width: 6px;
    animation: imageScroll 1s linear 0s 1 both; }
  .popular-product article:hover .pro-thumb .pro-image-hover {
    opacity: 1; }
  .popular-product article:hover .pro-thumb .pro-buttons {
    transition-timing-function: ease-out;
    /* A litttttle slower on the way in */
    transition: 0.75s;
    /* Move into place */
    transform: translateY(0); }

.popular-product:hover .product-hover {
  opacity: 1; }

.pro-pl-content .pro-heading-title {
  padding-left: 0;
  padding-bottom: 35px; }

.pro-pl-content .product {
  margin-bottom: 30px; }

.pro-tr-content .pro-heading-title {
  padding-left: 0;
  padding-bottom: 35px; }

.pro-tr-content .product article .pro-description .pro-title {
  min-height: 40px; }

.pro-tr-content .product article .pro-description .pro-btns {
  align-items: center; }
  .pro-tr-content .product article .pro-description .pro-btns .pro-price {
    display: block; }
    .pro-tr-content .product article .pro-description .pro-btns .pro-price ins,
    .pro-tr-content .product article .pro-description .pro-btns .pro-price del {
      display: block;
      line-height: 20px;
      margin-right: 0; }

.blog-content .pro-heading-title {
  padding-left: 0;
  padding-bottom: 35px; }

.blog-content .pro-blog:hover .pro-thumb:after {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border-width: 6px;
  animation: imageScroll 1s linear 0s 1 both; }

.blog-content .pro-blog .pro-thumb {
  border-radius: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0); }
  .blog-content .pro-blog .pro-thumb:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-color: rgba(255, 255, 255, 0.2);
    border-style: solid;
    border-width: 0;
    -webkit-transition-property: border-width;
    transition-property: border-width;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  .blog-content .pro-blog .pro-thumb .img-fluid {
    display: inherit;
    width: inherit;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
  .blog-content .pro-blog .pro-thumb:hover .overlay {
    display: flex; }
  .blog-content .pro-blog .pro-thumb h2 {
    font-size: 0.875rem;
    line-height: 25px;
    padding: 15px 100px 15px 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1; }
    .blog-content .pro-blog .pro-thumb h2 a {
      color: #fff; }
      .blog-content .pro-blog .pro-thumb h2 a:hover {
        text-decoration: none; }
    .blog-content .pro-blog .pro-thumb h2 span {
      position: absolute;
      top: 15px;
      right: 0;
      background-color: #b38741;
      color: #fff;
      font-size: 0.875rem;
      line-height: normal;
      text-transform: capitalize;
      text-shadow: none;
      padding: 0.15em 0.4em;
      border-radius: 0; }
  .blog-content .pro-blog .pro-thumb .blog-date {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-family: "Montserrat-Bold", sans-serif;
    color: #fff;
    padding: 7px 10px;
    text-align: center;
    text-transform: uppercase;
    z-index: 9; }
    .blog-content .pro-blog .pro-thumb .blog-date small {
      display: block;
      font-family: "Montserrat-Regular", sans-serif;
      font-size: 60%;
      color: #fff; }

.blog-content .pro-blog .pro-detail h2 {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 0.875rem; }
  .blog-content .pro-blog .pro-detail h2 a:hover {
    text-decoration: none;
    color: #b38741; }

.blog-content .pro-blog .pro-detail p {
  font-size: 0.875rem;
  color: #000; }

.blog-content .pro-blog .pro-detail .pro-blog-meta {
  margin-bottom: 15px;
  padding: 0; }

.blog-content .pro-blog .pro-detail li {
  list-style: none;
  display: inline-block;
  padding-right: 10px;
  margin-right: 10px; }
  .blog-content .pro-blog .pro-detail li::after {
    content: '/';
    left: 12px;
    position: relative; }
  .blog-content .pro-blog .pro-detail li:last-child::after {
    content: none; }

.blog-content .pro-blog .pro-detail .pro-readmore {
  display: inline-block;
  font-size: 0.8125rem;
  text-decoration: none; }
  .blog-content .pro-blog .pro-detail .pro-readmore:hover {
    color: #b38741; }

.categories-content {
  padding-left: 30px;
  padding-right: 30px; }
  .categories-content .pro-heading-title {
    padding-left: 0;
    padding-bottom: 35px; }
  .categories-content .tab-category-carousel-js {
    margin-bottom: 0 !important; }
  .categories-content .banner-category {
    position: relative;
    overflow: hidden; }
    .categories-content .banner-category img {
      transition: transform 0.4s;
      /* Animation */ }
    .categories-content .banner-category .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .categories-content .banner-category:hover img {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1); }
    .categories-content .banner-category:hover .pro-text {
      transform: scaleY(1); }
  .categories-content .pro-Categories {
    border: 1px solid #ddd; }
    .categories-content .pro-Categories .pro-Categories-thumb {
      display: block;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      border-radius: 0;
      -webkit-transition: 0.8s;
      transition: 0.8s; }
      .categories-content .pro-Categories .pro-Categories-thumb img {
        transition: transform 1s;
        /* Animation */ }
      .categories-content .pro-Categories .pro-Categories-thumb .pro-Categories-title {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 7px 15px;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9;
        text-align: left;
        width: 100%; }
        .categories-content .pro-Categories .pro-Categories-thumb .pro-Categories-title a {
          font-family: "Montserrat-Bold", sans-serif;
          font-size: 0.875rem;
          color: #000;
          outline: none;
          text-transform: uppercase; }
      .categories-content .pro-Categories .pro-Categories-thumb:hover img {
        -webkit-transform: scale3d(1.1, 1.1, 1);
        transform: scale3d(1.1, 1.1, 1); }
      .categories-content .pro-Categories .pro-Categories-thumb:hover .pro-Categories-title {
        background-color: #b38741; }
        .categories-content .pro-Categories .pro-Categories-thumb:hover .pro-Categories-title a {
          color: #fff; }
    .categories-content .pro-Categories .pro-Categories-detail h2 {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 0.875rem;
      padding-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px; }
    .categories-content .pro-Categories .pro-Categories-detail .pro-Categories-meta {
      padding: 0;
      list-style: none;
      padding-left: 15px;
      margin-bottom: 0; }
      .categories-content .pro-Categories .pro-Categories-detail .pro-Categories-meta li {
        margin-bottom: 10px; }

.pro-plr-content .pro-heading-title {
  padding-left: 0;
  padding-bottom: 35px; }

.pro-plr-content .product {
  margin-bottom: 30px; }
  .pro-plr-content .product .pro-thumb {
    border: 1px solid #ddd; }
  .pro-plr-content .product article {
    margin-bottom: -4px; }

.pro-mini-content {
  padding-top: 0px; }
  .pro-mini-content .pro-banner-text {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    padding: 30px; }
    .pro-mini-content .pro-banner-text .pro-banner-thumb {
      width: 90px;
      height: 90px;
      overflow: hidden; }
      .pro-mini-content .pro-banner-text .pro-banner-thumb img {
        height: 100%;
        width: initial;
        max-width: initial;
        margin-left: -38%; }
    .pro-mini-content .pro-banner-text .pro-image-text {
      padding-left: 30px; }
      .pro-mini-content .pro-banner-text .pro-image-text h4 {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 0.875rem;
        margin-bottom: 15px; }

.pro-sp-content {
  background-color: #f4f4f4;
  padding-bottom: 100px;
  margin-top: 100px; }
  .pro-sp-content .pro-description .pro-info {
    display: block;
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 28px;
    color: #dc3545;
    margin-top: -11px; }
  .pro-sp-content .pro-description .pro-title {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 50px;
    margin-top: 10px; }
    .pro-sp-content .pro-description .pro-title ins {
      color: #b38741;
      text-decoration: none; }
  .pro-sp-content .pro-description .pro-price {
    display: flex;
    align-items: center;
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 50px;
    margin-top: 15px; }
    .pro-sp-content .pro-description .pro-price del {
      font-size: 40px;
      color: #000; }
    .pro-sp-content .pro-description .pro-price ins {
      margin-left: 15px;
      color: #b38741;
      text-decoration: none; }
  .pro-sp-content .pro-description .pro-timer {
    position: static;
    justify-content: start;
    padding: 0;
    margin: 30px 0 0 0;
    background-color: transparent; }
    .pro-sp-content .pro-description .pro-timer span {
      background-color: rgba(0, 0, 0, 0.6);
      font-size: 1.75rem;
      margin-left: 0;
      margin-right: 30px;
      width: 80px;
      height: 80px;
      padding-top: 6px; }
      .pro-sp-content .pro-description .pro-timer span small {
        color: #fff; }
  .pro-sp-content .pro-description .pro-btns {
    margin-top: 30px; }
    .pro-sp-content .pro-description .pro-btns .btn {
      font-size: 24px;
      line-height: normal; }
  .pro-sp-content .pro-thumb a,
  .pro-sp-content .pro-thumb span {
    display: block; }
  .pro-sp-content .pro-thumb .pro-image img {
    width: 100%; }

.pro-category-content {
  padding-bottom: 60px; }
  .pro-category-content .pro-heading-subtitle h4 {
    font-family: "Montserrat-Bold", sans-serif;
    margin-bottom: 30px; }
  .pro-category-content .pro-category-items .pro-single-item {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    margin: 0 0 15px 0;
    padding: 10px;
    height: calc(100% - 30px); }
    .pro-category-content .pro-category-items .pro-single-item .pro-thumb {
      position: relative;
      width: 24%; }
      .pro-category-content .pro-category-items .pro-single-item .pro-thumb .pro-image {
        -webkit-transition: opacity 0.75s ease-out;
        transition: opacity 0.75s ease-out; }
      .pro-category-content .pro-category-items .pro-single-item .pro-thumb .pro-image-hover {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        -webkit-transition: opacity 0.75s ease-out;
        transition: opacity 0.75s ease-out; }
    .pro-category-content .pro-category-items .pro-single-item:hover .pro-thumb .pro-image-hover {
      opacity: 1; }
    .pro-category-content .pro-category-items .pro-single-item .pro-description {
      width: 76%;
      padding-left: 10px; }
      .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-info {
        font-size: 0.8125rem;
        color: #000;
        text-transform: uppercase; }
      .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-title {
        line-height: 20px; }
        .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-title a {
          font-family: "Montserrat-Bold", sans-serif;
          font-size: 0.875rem;
          color: #000;
          text-decoration: none; }
      .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-price {
        display: flex;
        align-items: center; }
        .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-price ins {
          font-family: "Montserrat-ExtraBold", sans-serif;
          font-size: 1.2rem;
          color: #b38741;
          text-decoration: none; }
        .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-price del {
          font-size: 1rem;
          font-weight: 800;
          color: #000;
          text-decoration: line-through;
          margin-right: 10px; }
    .pro-category-content .pro-category-items .pro-single-item:last-child {
      margin-bottom: 0; }

.info-boxes-content {
  border-top: 1px solid #f4f4f4;
  background-color: #f8f9fa; }
  .info-boxes-content .info-box-full {
    color: #000;
    padding: 30px;
    position: relative;
    overflow: hidden; }
    .info-boxes-content .info-box-full .info-box {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-right: 15px; }
      .info-boxes-content .info-box-full .info-box .fas {
        font-size: 35px;
        color: #b38741;
        margin-bottom: 0;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100px;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        max-width: 100px;
        text-align: center;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center; }
      .info-boxes-content .info-box-full .info-box .block h4 {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 1rem; }
      .info-boxes-content .info-box-full .info-box .block p {
        font-size: 0.875rem;
        margin-bottom: 0; }
    .info-boxes-content .info-box-full .last {
      padding-right: 0; }

.pro-bc-content {
  padding-bottom: 100px; }
  .pro-bc-content .pro-heading-title {
    padding-left: 0;
    padding-bottom: 35px; }
  .pro-bc-content .pro-main .banner-image {
    overflow: hidden; }
    .pro-bc-content .pro-main .banner-image img {
      transition: transform 0.4s;
      /* Animation */
      width: 100%; }
    .pro-bc-content .pro-main .banner-image:hover img {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1); }
  .pro-bc-content .pro-main .pro-description {
    text-align: center; }
    .pro-bc-content .pro-main .pro-description h2 {
      font-family: "Montserrat-Bold", sans-serif; }

.pro-logo-slik img {
  border: 1px solid #ddd; }

.testimonails-content {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f4f4f4; }
  .testimonails-content .pro-heading-title {
    padding-left: 0;
    padding-bottom: 35px; }
  .testimonails-content .banner-image {
    width: 160px;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; }
    .testimonails-content .banner-image img {
      border-radius: 200px; }
  .testimonails-content .pro-detail {
    text-align: center;
    margin-top: 20px; }
    .testimonails-content .pro-detail h2 {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 1rem;
      margin-bottom: 15px;
      margin-top: 30px; }
    .testimonails-content .pro-detail p {
      font-size: 0.875rem;
      color: #000;
      margin-bottom: 5px; }
    .testimonails-content .pro-detail .pro-readmore {
      display: inline-block;
      font-size: 0.8125rem;
      text-decoration: none; }
      .testimonails-content .pro-detail .pro-readmore:hover {
        color: #b38741; }

.brands-content img {
  border: 1px solid #eee; }

.well span {
  width: 80px;
  height: 80;
  font-size: 2rem;
  float: left;
  padding: 8px;
  background-color: aqua; }
  .well span small {
    word-break: break-all; }

.no-js #loader {
  display: none; }

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0; }

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../../images/loader-64x/Preloader_2.gif) center no-repeat #fff;
  background-size: 64px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-mini-content .pro-banner-text {
    display: block;
    padding: 15px; }
    .pro-mini-content .pro-banner-text .pro-banner-thumb {
      width: 100%;
      height: 140px;
      margin-bottom: 15px; }
    .pro-mini-content .pro-banner-text .pro-image-text {
      padding-left: 0px; }
  .pro-category-content .pro-heading-subtitle h4 {
    font-size: 1.2rem; }
  .pro-category-content .pro-category-items .pro-single-item {
    display: block; }
    .pro-category-content .pro-category-items .pro-single-item .pro-thumb {
      width: 100%;
      margin-bottom: 15px; }
    .pro-category-content .pro-category-items .pro-single-item .pro-description {
      width: 100%;
      padding-left: 0px; }
      .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-title a {
        font-size: 1rem; } }

@media only screen and (max-width: 991px) {
  .pro-sp-content {
    padding-bottom: 70px;
    margin-top: 70px; }
  .pro-full-screen-banner {
    margin-top: 70px; }
  .pro-sp-content .pro-description {
    margin-top: 30px; }
    .pro-sp-content .pro-description .pro-timer span {
      margin-right: 10px; }
      .pro-sp-content .pro-description .pro-timer span:last-child {
        margin-right: 0;
        min-width: 55px; }
  .pro-sp-content .pro-thumb img {
    width: 100%; }
  .pro-mini-content .pro-banner-text .pro-banner-thumb img {
    margin-left: -18%; }
  .pro-bc-content {
    padding-bottom: 70px; }
  .testimonails-content {
    padding-top: 70px;
    padding-bottom: 70px; } }

@media only screen and (max-width: 767px) {
  .popular-product article {
    display: block; }
    .popular-product article .pro-thumb {
      width: 100%; }
    .popular-product article .pro-description {
      width: 100%; }
      .popular-product article .pro-description > div {
        width: 100%; }
      .popular-product article .pro-description .btn {
        max-width: none; }
  .pro-sp-content .pro-description .pro-info {
    font-size: 20px; }
  .pro-sp-content .pro-description .pro-title {
    font-size: 30px; }
  .pro-sp-content .pro-description .pro-btns .btn {
    width: 100%; }
  .blog-content .pro-blog .pro-thumb {
    height: auto;
    margin-bottom: 15px; }
  .info-boxes-content .info-box-full {
    border: none;
    padding: 30px;
    margin: 0; }
    .info-boxes-content .info-box-full .info-box {
      justify-content: start;
      border-width: 0px;
      padding-right: 0;
      margin-bottom: 30px; }
      .info-boxes-content .info-box-full .info-box .fas {
        text-align: left;
        max-width: 65px; }
    .info-boxes-content .info-box-full .last {
      margin-bottom: 0; }
  .pro-mini-content .pro-banner-text .pro-banner-thumb img {
    margin-left: -38%; }
  .categories-content .banner-category {
    margin-bottom: 15px; }
  .categories-content .banner-category.last {
    margin-bottom: 0; }
  .pro-main {
    margin-bottom: 70px; }
  .pro-main.last {
    margin-bottom: 0; } }

.discription {
  text-align: center;
  padding-top: 15px; }
  .discription p {
    font-weight: 600;
    margin-bottom: 5px; }
  .discription h2 {
    font-size: 2.5rem;
    font-weight: 800; }

.shop-content .pro-heading-title {
  padding-bottom: 35px; }

.shop-content .product {
  padding-bottom: 25px; }

.shop-special .listing .product .pro-thumb.pro-list-thumb {
  width: 356px; }

.shop-special .listing .product .pro-thumb {
  width: 404px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-special .listing .product article .pro-thumb.pro-list-thumb,
  .shop-special .listing .product article .pro-thumb {
    width: auto; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-special .listing .product article .pro-thumb.pro-list-thumb,
  .shop-special .listing .product article .pro-thumb {
    width: auto; }
  .shop-special .listing .product article .pro-mobile-buttons .btn-block {
    width: auto; }
  .shop-content .right-menu {
    margin-top: 15px; } }

@media only screen and (max-width: 767px) {
  .shop-special .listing .product article {
    display: block; }
    .shop-special .listing .product article .pro-thumb.pro-list-thumb,
    .shop-special .listing .product article .pro-thumb {
      width: auto; }
    .shop-special .listing .product article .pro-description {
      padding: 15px; }
  .shop-content .right-menu {
    margin-top: 15px; }
  .shop-content .pro-heading-title {
    padding-bottom: 20px; } }

.product-content h2 {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 1rem; }

.product-content .pro-heading-title {
  padding-bottom: 35px; }

.product-content .product-detail-info .pro-tags .pro-tag {
  display: inline-block;
  background-color: #dc3545;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 0.8125rem;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0;
  padding: 2px 7px 2px 7px;
  min-width: 40px;
  z-index: 9; }

.product-content .product-detail-info .pro-tags .pro-tag.bg-primary {
  background-color: #28a745 !important; }

.product-content .product-detail-info .pro-tags .pro-tag.bg-success {
  background-color: #479af1 !important; }

.product-content .product-detail-info .pro-title {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 2rem;
  margin-top: 15px; }

.product-content .product-detail-info .pro-price {
  display: flex;
  align-items: center; }
  .product-content .product-detail-info .pro-price ins {
    font-family: "Montserrat-ExtraBold", sans-serif;
    font-weight: 800;
    font-size: 1.9rem;
    color: #b38741;
    text-decoration: none; }
  .product-content .product-detail-info .pro-price del {
    font-weight: 800;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 1.2rem;
    color: #000;
    text-decoration: line-through;
    margin-right: 10px; }

.product-content .product-detail-info .pro-rating .fas {
  color: #ffba0a; }

.product-content .product-detail-info .pro-rating .btn-link {
  font-weight: 800;
  color: #b8b8b8;
  margin-left: 10px; }

.product-content .product-detail-info .pro-infos {
  margin-top: 15px;
  margin-bottom: 15px; }
  .product-content .product-detail-info .pro-infos .pro-single-info {
    display: flex;
    line-height: 24px; }
    .product-content .product-detail-info .pro-infos .pro-single-info b {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 0.875rem;
      color: #000;
      margin-right: 10px; }
    .product-content .product-detail-info .pro-infos .pro-single-info ul {
      display: inline-block;
      padding-left: 0;
      margin-bottom: 0; }
      .product-content .product-detail-info .pro-infos .pro-single-info ul li {
        display: inline-block; }
        .product-content .product-detail-info .pro-infos .pro-single-info ul li:after {
          content: ','; }

.product-content .product-detail-info .pro-sub-buttons {
  display: block;
  margin-top: 24px;
  margin-bottom: 30px; }
  .product-content .product-detail-info .pro-sub-buttons .buttons {
    margin-bottom: 22px; }
  .product-content .product-detail-info .pro-sub-buttons .btn {
    padding: 0;
    margin-right: 15px; }
    .product-content .product-detail-info .pro-sub-buttons .btn .fas {
      margin-right: 5px; }
  .product-content .product-detail-info .pro-sub-buttons .sharethis-inline-share-buttons {
    z-index: 1 !important; }

.product-content .product-detail-info .nav .nav-item {
  font-family: "Montserrat-Bold", sans-serif;
  color: #1d1d1d;
  padding: 15px 16px;
  text-align: center; }

.product-content .product-detail-info .nav .active {
  border-color: #e9ecef;
  background-color: #e9ecef; }

.product-content .product-detail-info .tab-content .tab-pane {
  padding: 15px;
  border: 1px solid #e9ecef; }
  .product-content .product-detail-info .tab-content .tab-pane .table {
    font-size: 0.875rem;
    margin-bottom: 0; }
    .product-content .product-detail-info .tab-content .tab-pane .table th {
      padding-left: 0.75rem; }
  .product-content .product-detail-info .tab-content .tab-pane p {
    font-size: 0.875rem;
    color: #000;
    margin-bottom: 0; }
  .product-content .product-detail-info .tab-content .tab-pane ul {
    margin-top: 15px;
    color: #000; }

.product-content .product-detail-info .reviews .review-bubbles .review-bubble-single {
  background-color: #e9ecef;
  margin-bottom: 15px;
  padding: 15px; }
  .product-content .product-detail-info .reviews .review-bubbles .review-bubble-single h4 {
    font-family: "Montserrat-Bold", sans-serif;
    margin-top: 10px;
    margin-bottom: 5px; }
  .product-content .product-detail-info .reviews .review-bubbles .review-bubble-single span {
    color: #b8b8b8; }

.product-content .product-detail-info .pro-banner-detial {
  padding: 30px 18px 0 18px;
  background-color: #f8f9fa;
  text-align: center; }
  .product-content .product-detail-info .pro-banner-detial .banner-single {
    margin-bottom: 30px; }
    .product-content .product-detail-info .pro-banner-detial .banner-single .fas {
      color: #b38741;
      font-size: 1.53125rem;
      margin-bottom: 0.5rem; }
    .product-content .product-detail-info .pro-banner-detial .banner-single .block {
      font-size: 12px;
      font-weight: 600; }
      .product-content .product-detail-info .pro-banner-detial .banner-single .block h4 {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 0.875rem; }
      .product-content .product-detail-info .pro-banner-detial .banner-single .block p {
        font-family: "Montserrat-Regular", sans-serif;
        font-size: 0.8125rem; }

.product-content .related-product-content {
  margin-top: 100px; }

.sub-title-special {
  font-size: 1.52rem !important;
  margin-bottom: 15px; }

.product-carousel2 .product article .pro-description {
  margin-top: 0; }
  .product-carousel2 .product article .pro-description .pro-title.sub-title {
    margin-top: 5px !important;
    margin-bottom: 15px; }

.product-carousel2 .slick-dots {
  margin-top: 15px !important; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-content .product-detail-info .nav .nav-item {
    width: 31.2%;
    padding-top: 10px;
    padding-left: inherit;
    padding-right: inherit;
    padding-bottom: 10px; }
    .product-content .product-detail-info .nav .nav-item:last-child {
      margin-right: 0; } }

@media only screen and (max-width: 767px) {
  img.zoomImg {
    display: none !important;
    z-index: 0 !important; }
  .product-content.pro-content {
    padding-top: 15px; }
  .product-content .pro-heading-title {
    padding-left: 0; }
  .product-detail-info .slider-wrapper {
    margin-bottom: 30px; }
  .product-detail-info .slider-for-vertical {
    width: calc(80% - 15px);
    margin-left: 15px; }
  .product-detail-info .slider-nav-vertical .slick-dots {
    display: none; }
  .product-detail-info .slider-nav {
    margin-top: 15px; }
    .product-detail-info .slider-nav .slick-dots {
      margin-top: 10px !important; }
  .product-detail-info .nav .nav-item {
    font-size: 0.8125rem; }
  .product-detail-info .tab-content .tab-pane {
    margin-bottom: 15px; } }

.aboutus-content .pro-pera {
  margin-top: 30px;
  margin-bottom: -6px; }

.aboutus-content .bg-media {
  background-color: #e9ecef;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 100px;
  margin-top: 100px; }

.aboutus-content .pro-heading-title {
  padding-bottom: 35px;
  padding-left: 0; }

.aboutus-content p {
  color: #000; }

.aboutus-content .peragraph:last-child {
  margin-bottom: -6px; }

.aboutus-content video {
  width: 100%; }

.aboutus-content .media .rounded-circle {
  margin-right: 30px;
  margin-bottom: 0; }

.aboutus-content .media .media-body h4 {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 1rem; }
  .aboutus-content .media .media-body h4 small {
    font-size: 0.8125rem;
    color: #000; }

.aboutus-content .media .media-body p {
  margin-bottom: 0; }

.aboutus-content .media-col {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end; }

.aboutus-content .team-member {
  text-align: center; }
  .aboutus-content .team-member h3 {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1rem;
    margin-top: 15px; }
  .aboutus-content .team-member p {
    color: #000;
    margin: 0; }

.aboutus-content .accordion-container {
  background-color: #f8f9fa;
  padding-bottom: 60px;
  padding-top: 60px;
  margin-bottom: 100px;
  margin-top: 100px; }

.aboutus-content .accordions .card-header h5 {
  font-size: 0.875rem; }

.aboutus-content .accordions .card-header a {
  font-family: "Montserrat-Bold", sans-serif;
  text-decoration: none; }
  .aboutus-content .accordions .card-header a .fas {
    float: right; }

.aboutus-content .accordions .card-header a[aria-expanded='true']:after {
  content: '\f077';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #000;
  font-size: 0.8125rem;
  position: absolute;
  right: 20px;
  top: 14px; }

.aboutus-content .accordions .card-header a[aria-expanded='false']:after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #000;
  font-size: 0.8125rem;
  position: absolute;
  right: 20px;
  top: 14px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aboutus-content .media {
    display: block;
    text-align: center; }
    .aboutus-content .media .rounded-circle {
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto; }
    .aboutus-content .media .media-body {
      text-align: center; } }

.contact-content .pro-heading-title {
  padding-left: 15px;
  padding-bottom: 35px; }

.contact-content .form-group {
  margin-bottom: 1.5rem; }
  .contact-content .form-group label.error {
    right: 15px; }

.contact-content .contact-info {
  padding-left: 0px; }
  .contact-content .contact-info li {
    display: flex;
    align-items: center;
    padding-bottom: 30px; }
    .contact-content .contact-info li .fas {
      color: #b38741;
      font-size: 26px;
      width: 37px; }
    .contact-content .contact-info li span {
      display: block;
      font-size: 0.8125rem;
      color: #000; }
    .contact-content .contact-info li p {
      margin-bottom: 0; }

.contact-content .more-info li {
  display: block; }

.contact-content .more-info h2 {
  font-size: 1rem;
  font-family: "Montserrat-Bold", sans-serif; }

.bodyrtl .contact-content form label.error {
  left: 15px;
  right: auto;
  text-align: left; }

@media only screen and (max-width: 767px) {
  .contact-content .pro-heading-title {
    padding-bottom: 20px; }
  .contact-content .pro-socials ul {
    margin-bottom: 0; }
  .contact-content form {
    margin-bottom: 0; }
  .contact-content .form-group {
    margin-bottom: 0; }
    .contact-content .form-group .form-control {
      margin-bottom: 25px; }
    .contact-content .form-group label.error {
      bottom: -5px; }
  .contact-content-page .pro-socials {
    margin-bottom: 30px; } }

.blog-content-page .pro-heading-title {
  padding-left: 15px; }

.blog-content .blog-area .border-bg {
  background-color: transparent;
  border: none;
  padding: 0; }

.blog .blog-date {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-family: "Montserrat-Bold", sans-serif;
  color: #fff;
  padding: 7px 10px;
  text-align: center;
  text-transform: uppercase;
  z-index: 0; }
  .blog .blog-date small {
    display: block;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 60%;
    color: #fff; }

.blog .blog-thumbnail {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden; }
  .blog .blog-thumbnail .img-thumbnail {
    padding: 0;
    border: none;
    width: 100%; }
  .blog .blog-thumbnail .badge {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 100%;
    font-weight: 400;
    line-height: normal;
    padding: 0; }
    .blog .blog-thumbnail .badge a {
      float: left;
      padding: 3px 10px;
      color: #fff; }
      .blog .blog-thumbnail .badge a:hover {
        color: #b38741; }
    .blog .blog-thumbnail .badge .fas {
      background-color: #b38741;
      color: #fff;
      padding: 5px; }

.blog h1,
.blog h2,
.blog h4 {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 1rem; }
  .blog h1 small,
  .blog h2 small,
  .blog h4 small {
    color: #000; }

.blog p {
  font-size: 0.875rem;
  color: #000; }
  .blog p a {
    color: #b38741; }
  .blog p span {
    display: block;
    margin-top: 10px; }

.blog .blog-control {
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-bottom: 30px; }
  .blog .blog-control .btn {
    padding: 0 5px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-content.blog-content-page .pro-heading-title {
    padding-left: 15px;
    padding-bottom: 20px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-content.blog-content-page .pro-heading-title {
    padding-left: 15px;
    padding-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .blog-content.blog-content-page .pro-heading-title {
    padding-left: 15px;
    padding-bottom: 20px; }
  .blog-content.blog-content-page .blog .blog-thumbnail {
    height: auto; } }

.login-content .pro-heading-title {
  padding-bottom: 25px; }

.login-content h2 {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 1rem; }

.login-content p {
  font-size: 0.875rem; }

.login-content .registration-process {
  background-color: #fff;
  margin-bottom: 30px; }
  .login-content .registration-process .btn-link {
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px; }

.login-content .registration-socials {
  background-color: #fff;
  padding: 15px;
  text-align: center; }
  .login-content .registration-socials .btn {
    margin: 0 2px; }

.login-content label.error {
  right: 15px; }

.center-content-page .pro-heading-title {
  padding-left: 0; }

#registerTab {
  border: none; }
  #registerTab .nav-link {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    background-color: #f4f4f4;
    border: none; }
    #registerTab .nav-link.active {
      background-color: #b38741;
      color: #fff;
      border: none; }

#registerTabContent .registration-process {
  background-color: #f4f4f4;
  padding: 30px 15px; }

#registerTabContent .registration-socials {
  background-color: transparent; }
  #registerTabContent .registration-socials .btn {
    margin: 0 5px; }

.bodyrtl .login-content label.error {
  left: 15px;
  right: auto;
  text-align: left; }

@media only screen and (max-width: 767px) {
  .center-content-page .pro-heading-title {
    padding-left: 0; }
  .login-content .pro-heading-title {
    padding-bottom: 20px; }
  .login-content p:last-child {
    margin-bottom: 0; }
  #registerTabContent .registration-process {
    padding: 15px 0; }
    #registerTabContent .registration-process .btn-primary,
    #registerTabContent .registration-process .btn-secondary {
      padding-left: 15px;
      padding-right: 15px; } }

.cart-content .pro-heading-title {
  padding-bottom: 35px; }

.cart-content .table-background {
  background-color: #f4f4f4; }
  .cart-content .table-background tr th,
  .cart-content .table-background tr td {
    padding-left: 0.75rem;
    width: 100%; }
    .cart-content .table-background tr th .form-control,
    .cart-content .table-background tr td .form-control {
      height: calc(2.0625rem + 12px); }

.cart-content .table th,
.cart-content .table td {
  padding-left: 0; }

.cart-content .table thead tr th {
  border-top: none;
  text-transform: uppercase; }

.cart-content .table tbody tr {
  align-items: center;
  border-bottom: 1px solid #ddd; }
  .cart-content .table tbody tr td {
    border: none; }

.cart-content .table .item-controls {
  display: flex;
  align-items: center; }
  .cart-content .table .item-controls .btn {
    font-size: 12px;
    border: 1px solid #dee2e6;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    margin-right: 10px; }
    .cart-content .table .item-controls .btn:hover {
      background-color: #b38741;
      color: #fff; }

.cart-content .table .item-detail {
  float: left; }
  .cart-content .table .item-detail .pro-info {
    text-transform: uppercase; }
  .cart-content .table .item-detail h2 {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1rem;
    margin-bottom: 15px; }
    .cart-content .table .item-detail h2 small {
      color: #000; }

.cart-content .table .item-price,
.cart-content .table .item-total {
  font-family: "Montserrat-Bold", sans-serif;
  color: #b38741;
  font-size: 1.25rem; }

.cart-content .align-right {
  text-align: right; }
  .cart-content .align-right .item-controls {
    justify-content: flex-end; }
    .cart-content .align-right .item-controls .btn {
      margin-left: 10px;
      margin-right: 0; }

.cart-content .btn {
  z-index: 0; }

.bodyrtl .cart-content .item-quantity {
  margin-left: 15px;
  margin-right: 0; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-content .pro-heading-title {
    padding-left: 15px; }
  .cart-content.cart-page-2 .table .item-controls {
    justify-content: flex-end; }
    .cart-content.cart-page-2 .table .item-controls .btn {
      margin-right: 0;
      margin-left: 10px; }
  .btn-res {
    margin-top: 30px; }
    .btn-res .btn {
      margin-left: 15px; } }

@media only screen and (max-width: 767px) {
  .cart-content .pro-heading-title {
    padding-bottom: 20px; }
  .cart-content .btn-res {
    padding-top: 25px;
    justify-content: space-between !important; }
    .cart-content .btn-res .btn-link {
      padding-left: 0;
      padding-right: 0; }
  .cart-content .top-table {
    text-align: center; }
    .cart-content .top-table thead {
      display: none; }
    .cart-content .top-table tbody .d-flex {
      flex-direction: column;
      margin-bottom: 15px; }
    .cart-content .top-table tbody td {
      padding-right: 0; }
    .cart-content .top-table tbody td:first-child {
      border: none;
      padding-top: 0; }
    .cart-content .top-table tbody td:last-child {
      padding-bottom: 15px;
      text-align: center; }
  .cart-content .table-background {
    margin-bottom: 15px !important; }
    .cart-content .table-background thead {
      display: none; }
    .cart-content .table-background tbody .d-flex {
      flex-direction: column;
      border-bottom: none; }
    .cart-content .table-background tbody td:first-child {
      border: none; }
    .cart-content .table-background tbody td:last-child {
      text-align: center; }
  .cart-content .table {
    margin-bottom: 0;
    border-bottom: none; }
    .cart-content .table .item-detail {
      width: 100%; }
    .cart-content .table .item-controls {
      justify-content: center; }
    .cart-content .table .item-quantity {
      margin-left: auto;
      margin-right: auto; }
  .cart-content .table .item-controls .btn {
    margin-left: 5px;
    margin-right: 5px; }
  .cart-content.cart-page-2 .table .item-detail h2 {
    margin-bottom: 0; } }

.order-content {
  margin-bottom: 30px; }
  .order-content .pro-heading-title {
    padding-bottom: 35px; }
  .order-content .table {
    border-bottom: 1px solid #ddd; }
    .order-content .table th {
      border-top: none; }

.order-detail-content {
  margin-bottom: 30px; }
  .order-detail-content .pro-heading-title {
    padding-bottom: 35px; }
  .order-detail-content h4 {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1rem;
    padding-left: 15px; }
  .order-detail-content h2 {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1rem;
    margin-bottom: 30px; }
    .order-detail-content h2 small {
      text-transform: uppercase;
      color: #000; }
  .order-detail-content strong {
    font-family: "Montserrat-Bold", sans-serif; }
  .order-detail-content .item-controls {
    display: flex;
    align-items: center; }
    .order-detail-content .item-controls .btn {
      font-size: 12px;
      border: 1px solid #dee2e6;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      margin-right: 10px; }
      .order-detail-content .item-controls .btn:hover {
        background-color: #b38741;
        color: #fff; }
  .order-detail-content .top-table {
    background-color: transparent !important; }
    .order-detail-content .top-table tbody tr {
      align-items: center;
      border-bottom: 1px solid #dee2e6; }
      .order-detail-content .top-table tbody tr td {
        border: none; }
  .order-detail-content .table {
    background-color: #f8f9fa; }
    .order-detail-content .table th {
      border-top: none; }
    .order-detail-content .table td .pro-info {
      text-transform: uppercase; }
    .order-detail-content .table td .item-price {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 1rem;
      color: #b38741; }
  .order-detail-content .order-id {
    margin-bottom: 30px; }
    .order-detail-content .order-id tbody tr .pending p {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 0.8125rem;
      background-color: #b38741;
      color: #fff;
      margin: 0;
      padding: 3px 7px 2px 7px;
      min-width: 80px;
      max-width: 100px;
      text-transform: uppercase;
      text-align: center; }
    .order-detail-content .order-id tbody tr td {
      border-top: none;
      padding-left: 15px;
      color: #000; }
  .order-detail-content .comments-area {
    background-color: #fff;
    margin-bottom: 15px; }
    .order-detail-content .comments-area h4 {
      padding-left: 0; }
    .order-detail-content .comments-area p {
      font-size: 0.875rem; }

.star {
  font-weight: 800;
  color: red;
  position: relative;
  top: 3px; }

.shipping-content {
  margin-bottom: 30px; }
  .shipping-content .pro-heading-title {
    padding-bottom: 35px; }
  .shipping-content .add-address {
    padding: 15px 0; }
    .shipping-content .add-address h4 {
      font-family: "Montserrat-Bold", sans-serif;
      margin-bottom: 15px; }
  .shipping-content .table th {
    border-top: none; }
  .shipping-content .table tr td .form-check {
    padding-left: 35px; }
    .shipping-content .table tr td .form-check .form-check-input {
      position: absolute;
      left: 25px;
      top: 5px;
      margin-top: 0; }
    .shipping-content .table tr td .form-check .form-check-label {
      text-align: start; }
  .shipping-content .table tr .controls {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
    .shipping-content .table tr .controls li {
      margin-right: 15px;
      text-align: center; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shipping-content .table {
    margin-top: 30px; }
  .shipping-content .btn {
    margin-top: 15px; } }

@media only screen and (max-width: 767px) {
  .shipping-content .table {
    border-bottom: none; }
    .shipping-content .table thead {
      display: none; }
    .shipping-content .table tbody .d-flex {
      flex-direction: column; }
    .shipping-content .table tbody td:first-child {
      border: none; }
    .shipping-content .table tbody td:last-child {
      padding-bottom: 35px;
      text-align: left; }
  .shipping-content .btn {
    margin-top: 15px; } }

.bodyrtl .shipping-content .table tr .controls li {
  margin-left: 15px;
  margin-right: 0; }

.profile-content {
  margin-bottom: 30px; }
  .profile-content h5 {
    font-size: 1rem;
    font-family: "Montserrat-Bold", sans-serif; }
  .profile-content .pro-heading-title {
    padding-bottom: 35px; }
  .profile-content .media {
    display: flex;
    align-items: center;
    margin-bottom: 30px; }
    .profile-content .media img {
      width: 50px;
      height: 50px;
      border: 1px solid #ddd;
      border-radius: 200px; }
    .profile-content .media .media-body {
      margin-left: 15px; }
      .profile-content .media .media-body h5 {
        font-size: 1rem;
        margin-bottom: 0; }
        .profile-content .media .media-body h5 small span {
          color: #b38741; }
      .profile-content .media .media-body p {
        margin-bottom: 0; }
  .profile-content form {
    float: left;
    width: 100%;
    background-color: #f4f4f4;
    padding: 30px; }
    .profile-content form label.error {
      right: 15px; }
    .profile-content form .input-group.date .input-group-prepend {
      margin-right: 0; }
      .profile-content form .input-group.date .input-group-prepend .input-group-text {
        border-left-width: 0; }
    .profile-content form .input-group.date .datepicker-inline {
      position: absolute;
      left: 0;
      top: 100%;
      background-color: white;
      z-index: 999; }
    .profile-content form ul {
      padding-left: 10px;
      margin: 40px 0 0 0; }
      .profile-content form ul li {
        margin-bottom: 15px; }
    .profile-content form .btn {
      float: right; }

.bodyrtl .profile-content form label.error {
  left: 15px;
  right: auto;
  text-align: left; }

#datepicker {
  width: 100%; }

#datepicker > span:hover {
  cursor: pointer; }

@media only screen and (max-width: 767px) {
  .profile-content .media .media-body a {
    display: block; }
  .profile-content form {
    padding: 15px; }
  .profile-content form .btn.mt-3 {
    margin-top: 0 !important; }
  .profile-content form ul {
    padding-left: 15px;
    margin-top: 30px; } }

.checkout-content .pro-heading-title {
  padding-bottom: 35px; }

.checkout-content .checkout-module {
  padding: 0 15px;
  width: 100%; }
  .checkout-content .checkout-module .checkoutd-nav {
    padding-left: 0;
    padding-right: 0; }
    .checkout-content .checkout-module .checkoutd-nav .nav-item {
      font-family: "Montserrat-Bold", sans-serif;
      width: calc(25% - 17px);
      margin-right: 17px; }
    .checkout-content .checkout-module .checkoutd-nav .nav-item a {
      background-color: #e1e1e1;
      position: relative;
      padding-left: 30px;
      padding-top: 8px;
      padding-bottom: 8px; }
    .checkout-content .checkout-module .checkoutd-nav .nav-item a:after {
      content: '';
      border-top: 18.5px solid transparent;
      border-bottom: 18.5px solid transparent;
      border-left: 18.5px solid #e1e1e1;
      position: absolute;
      right: -18px;
      top: 0;
      z-index: auto;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .checkout-content .checkout-module .checkoutd-nav .nav-item a:before {
      content: '';
      border-top: 18.5px solid transparent;
      border-bottom: 18.5px solid transparent;
      border-left: 18.5px solid #fff;
      position: absolute;
      left: 0;
      top: 0;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .checkout-content .checkout-module .checkoutd-nav .nav-item a.active {
      background-color: #b38741;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .checkout-content .checkout-module .checkoutd-nav .nav-item a.active:after {
      content: '';
      border-top: 18.5px solid transparent;
      border-bottom: 18.5px solid transparent;
      border-left: 18.5px solid #b38741;
      position: absolute;
      right: -18px;
      top: 0;
      z-index: auto;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .checkout-content .checkout-module .checkoutd-nav .nav-item a.active:before {
      content: '';
      border-top: 18.5px solid transparent;
      border-bottom: 18.5px solid transparent;
      border-left: 18.5px solid #fff;
      position: absolute;
      left: 0;
      top: 0;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
  .checkout-content .checkout-module .form-group .form-check-inline {
    margin-right: 5px;
    background-color: white;
    height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left; }
  .checkout-content .checkout-module .btn {
    margin-right: 15px; }

.checkout-content .right-table {
  margin-bottom: -19px; }
  .checkout-content .right-table thead {
    background-color: #fff; }
    .checkout-content .right-table thead th {
      border-top: none; }
  .checkout-content .right-table tbody {
    background-color: #fff; }

.checkout-content .top-table tbody tr {
  align-items: center;
  border-bottom: 1px solid #ddd; }
  .checkout-content .top-table tbody tr td {
    border: none; }

.checkout-content .item-detail {
  float: left; }
  .checkout-content .item-detail h2 {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 1rem;
    margin-bottom: 15px; }
    .checkout-content .item-detail h2 small {
      color: #000; }
  .checkout-content .item-detail .item-controls {
    display: flex;
    align-items: center; }
    .checkout-content .item-detail .item-controls .btn {
      font-size: 12px;
      border: 1px solid #dee2e6;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      margin-right: 10px; }
      .checkout-content .item-detail .item-controls .btn:hover {
        background-color: #b38741;
        color: #fff; }

.checkout-content .item-price,
.checkout-content .item-total {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 1.25rem;
  color: #b38741; }

.checkout-content .tab-content .tab-pane h4 {
  font-family: "Montserrat-Bold", sans-serif;
  margin-bottom: 15px; }

.checkout-content .tab-content .tab-pane .heading {
  margin-top: 15px; }
  .checkout-content .tab-content .tab-pane .heading h4 {
    margin-bottom: 0; }

.checkout-content .tab-content .tab-pane .top-table thead {
  background-color: #fff; }
  .checkout-content .tab-content .tab-pane .top-table thead tr th {
    border-top: none; }

.checkout-content .tab-content .tab-pane .top-table tbody {
  background-color: #fff; }

.bodyrtl .checkout-content .item-detail {
  float: right; }

@media only screen and (max-width: 767px) {
  .checkout-content .pro-heading-title {
    padding-bottom: 20px; }
  .checkout-content .checkout-module .tab-content .tab-pane .top-table {
    border-bottom: none;
    text-align: center; }
    .checkout-content .checkout-module .tab-content .tab-pane .top-table thead {
      display: none; }
    .checkout-content .checkout-module .tab-content .tab-pane .top-table tbody .d-flex {
      flex-direction: column; }
    .checkout-content .checkout-module .tab-content .tab-pane .top-table tbody td {
      padding-right: 0; }
      .checkout-content .checkout-module .tab-content .tab-pane .top-table tbody td .input-group {
        justify-content: center; }
    .checkout-content .checkout-module .tab-content .tab-pane .top-table tbody td:first-child {
      border: none; }
    .checkout-content .checkout-module .tab-content .tab-pane .top-table tbody td:last-child {
      padding-bottom: 15px;
      text-align: center; }
  .checkout-content .checkout-module .btn-res {
    padding-top: 25px; }
  .checkout-content .checkout-module .form-group .form-check-inline {
    width: 100%;
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 15px; }
  .checkout-content .item-detail {
    width: 100%; }
    .checkout-content .item-detail h2 {
      margin-bottom: 10px; }
    .checkout-content .item-detail .item-controls {
      justify-content: center; }
      .checkout-content .item-detail .item-controls .btn {
        margin-right: 5px;
        margin-left: 5px; }
  .checkout-content .item-quantity {
    margin-right: auto;
    margin-left: auto; } }

.compare-content {
  margin-bottom: 30px; }
  .compare-content .pro-heading-title {
    padding-bottom: 35px; }
  .compare-content .table {
    background-color: #fff;
    margin-bottom: 0; }
    .compare-content .table thead td {
      border: none;
      padding-left: 0;
      padding-right: 0; }
      .compare-content .table thead td .img-div {
        padding: 15px;
        background-color: #f8f9fa;
        border: 1px solid #dee2e6; }
    .compare-content .table tbody tr td {
      font-size: 0.875rem;
      color: #000;
      padding-right: 15px;
      border-bottom: 1px solid #dee2e6;
      border-top: none; }
      .compare-content .table tbody tr td h4 {
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 1rem;
        color: #000;
        margin: 0; }
      .compare-content .table tbody tr td span {
        display: inline-block;
        font-size: 0.875rem;
        font-family: "Montserrat-Bold", sans-serif; }
      .compare-content .table tbody tr td .price-tag {
        font-size: 1rem;
        color: #b38741; }
      .compare-content .table tbody tr td .detail-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between; }
    .compare-content .table tbody tr:last-child td {
      border: none;
      padding-bottom: 0; }

@media only screen and (max-width: 767px) {
  .compare-content .pro-heading-title {
    padding-bottom: 10px; }
    .compare-content .pro-heading-title h1 {
      font-size: 20px !important; } }

.wishlist-content {
  margin-bottom: 30px; }
  .wishlist-content .pro-heading-title {
    padding-bottom: 35px; }
  .wishlist-content .media .media-thumb {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border: 1px solid #ddd;
    margin-right: 1rem; }
    .wishlist-content .media .media-thumb img {
      transform: translateY(-10%); }
  .wishlist-content .media .media-body h2 {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 0.875rem; }
  .wishlist-content .media .media-body .pro-price {
    display: flex;
    align-items: center;
    margin-bottom: 5px; }
    .wishlist-content .media .media-body .pro-price ins {
      font-family: "Montserrat-ExtraBold", sans-serif;
      font-size: 1.2rem;
      color: #b38741;
      text-decoration: none; }
    .wishlist-content .media .media-body .pro-price del {
      font-size: 1rem;
      font-weight: 800;
      color: #000;
      text-decoration: line-through;
      margin-right: 10px; }
  .wishlist-content .media .media-body .buttons {
    display: flex;
    align-items: center; }
    .wishlist-content .media .media-body .buttons .input-group {
      margin-right: 10px; }
  .wishlist-content .media .media-body p {
    font-size: 0.875rem;
    color: #000; }
  .wishlist-content .media .media-body .detail {
    text-align: end; }
    .wishlist-content .media .media-body .detail p {
      margin-bottom: 5px;
      color: #000; }
    .wishlist-content .media .media-body .detail .share {
      margin-bottom: 5px; }

.bodyrtl .wishlist-content .media .media-thumb {
  margin-left: 1rem;
  margin-right: 0; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-content .list-group {
    margin-bottom: 30px; }
  .wishlist-content .media .media-body .pro-price {
    margin-bottom: 10px; } }

@media only screen and (max-width: 767px) {
  .wishlist-content .pro-heading-title {
    padding-bottom: 10px; }
  .wishlist-content .list-group {
    margin-bottom: 60px; }
  .wishlist-content .media {
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .wishlist-content .media .media-thumb {
      margin-right: 0;
      width: 100%;
      height: auto; }
      .wishlist-content .media .media-thumb img {
        transform: none; }
    .wishlist-content .media .media-body {
      margin-top: 20px; }
      .wishlist-content .media .media-body .pro-price {
        justify-content: center;
        margin-bottom: 10px; }
      .wishlist-content .media .media-body .buttons {
        justify-content: center; }
      .wishlist-content .media .media-body .detail {
        display: flex;
        justify-content: center; }
        .wishlist-content .media .media-body .detail .share {
          margin: 15px 10px -5px 10px; } }

.empty-content {
  margin-top: 100px; }
  .empty-content .pro-empty-page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 33px 0 92px; }
    .empty-content .pro-empty-page h2 {
      font-family: "Montserrat-Bold", sans-serif;
      color: #ebebeb;
      font-size: 36px;
      line-height: 43px;
      padding: 0;
      margin: 0; }
    .empty-content .pro-empty-page h1 {
      font-family: "Montserrat-Bold", sans-serif;
      font-size: 64px;
      line-height: 77px; }
    .empty-content .pro-empty-page p {
      margin-top: 25px; }

@media only screen and (max-width: 767px) {
  .empty-content {
    margin-top: 30px; }
    .empty-content .pro-empty-page {
      padding-bottom: 0; }
      .empty-content .pro-empty-page p {
        margin-bottom: 0; } }

/*!
 * Bootstrap-select v1.13.9 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2019 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */
.bootstrap-select > select.bs-select-hidden,
select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px\0;
  vertical-align: middle; }

.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.bootstrap-select > .dropdown-toggle:after {
  margin-top: -1px; }

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #999; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover {
  color: rgba(255, 255, 255, 0.5); }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2 !important; }

.bootstrap-select.is-invalid .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
  border-color: #28a745; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 220px; }

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: thin dotted #333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }

:not(.input-group) > .bootstrap-select.form-control:not([class*='col-']) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  float: none;
  z-index: auto; }

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.form-control:not([class*='col-']) {
  width: auto; }

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*='col-'] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*='col-'].dropdown-menu-right,
.row .bootstrap-select[class*='col-'].dropdown-menu-right {
  float: right; }

.form-group .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-inline .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }

.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-lg .dropdown-toggle,
.bootstrap-select.form-control-sm .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }

.bootstrap-select.disabled:focus,
.bootstrap-select > .disabled:focus {
  outline: 0 !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.bs3.bootstrap-select .dropdown-toggle .filter-option {
  padding-right: inherit; }

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  padding-top: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  float: none; }

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
  padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*='col-'] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .dropdown-menu > .inner:focus {
  outline: 0 !important; }

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select .dropdown-menu li {
  position: relative; }

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important; }

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em; }

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0;
  width: auto; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: '\00a0'; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid #fff;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before {
  display: block; }

.bs-actionsbox,
.bs-donebutton,
.bs-searchbox {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer,
.fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url("fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url("fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick.eot");
  src: url("../../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick.woff") format("woff"), url("../../fonts/slick.ttf") format("truetype"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black; }

@font-face {
  font-family: 'Montserrat-Regular';
  src: url("../../fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.woff") format("woff"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.ttf") format("truetype"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff") format("woff"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.ttf") format("truetype"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("../../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.woff") format("woff"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.ttf") format("truetype"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@media only screen and (min-width: 1025px) {
  .header-desktop {
    display: block; }
  .header-mobile {
    display: none; } }

@media only screen and (max-width: 1024px) {
  .header-mobile {
    display: block; }
  .header-desktop {
    display: none; }
  .sticky-header {
    display: none !important; }
  .bodyrtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile {
    right: -250px;
    left: auto; }
  .bodyrtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile.navigation-active {
    right: 0; }
  .bodyrtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .main-manu {
    text-align: right; }
  .bodyrtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .logout-main .logout a {
    float: left;
    right: auto;
    left: 0.75rem; }
  .bodyrtl .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap .btn-secondary {
    right: auto;
    left: 0; }
  .bodyrtl .header-mobile .header-navbar .form-inline .search-field-module .search-field-select {
    float: right; }
  .bodyrtl .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap input {
    text-align: right;
    padding: 0  20px 0 55px; }
  .bodyrtl .header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
    padding: 0 15px 0 0;
    width: 80px; }
  .bodyrtl .header-mobile .header-navbar .form-inline .search-field-module .search-field-select::before {
    right: auto;
    left: -10px; }
  .bodyrtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile a[aria-expanded="true"]:after {
    right: auto;
    left: 20px; }
  .bodyrtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile .sub-manu .unorder-list li a {
    text-align: right; }
  .bodyrtl .header-mobile .header-maxi .navigation-mobile-container #navigation-mobile a[aria-expanded="false"]:after {
    left: 20px;
    right: auto; } }

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .header-mobile .header-navbar .form-inline .search-field-module .search-field-select {
    width: 150px !important; }
  .header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
    width: 150px; }
  .header-mobile .header-navbar .form-inline .search-field-module .search-field-wrap {
    width: calc(100% - 150px); }
  .bodyrtl .header-mobile .header-navbar .form-inline .search-field-module .search-field-select .form-control {
    padding: 0 15px 0 0;
    width: 150px; } }

@media only screen and (min-width: 1200px) {
  .padding-r0 {
    padding-right: 0; }
  .padding-l0 {
    padding-left: 0; }
  .footer-one .single-footer .newsletter .block .form-inline .search .subscribe {
    display: block; }
  .footer-one .single-footer .newsletter .block .form-inline .search .fa-location-arrow {
    display: none; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info-boxes-content .info-box-full {
    border: none;
    padding: 0;
    padding-top: 15px;
    margin: 0; }
    .info-boxes-content .info-box-full .info-box {
      justify-content: start;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px; }
  .modal .modal-dialog {
    width: 98%; }
  .listing .product article .pro-thumb2 {
    width: 36%; }
  .slick-slider {
    padding-bottom: 15px; }
  .sticky-header .header-maxi nav .navbar-collapse ul li {
    margin-right: 30px; }
  .padding-r0 {
    padding-right: 0; }
  .padding-l0 {
    padding-left: 0; }
  .rev_slider_wrapper li img {
    width: 100%;
    height: 100%; }
  .checkout-area .checkout-module .checkoutd-nav .nav-item {
    width: 23%;
    margin-right: 15px;
    margin-bottom: 15px; }
  /* shop page */
  .right-menu .main-manu {
    font-size: 12px; }
  .right-menu .sub-manu .unorder-list .list-item a {
    font-size: 12px; }
  .ban-img {
    height: 311px; }
  .caption3 {
    position: absolute;
    top: 20%;
    right: 0px;
    width: 77% !important; }
  .caption-lb1 {
    width: 247px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal .modal-dialog {
    width: 98%;
    max-width: 700px; }
  .header-mobile .header-mini .navbar {
    padding: 0;
    justify-content: space-between; }
  .product article .thumb {
    padding: 15px; }
    .product article .thumb .img-fluid {
      margin-left: auto;
      margin-right: auto;
      height: 100%; }
  .product article .timer-tag {
    bottom: -19px; }
  .product article .upcomming-tag {
    bottom: -19px; }
  .product article .mobile-icons {
    position: absolute;
    left: 7px; }
    .product article .mobile-icons .icon-liked {
      position: relative; }
      .product article .mobile-icons .icon-liked .badge {
        font-family: "Roboto", sans-serif;
        border-radius: 0;
        position: absolute;
        right: -10px;
        top: -6px; }
    .product article .mobile-icons .icon {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;
      border: 1px solid #ddd;
      border-radius: 0;
      font-size: 1rem;
      color: #b38741;
      text-decoration: none;
      margin-bottom: 7px;
      cursor: pointer; }
      .product article .mobile-icons .icon:hover {
        background-color: #e9ecef;
        color: #1d1d1d; }
  .product article .mobile-buttons {
    text-align: center; }
    .product article .mobile-buttons a.btn {
      width: 49%; }
  .info-boxes-content .info-box-full {
    border: none;
    padding: 0;
    padding-top: 15px;
    margin: 0; }
    .info-boxes-content .info-box-full .info-box {
      justify-content: start;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px; }
  .footer-mobile .single-footer .links-list li a {
    font-size: 0.775rem; }
  .checkout-area .checkout-module .checkoutd-nav .nav-item {
    width: 23%;
    margin-right: 15px;
    margin-bottom: 15px; }
  .click-btn .input-group {
    margin-bottom: 15px; }
  .align-right {
    margin-bottom: 15px; } }

@media only screen and (max-width: 991px) {
  .header-mobile .header-maxi {
    padding-bottom: 0; }
  .header-mobile .header-maxi .logo {
    padding-top: 0; }
  .banners-content .container-fluid figure, .banners-content .container-sm figure, .banners-content .container-md figure, .banners-content .container-lg figure, .banners-content .container-xl figure {
    margin-bottom: 10px; }
  .listing .product article .pro-thumb2 {
    width: 36%; }
  .listing .list2 p {
    display: none; }
  .breadcrumb {
    margin-top: 15px; }
  .info-boxes-content {
    border-top: none; }
  .product-carousel2 .product {
    padding: 15px; }
  .checkout-content .checkout-module .form-group {
    padding: 0 !important; }
  .checkout-content .checkout-module .checkoutm-nav .nav-item {
    width: calc(33.33% - 15px);
    position: relative; }
    .checkout-content .checkout-module .checkoutm-nav .nav-item a {
      background-color: #e0e0e2;
      border-radius: 200px;
      padding: 5px;
      width: 45px;
      height: 45px;
      color: #333;
      font-weight: bold; }
      .checkout-content .checkout-module .checkoutm-nav .nav-item a span {
        display: flex;
        margin-right: 0px;
        border-radius: 200px;
        background-color: white;
        border: 4px solid #333;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%; }
      .checkout-content .checkout-module .checkoutm-nav .nav-item a.active {
        background-color: #e0e0e2;
        color: #333; }
        .checkout-content .checkout-module .checkoutm-nav .nav-item a.active span {
          border-color: #6ad180; }
    .checkout-content .checkout-module .checkoutm-nav .nav-item::before {
      content: "";
      height: 4px;
      width: 100%;
      display: block;
      background-color: #c8c8c8;
      position: absolute;
      top: 21px;
      z-index: -1; }
    .checkout-content .checkout-module .checkoutm-nav .nav-item:last-child {
      width: 45px; }
      .checkout-content .checkout-module .checkoutm-nav .nav-item:last-child::before {
        content: "";
        width: 0; }
  .right-menu .img-main {
    margin-bottom: 15px; }
    .right-menu .img-main img {
      width: 100%; }
  .product-page .xzoom-container {
    margin-bottom: 30px; }
    .product-page .xzoom-container .xzoom-thumbs {
      display: none; }
  .product-page h1 {
    font-size: 1rem;
    width: 88%; }
  .product-page .controls {
    padding-left: 0;
    padding-right: 0;
    display: block; }
    .product-page .controls .select-control {
      margin-bottom: 15px; }
    .product-page .controls .input-group {
      width: 100%; }
  .product-page .product-buttons {
    display: block; }
    .product-page .product-buttons h2 {
      margin-bottom: 7px; }
    .product-page .product-buttons .buttons {
      margin-top: 7px;
      width: 100%; }
  .product-page-two .xzoom-container {
    margin-top: 15px;
    margin-bottom: 0; }
  .product-page-three .xzoom-3 .xzoom2, .product-page-three .xzoom-3 .xzoom3, .product-page-three .xzoom-3 .xzoom4, .product-page-three .xzoom-3 .xzoom5 {
    width: 100% !important;
    height: auto; }
  .product-page-three .add-favrt {
    justify-content: center;
    background-color: #fff;
    padding: 5px;
    margin-bottom: 15px; }
  .product-page-three .owl-nav {
    top: 35%;
    position: absolute;
    transform: translateY(-50%);
    width: 100%; }
    .product-page-three .owl-nav button {
      display: inline-block;
      background-color: #e9ecef !important;
      color: #000 !important;
      border: 1px solid #ddd !important;
      border-radius: 0;
      margin-left: 1px;
      height: 38px;
      width: 38px;
      text-align: center;
      line-height: 38px;
      text-decoration: none;
      outline: none; }
      .product-page-three .owl-nav button:hover {
        background-color: #b38741 !important;
        color: #fff !important; }
      .product-page-three .owl-nav button span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 2rem;
        padding-bottom: 7px; }
    .product-page-three .owl-nav .disabled {
      opacity: 0.3; }
      .product-page-three .owl-nav .disabled:hover {
        background-color: #e9ecef !important;
        color: #000 !important; }
    .product-page-three .owl-nav .owl-prev {
      position: absolute;
      margin-left: -45px;
      border-radius: 50px; }
    .product-page-three .owl-nav .owl-next {
      position: absolute;
      margin-right: -45px;
      border-radius: 50px;
      right: 0; }
  .product-page-three .tab-list .nav-index {
    width: 31%;
    padding: 5px;
    font-size: 12px; }
  .product-page-three .tab-list .nav-index:last-child {
    margin-right: 0; }
  .product-page-four .xzoom-container {
    margin-bottom: 0; }
  .product-page-four .add-favrt {
    justify-content: center;
    background-color: #fff;
    padding: 5px;
    margin-bottom: 15px; }
  .product-page-four .product-buttons .buttons {
    padding-left: 0;
    padding-right: 0; }
  .product-page-four .product-buttons .icon-liked {
    position: absolute;
    top: 0;
    right: 14px; }
  .product-page-four .tab-list .nav-index {
    text-align: center;
    width: 31%;
    padding: 5px;
    font-size: 12px; }
  .product-page-four .tab-list .nav-index:last-child {
    margin-right: 0; }
  .product-page-five .add-favrt {
    justify-content: center;
    background-color: #fff;
    padding: 5px;
    margin-bottom: 15px; }
  .product-page-five .product-buttons .buttons {
    padding-left: 0;
    padding-right: 0; }
  .product-page-five .product-buttons .icon-liked {
    position: absolute;
    top: 0;
    right: 14px; }
  .product-page-five .tab-list .nav-index {
    text-align: center;
    width: 31%;
    padding: 5px;
    font-size: 12px; }
  .product-page-five .tab-list .nav-index:last-child {
    margin-right: 0; }
  .product-page-six .add-favrt {
    justify-content: center;
    background-color: #fff;
    padding: 5px;
    margin-bottom: 15px; }
  .product-page-six .tab-list .nav-index {
    text-align: center;
    width: 31%;
    padding: 5px;
    font-size: 12px; }
  .product-page-six .tab-list .nav-index:last-child {
    margin-right: 0; }
  .blog-content .blog .blog-thumbnail .img-thumbnail {
    height: 100%; }
  .blog-content .pagination {
    margin-bottom: 15px; }
  .ban-img {
    height: 280px; }
  .nav-img {
    height: 100%; }
  .carousel-caption {
    position: absolute;
    top: 8%;
    right: 12%;
    width: 300px; }
    .carousel-caption .text-deco1 h1 {
      margin: 0;
      color: #ffffff;
      font-size: 24px;
      line-height: 53px;
      position: relative;
      z-index: 99999;
      font-family: sans-serif; }
    .carousel-caption .text-deco1 p {
      font-size: 21px;
      font-family: sans-serif;
      position: relative;
      line-height: normal;
      color: #ffffff;
      margin: 0; }
    .carousel-caption .text-deco1 .btn {
      position: relative;
      bottom: -25px;
      background-color: #ffffff;
      font-family: sans-serif; }
    .carousel-caption .text-deco2 {
      text-align: left; }
      .carousel-caption .text-deco2 h2 {
        font-size: 11rem;
        position: absolute;
        line-height: 93px;
        opacity: 0.4; }
      .carousel-caption .text-deco2 h1 {
        color: #ffffff;
        font-family: sans-serif;
        font-size: 32px;
        text-align: left;
        z-index: 99999;
        margin: 0; }
        .carousel-caption .text-deco2 h1 span {
          color: #ffffff; }
      .carousel-caption .text-deco2 p {
        font-size: 14px;
        font-family: sans-serif;
        text-align: left;
        line-height: 0;
        color: #ffffff; }
      .carousel-caption .text-deco2 .btn {
        position: relative;
        bottom: -13px;
        border: 3px solid #ffffff;
        color: #ffffff;
        font-family: sans-serif; }
    .carousel-caption .text-deco3 {
      text-align: right; }
      .carousel-caption .text-deco3 h1 {
        color: black;
        font-family: sans-serif;
        font-size: 36px;
        line-height: 85px;
        text-align: right;
        z-index: 99999;
        margin: 0; }
        .carousel-caption .text-deco3 h1 span {
          color: red; }
      .carousel-caption .text-deco3 p {
        font-size: 16px;
        font-family: sans-serif;
        text-align: right;
        margin: 0;
        line-height: 0;
        color: black; }
      .carousel-caption .text-deco3 .btn {
        font-size: 19px;
        margin: 0;
        padding: 0;
        font-family: sans-serif; }
    .carousel-caption .text-deco4 {
      text-align: center; }
      .carousel-caption .text-deco4 h1 {
        color: #ffffff;
        font-size: 22px;
        text-align: center;
        position: relative;
        z-index: 99999;
        font-family: sans-serif;
        line-height: 21px; }
      .carousel-caption .text-deco4 p {
        font-size: 25px;
        font-family: sans-serif;
        position: relative;
        text-align: center;
        color: #ffffff;
        font-weight: 600; }
      .carousel-caption .text-deco4 .btn {
        position: relative;
        bottom: -13px;
        background-color: #ffffff;
        font-family: sans-serif;
        font-size: 10px; }
    .carousel-caption .text-deco5 {
      text-align: left; }
      .carousel-caption .text-deco5 h1 {
        color: #ffffff;
        font-family: sans-serif;
        font-size: 30px;
        text-align: left;
        z-index: 99999;
        margin: 0; }
        .carousel-caption .text-deco5 h1 span {
          color: #ffffff; }
      .carousel-caption .text-deco5 p {
        font-size: 14px;
        font-family: sans-serif;
        text-align: left;
        line-height: 0;
        color: #ffffff; }
      .carousel-caption .text-deco5 .btn {
        position: relative;
        bottom: -25px;
        border: 3px solid #ffffff;
        color: #ffffff;
        font-family: sans-serif; }
    .carousel-caption .text-deco6 {
      text-align: right; }
      .carousel-caption .text-deco6 h2 {
        font-size: 11rem;
        position: absolute;
        line-height: 93px;
        margin: 0;
        opacity: 0.4; }
      .carousel-caption .text-deco6 h1 {
        color: black;
        font-family: sans-serif;
        font-size: 28px;
        line-height: 66px;
        text-align: right;
        z-index: 99999;
        margin: 0; }
        .carousel-caption .text-deco6 h1 span {
          color: red; }
      .carousel-caption .text-deco6 p {
        font-size: 13px;
        font-family: sans-serif;
        text-align: right;
        margin: 0;
        line-height: 0;
        color: black; }
      .carousel-caption .text-deco6 .btn {
        font-size: 19px;
        margin: 0;
        padding: 0;
        font-family: sans-serif; }
    .carousel-caption .text-deco7 {
      text-align: left; }
      .carousel-caption .text-deco7 h2 {
        font-size: 11rem;
        position: absolute;
        line-height: 93px;
        margin: 0;
        opacity: 0.4;
        font-family: sans-serif; }
      .carousel-caption .text-deco7 h1 {
        color: #ffffff;
        font-size: 27px;
        line-height: 85px;
        text-align: left;
        position: relative;
        z-index: 99999;
        font-family: sans-serif; }
      .carousel-caption .text-deco7 p {
        font-size: 29px;
        font-family: sans-serif;
        position: relative;
        text-align: left;
        line-height: 0;
        color: #ffffff; }
      .carousel-caption .text-deco7 .btn {
        position: relative;
        bottom: -25px;
        background-color: #ffffff;
        font-family: sans-serif; }
    .carousel-caption .text-deco8 {
      text-align: left; }
      .carousel-caption .text-deco8 h2 {
        font-size: 11rem;
        position: absolute;
        line-height: 93px;
        opacity: 0.4; }
      .carousel-caption .text-deco8 h1 {
        color: #ffffff;
        font-family: sans-serif;
        font-size: 44px;
        text-align: left;
        z-index: 99999;
        margin: 0; }
        .carousel-caption .text-deco8 h1 span {
          color: #ffffff; }
      .carousel-caption .text-deco8 p {
        font-size: 20px;
        font-family: sans-serif;
        text-align: left;
        line-height: 0;
        color: #ffffff; }
      .carousel-caption .text-deco8 .btn {
        position: relative;
        bottom: -25px;
        border: 3px solid #ffffff;
        color: #ffffff;
        font-family: sans-serif; }
    .carousel-caption .text-deco9 {
      text-align: right; }
      .carousel-caption .text-deco9 h1 {
        color: black;
        font-family: sans-serif;
        font-size: 35px;
        line-height: 85px;
        text-align: right;
        z-index: 99999;
        margin: 0; }
        .carousel-caption .text-deco9 h1 span {
          color: red; }
      .carousel-caption .text-deco9 p {
        font-size: 16px;
        font-family: sans-serif;
        text-align: right;
        margin: 0;
        line-height: 0;
        color: black; }
      .carousel-caption .text-deco9 .btn {
        font-size: 19px;
        margin: 0;
        padding: 0;
        font-family: sans-serif; }
    .carousel-caption .text-deco11 {
      text-align: left; }
      .carousel-caption .text-deco11 h2 {
        font-size: 11rem;
        position: absolute;
        line-height: 93px;
        opacity: 0.4; }
      .carousel-caption .text-deco11 h1 {
        font-size: 23px; }
        .carousel-caption .text-deco11 h1 span {
          color: #ffffff; }
      .carousel-caption .text-deco11 p {
        font-size: 10px; }
      .carousel-caption .text-deco11 .btn {
        position: relative;
        bottom: -14px;
        font-size: 10px;
        border: 1px solid; } }

@media only screen and (max-width: 767px) {
  .product-content .related-product-content {
    margin-top: 30px; }
  #back-to-top {
    bottom: 15px;
    right: 15px; }
  #newsletterModal .promo-box {
    padding: 30px 0 30px 0; }
  .pro-mini-content .pro-banner-text {
    padding: 15px; }
    .pro-mini-content .pro-banner-text img {
      width: 124px; }
  .login-content .registration-process:first-child {
    margin-bottom: 30px; }
  .login-content .registration-socials {
    padding: 0; }
    .login-content .registration-socials .btn {
      margin-bottom: 10px; }
  .banners-content {
    padding-top: 10px; }
  .blog-content .blog-col {
    justify-content: center;
    display: flex; }
  .products-content .products-area .owl-carousel {
    width: 220px;
    margin-left: auto;
    margin-right: auto; }
  .category-area article {
    padding: 0; }
  .product article .cat-title {
    font-size: 10px !important; }
  .footer-mobile .display-mobile {
    display: block; }
  .footer-mobile .copyright-content {
    margin-top: 0; }
    .footer-mobile .copyright-content .footer-image {
      float: none;
      text-align: center; }
    .footer-mobile .copyright-content .footer-info {
      text-align: center; }
  .heading h2 {
    font-size: 13px;
    margin-bottom: 3px; }
    .heading h2 small {
      font-size: 12px;
      line-height: normal; }
  .heading hr:before {
    display: none; }
  .product-2x {
    height: auto; }
    .product-2x article .module {
      position: static;
      text-align: center; }
      .product-2x article .module .title {
        margin-top: 0; }
      .product-2x article .module .price {
        margin-top: 0; }
      .product-2x article .module .list {
        display: none; }
    .product-2x article .thumb {
      position: static;
      margin-top: 42px;
      width: 100%; }
    .product-2x article .buttons .btn {
      width: 49%; }
  .products-area .nav-pills .nav-link {
    width: 32.60%;
    font-size: 10px; }
  .products-area .nav-pills .active:after {
    content: '';
    position: absolute;
    left: 44%;
    top: 31px;
    width: 0;
    height: 0;
    right: 14px; }
  .products-area .owl-nav {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    width: 100%; }
    .products-area .owl-nav .owl-prev {
      position: absolute;
      margin-left: -40px;
      border-radius: 50px; }
    .products-area .owl-nav .owl-next {
      position: absolute;
      margin-right: -40px;
      border-radius: 50px;
      right: 0; }
  .shop-content .breadcum-area {
    padding: 30px; }
    .shop-content .breadcum-area .breadcrumb-item {
      padding-left: 0;
      width: 100%; }
  .shipping-content .list-group {
    margin-bottom: 15px; }
  .shipping-content .shipping-table tbody tr {
    display: flex;
    flex-direction: column; }
  .shipping-content .main-form form .btn {
    width: 100%; }
  .order-table thead {
    display: none; }
  .order-table tbody tr {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px; }
    .order-table tbody tr td {
      display: flex;
      flex-direction: column;
      padding-left: 15px; }
      .order-table tbody tr td span {
        font-weight: 700; }
  .aboutus-content .media-col {
    margin-bottom: 25px;
    border: none; }
  .aboutus-content .media {
    display: block;
    text-align: center; }
    .aboutus-content .media .rounded-circle {
      margin-right: 0;
      margin-bottom: 15px; }
  .aboutus-content .accordion-container {
    padding-bottom: 30px;
    padding-top: 30px; }
    .aboutus-content .accordion-container .accordions {
      padding-bottom: 35px; }
  .order-detail-content .table {
    border-bottom: none; }
    .order-detail-content .table thead {
      display: none; }
    .order-detail-content .table tbody .d-flex {
      flex-direction: column; }
    .order-detail-content .table tbody td {
      padding-right: 0; }
      .order-detail-content .table tbody td h2 {
        margin-bottom: 0; }
      .order-detail-content .table tbody td .input-group {
        justify-content: center; }
    .order-detail-content .table tbody td:first-child {
      border: none; }
  .order-detail-content .order-id tbody tr:last-child td:first-child {
    padding-bottom: 0; }
  .order-detail-content .top-table {
    text-align: center; } }

@media only screen and (max-width: 576px) {
  .pro-category-content {
    padding-top: 0;
    padding-bottom: 30px; }
    .pro-category-content .pro-heading-subtitle {
      padding-top: 30px; }
  .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-info {
    font-size: 11px; }
  .slider-wrapper .slider-for {
    margin-bottom: 0; }
  .profile-content .media .media-body p {
    font-size: 12px; }
  .slick-slider {
    padding-bottom: 0px; }
  .pro-tab-content .nav .nav-link {
    font-size: 0.8125rem;
    padding: 0.5rem 6px; }
  .sticky-header {
    display: none !important; }
  .registration-socials .btn {
    width: 100%; }
  .pagination {
    display: block;
    text-align: center; }
    .pagination .loader-page {
      padding-bottom: calc(0.375rem + 1px); }
  .product-detail-info .pro-sub-buttons #st-1.st-animated {
    display: flex; }
  .product-detail-info .vertical-slider .slider-nav .slick-track .slider-nav__item img {
    height: 115px !important; }
  .product-detail-info .pro-counter .btn-secondary {
    min-width: 150px; }
  .product-detail-info .nav .nav-item {
    width: 33.33%;
    margin-right: 0 !important;
    padding: 3px 1px !important;
    display: flex;
    align-items: center;
    justify-content: center; } }

@media only screen and (max-width: 465px) {
  .product-detail-info .vertical-slider .slider-nav .slick-track .slider-nav__item img {
    height: 70px !important; }
  .product-detail-info .pro-counter .btn-secondary {
    min-width: 150px; } }

@font-face {
  font-family: 'Montserrat-Regular';
  src: url("../../fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.woff") format("woff"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.ttf") format("truetype"), url("../../fonts/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.woff") format("woff"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.ttf") format("truetype"), url("../../fonts/Montserrat-ExtraBold/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("../../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.woff") format("woff"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.ttf") format("truetype"), url("../../fonts/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

.bodyrtl {
  overflow-x: hidden;
  text-align: right;
  direction: rtl; }
  .bodyrtl label.error {
    left: 0;
    right: auto;
    text-align: left; }
  .bodyrtl .header-area .alert .pro-description .close {
    left: -5px;
    right: auto; }
  .bodyrtl .header-area .navbar-nav .badge-secondary {
    left: auto !important;
    right: -12px; }
    .bodyrtl .header-area .navbar-nav .badge-secondary::after {
      left: auto !important;
      right: 14px;
      transform: rotate(28deg) !important; }
  .bodyrtl .header-area .pro-header-right-options li > a .badge, .bodyrtl .header-area .pro-header-right-options li button .badge {
    left: -4px;
    right: auto; }
  .bodyrtl .header-one .header-maxi .search-field-module .search-field-select .bootstrap-select > .dropdown-menu {
    right: 0;
    left: auto !important; }
    .bodyrtl .header-one .header-maxi .search-field-module .search-field-select .bootstrap-select > .dropdown-menu .dropdown-item {
      text-align: right; }
  .bodyrtl .header-two .header-mini .dropdown {
    margin-right: 15px;
    margin-left: 0;
    padding-left: 12px;
    padding-right: 0; }
  .bodyrtl .header-one .navbar-lang .dropdown .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    text-align: right;
    margin-right: -28px;
    margin-left: 0; }
  .bodyrtl .header-two .navbar-lang .dropdown .dropdown-menu {
    left: 0 !important;
    right: auto !important;
    text-align: right;
    margin-right: -28px;
    margin-left: 0; }
  .bodyrtl .header-three .navbar-lang .dropdown .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    text-align: right;
    margin-right: -28px;
    margin-left: 0; }
  .bodyrtl .header-one .header-mini .navbar-lang .dropdown {
    margin-right: 0;
    margin-left: 15px;
    padding-left: 12px;
    padding-right: 0; }
  .bodyrtl .header-one .header-mini .pro-header-options .dropdown {
    margin-left: 0;
    padding-left: 12px; }
  .bodyrtl .dropdown-toggle::after {
    right: auto;
    left: -14px; }
  .bodyrtl .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap {
    right: 480px !important; }
    .bodyrtl .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap .tp-caption {
      text-align: right !important; }
  .bodyrtl .rev_slider_wrapper .rev_slider li[data-index="rs-3043"] .tp-parallax-wrap:last-child {
    right: 50% !important;
    margin-right: -75px; }
  .bodyrtl .rev_slider_wrapper .rev_slider li[data-index="rs-3044"] .tp-parallax-wrap {
    right: 15px !important; }
    .bodyrtl .rev_slider_wrapper .rev_slider li[data-index="rs-3044"] .tp-parallax-wrap .tp-caption {
      text-align: right !important; }
  .bodyrtl .carousel-content-home-page-2 .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap {
    left: 610px !important; }
    .bodyrtl .carousel-content-home-page-2 .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap .tp-caption {
      text-align: right !important; }
  .bodyrtl .carousel-content-home-page-3 .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap {
    left: 650px !important; }
    .bodyrtl .carousel-content-home-page-3 .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap .tp-caption {
      text-align: right !important; }
  .bodyrtl .pro-options .color-selection h4 b, .bodyrtl .pro-options .size-selection h4 b {
    float: right; }
  .bodyrtl .dropdown-submenu .dropdown-menu {
    right: 100% !important;
    left: auto !important; }
  .bodyrtl .product-content .product-detail-info .pro-infos .pro-single-info b {
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .product-content .product-detail-info .pro-rating .btn-link {
    margin-right: 10px;
    margin-left: 0; }
  .bodyrtl .product-content .product-detail-info .pro-infos .pro-single-info ul {
    padding-right: 0; }
  .bodyrtl .pro-options .color-selection ul li, .bodyrtl .pro-options .size-selection ul li {
    margin-right: 4px;
    margin-left: 10px; }
  .bodyrtl .pro-counter .input-group {
    margin-right: 0;
    margin-left: 15px; }
  .bodyrtl .product-content .product-detail-info .pro-sub-buttons .btn {
    margin-left: 15px;
    margin-right: 0; }
  .bodyrtl .product-content .product-detail-info .pro-sub-buttons .btn .fas {
    margin-right: 0;
    margin-left: 5px; }
  .bodyrtl .slick-slider {
    direction: ltr; }
    .bodyrtl .slick-slider .slick-slide {
      direction: rtl; }
  .bodyrtl .breadcrumb .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
    padding-right: 0.5rem; }
  .bodyrtl .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    padding-left: 0.5rem;
    padding-right: 0; }
  .bodyrtl .header-area .header-mini .pro-header-options li {
    margin-left: 0;
    margin-right: 15px; }
  .bodyrtl .header-area .header-mini .pro-header-options .pro-header-avatar {
    margin-right: 0;
    margin-left: 7px; }
  .bodyrtl .header-area .header-maxi .search-field-module .search-field-select {
    float: right; }
    .bodyrtl .header-area .header-maxi .search-field-module .search-field-select::before {
      left: -10px;
      right: auto; }
    .bodyrtl .header-area .header-maxi .search-field-module .search-field-select .bootstrap-select .btn.dropdown-toggle .filter-option-inner-inner {
      text-align: right; }
    .bodyrtl .header-area .header-maxi .search-field-module .search-field-select .bootstrap-select .btn.dropdown-toggle::after {
      left: 5px;
      right: auto; }
  .bodyrtl .header-area .header-maxi .search-field-module .search-field-wrap input {
    padding: 0 30px 0 55px; }
  .bodyrtl .header-area .header-maxi .search-field-module .search-field-wrap .btn-secondary {
    left: 0;
    right: auto; }
  .bodyrtl .header-area .header-maxi .pro-header-right-options li {
    margin-left: 0;
    margin-right: 35px; }
  .bodyrtl .header-area .header-maxi .pro-header-right-options .dropdown .dropdown-menu {
    transform: translate3d(0, 24px, 0) !important;
    left: 0  !important;
    right: auto !important;
    margin-top: 31px; }
  .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav {
    padding-right: 0; }
    .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav li {
      text-align: right;
      padding-left: 15px;
      padding-right: 0;
      margin-right: 45px; }
      .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav li .first {
        padding-left: 0;
        padding-right: 15px; }
      .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav li .badge-primary {
        right: 0;
        left: auto; }
        .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav li .badge-primary:after {
          left: 5px;
          transform: rotate(20deg); }
    .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav .nav-item > .dropdown-menu {
      right: 0;
      left: auto;
      margin-left: 0;
      margin-right: -28px; }
    .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav .mega-dropdown-menu {
      margin-right: 0 !important; }
      .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav .mega-dropdown-menu ul {
        padding-right: 0; }
        .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav .mega-dropdown-menu ul li a {
          padding-right: 0 !important; }
    .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
      right: 100%;
      left: auto; }
    .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav .dropdown-submenu .fas {
      float: left;
      transform: rotate(180deg); }
    .bodyrtl .header-area .header-navbar .navbar-collapse .navbar-nav li a {
      text-align: right; }
  .bodyrtl .header-area .pro-title {
    text-align: right; }
  .bodyrtl .header-one .header-navbar nav .logo {
    margin-left: 30px; }
  .bodyrtl .header-area .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li {
    text-align: right;
    margin-right: 0; }
    .bodyrtl .header-area .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-thumb {
      right: 0;
      left: auto; }
    .bodyrtl .header-area .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail {
      padding-left: 0;
      padding-right: 64px; }
      .bodyrtl .header-area .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail h2 {
        text-align: right; }
      .bodyrtl .header-area .pro-header-right-options .dropdown .dropdown-menu .shopping-cart-items li .item-detail .item-s .fas {
        margin-right: 8px; }
  .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul .nav-item.mega-dropdown .mega-dropdown-menu {
    margin-left: 0;
    margin-right: -26%; }
    .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul .nav-item.mega-dropdown .mega-dropdown-menu ul {
      padding-right: 0; }
      .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul .nav-item.mega-dropdown .mega-dropdown-menu ul li a {
        padding-right: 0; }
  .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul {
    padding-right: 0; }
    .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul li {
      margin-right: 0;
      margin-left: 45px; }
    .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul .nav-item > .dropdown-menu {
      text-align: right;
      right: 0;
      left: auto;
      margin-right: -28px;
      margin-left: 0; }
      .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul .nav-item > .dropdown-menu .dropdown-item {
        text-align: right; }
        .bodyrtl .header-two .header-maxi .nav-start nav .navbar-collapse ul .nav-item > .dropdown-menu .dropdown-item .fas {
          float: left;
          transform: rotate(-180deg); }
  .bodyrtl .header-three .header-maxi .navbar-lang .dropdown {
    margin-right: 0;
    margin-left: 15px;
    padding-right: 0;
    padding-left: 12px; }
  .bodyrtl .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar .close {
    float: left; }
  .bodyrtl .header-three .header-maxi .pro-header-right-options li .dropdown-searchbar .pro-col .pro-search-wrap .pro-btn-search {
    float: left;
    left: 0;
    right: auto; }
  .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul {
    padding-right: 0; }
    .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .nav-item {
      margin-right: 0;
      margin-left: 45px; }
    .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .nav-item > .dropdown-menu {
      text-align: right;
      right: 0;
      left: auto;
      margin-right: -28px;
      margin-left: 0; }
      .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .nav-item > .dropdown-menu .dropdown-item {
        text-align: right; }
        .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .nav-item > .dropdown-menu .dropdown-item .fas {
          float: left;
          transform: rotate(-180deg); }
    .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu {
      margin-right: 0; }
  .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu .pro-title {
    text-align: right; }
  .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul {
    padding-right: 0; }
  .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li {
    text-align: right; }
    .bodyrtl .sticky-header .header-maxi nav .navbar-collapse ul .mega-dropdown .mega-dropdown-menu ul li a {
      padding-right: 0; }
  .bodyrtl .blog-content .pro-blog .pro-thumb .blog-date {
    left: auto;
    right: 5px; }
  .bodyrtl .blog-menu .block .media img, .bodyrtl .blog-menu .block .media .fas {
    margin-right: 0;
    margin-left: 15px; }
  .bodyrtl .blog-menu .block .media .media-body span .far {
    margin-left: 5px;
    margin-right: 0; }
  .bodyrtl .product article .pro-thumb .pro-tag {
    left: auto;
    right: 5px; }
  .bodyrtl .popular-product article .pro-thumb .pro-tag {
    left: auto;
    right: 5px; }
  .bodyrtl .pro-socials ul {
    padding-right: 0; }
    .bodyrtl .pro-socials ul li {
      margin-left: 15px;
      margin-right: 0 !important; }
  .bodyrtl .popular-product article .pro-description .pro-price del {
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .product-content .product-detail-info .pro-price del {
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .slider-for-vertical {
    float: left;
    margin-left: 0;
    margin-right: 15px; }
  .bodyrtl .slider-nav-vertical {
    float: right;
    width: 20%; }
  .bodyrtl .footer-three .footer-top-content .search input {
    padding: 8px  8px  8px 98px; }
  .bodyrtl .footer-three .footer-top-content .search .btn {
    left: 0;
    right: auto; }
  .bodyrtl .footer-area h5, .bodyrtl .footer-area p {
    text-align: right; }
  .bodyrtl .footer-area hr:before {
    right: 15px; }
  .bodyrtl .footer-area ul {
    padding-right: 0; }
    .bodyrtl .footer-area ul li {
      float: right;
      text-align: right; }
  .bodyrtl .footer-area .links-list li {
    width: 100%; }
    .bodyrtl .footer-area .links-list li .fa {
      padding-left: 5px;
      padding-right: 0;
      transform: rotate(180deg);
      margin-right: -5px;
      margin-left: 5px; }
  .bodyrtl .footer-area .social-content .newsletter {
    text-align: right; }
  .bodyrtl .footer-area .newsletter .block .form-inline .search input {
    padding: 8px 8px 8px 98px; }
  .bodyrtl .footer-area .newsletter .block .form-inline .search .btn-secondary {
    left: 1px;
    right: auto; }
  .bodyrtl .categories-content .pro-Categories .pro-Categories-detail h2 {
    padding-left: 0;
    padding-right: 15px; }
  .bodyrtl .categories-content .pro-Categories .pro-Categories-detail .pro-Categories-meta {
    padding-right: 15px;
    padding-left: 0; }
  .bodyrtl .blog-content .pro-blog .pro-detail li {
    padding-left: 10px;
    margin-left: 10px;
    padding-right: 0;
    margin-right: 0; }
    .bodyrtl .blog-content .pro-blog .pro-detail li::after {
      right: 12px;
      left: auto; }
  .bodyrtl .blog-menu .category-div ul li {
    padding-left: 15px;
    padding-right: 0; }
  .bodyrtl .blog-menu .category-div .main-manu span {
    float: left; }
  .bodyrtl .blog-menu .category-div .main-manu img {
    margin-left: 7px;
    margin-right: 0 !important; }
  .bodyrtl .blog-menu .category-div .sub-manu .list-item {
    padding-left: 0;
    padding-right: 0; }
    .bodyrtl .blog-menu .category-div .sub-manu .list-item a {
      padding-right: 38px; }
  .bodyrtl .blog-menu .category-div a.btn {
    text-align: right !important; }
    .bodyrtl .blog-menu .category-div a.btn .fas {
      transform: rotate(-180deg);
      margin-left: 7px;
      margin-right: 0 !important; }
  .bodyrtl .blog-menu .category-div .media img {
    margin-left: 15px;
    margin-right: 0; }
  .bodyrtl .blog-menu .category-div .media .fas {
    margin-left: 15px;
    margin-right: 0; }
  .bodyrtl .blog-menu .category-div .media .media-body {
    text-align: right; }
    .bodyrtl .blog-menu .category-div .media .media-body span {
      margin-right: 0;
      margin-left: 10px; }
  .bodyrtl .shop-bar-categories .card .card-body .brands li a .fas {
    margin-right: 0;
    margin-left: 7px;
    transform: rotate(-180deg); }
  .bodyrtl .shop-bar-categories .card .card-body .extra-controls.form-inline .form-group .numaric2 {
    margin: 0 8px 5px 0; }
  .bodyrtl .shop-bar-categories .card .card-body .color-range {
    padding-right: 0; }
  .bodyrtl .shop-bar-categories .card .card-body .size-range li a {
    margin: 0  0 0 8px; }
  .bodyrtl .categories-panel ul {
    padding-right: 0; }
    .bodyrtl .categories-panel ul .nav-item a {
      text-align: right; }
      .bodyrtl .categories-panel ul .nav-item a img {
        margin-left: 7px;
        margin-right: 0; }
      .bodyrtl .categories-panel ul .nav-item a .fas {
        float: left;
        transform: rotate(-180deg); }
    .bodyrtl .categories-panel ul .dropright .dropdown-menu {
      right: 100%;
      left: auto; }
  .bodyrtl .product-2x .featured-tag {
    left: auto;
    right: 34px; }
  .bodyrtl .product-2x .icon-liked {
    left: 26px;
    right: auto; }
  .bodyrtl .product-2x article .thumb {
    right: auto;
    left: 15px; }
  .bodyrtl .product-2x article .module {
    text-align: right; }
    .bodyrtl .product-2x article .module .list li {
      padding-left: 64px;
      padding-right: 0; }
  .bodyrtl .product article .discount-tag {
    left: 0;
    right: auto; }
  .bodyrtl .product article .pro-price del {
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .pro-sp-content .pro-description .pro-price ins {
    margin-right: 15px;
    margin-left: 0; }
  .bodyrtl .pro-mini-content .pro-banner-text .pro-image-text {
    padding-left: 0;
    margin-right: 15px; }
  .bodyrtl .pro-sp-content .pro-description .pro-timer span {
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .pro-category-content .pro-category-items .pro-single-item .pro-description {
    padding-right: 10px;
    padding-left: 0; }
  .bodyrtl .pro-category-content .pro-category-items .pro-single-item .pro-description .pro-price del {
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .listing .product article {
    text-align: right; }
    .bodyrtl .listing .product article .thumb {
      float: right;
      margin-left: 15px; }
    .bodyrtl .listing .product article .discount-tag {
      right: 0;
      left: auto; }
    .bodyrtl .listing .product article .product-hover {
      right: 0;
      left: auto; }
    .bodyrtl .listing .product article .listview-btn {
      margin-right: 15px;
      margin-left: 0; }
  .bodyrtl .blog-content .blog h2 {
    padding-left: 100px;
    padding-right: 15px;
    text-align: right; }
    .bodyrtl .blog-content .blog h2 .featured-tag {
      left: 0;
      right: auto; }
  .bodyrtl .blog-content .blog .blog-date {
    margin-left: 0;
    margin-right: 15px; }
  .bodyrtl .info-boxes-content .info-box-full .info-box {
    padding-left: 15px;
    padding-right: 15px;
    border-right-width: 0; }
    .bodyrtl .info-boxes-content .info-box-full .info-box .panel .fas {
      margin-left: 15px;
      margin-right: 0; }
    .bodyrtl .info-boxes-content .info-box-full .info-box .panel .block {
      text-align: right; }
  .bodyrtl #back-to-top {
    left: 15px;
    right: auto; }
  .bodyrtl .pro-mini-content .pro-banner-text .pro-banner-thumb img {
    margin-left: 0;
    margin-right: -38%; }
  .bodyrtl .aboutus-content .media-body h4 small {
    margin-left: 10px; }
  .bodyrtl .aboutus-content .rounded-circle {
    margin-left: 15px; }
  .bodyrtl .aboutus-content .card .card-header h5 {
    text-align: right; }
    .bodyrtl .aboutus-content .card .card-header h5 .fas {
      float: left; }
  .bodyrtl .aboutus-content .card .card-body {
    text-align: right; }
  .bodyrtl .blog-content .blog-area .blog {
    text-align: right; }
    .bodyrtl .blog-content .blog-area .blog .blog-thumbnail .badge {
      right: auto;
      left: 5px; }
      .bodyrtl .blog-content .blog-area .blog .blog-thumbnail .badge span {
        float: right; }
      .bodyrtl .blog-content .blog-area .blog .blog-thumbnail .badge .fas {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1); }
  .bodyrtl .wishlist-content .media .media-body .pro-price del {
    margin-right: 0;
    margin-left: 10px; }
  .bodyrtl .order-detail-content h4 {
    padding-left: 0;
    padding-right: 12px; }
  .bodyrtl .wishlist-content .media .media-body .buttons .input-group {
    margin-right: 0; }
  .bodyrtl .compare-content h1, .bodyrtl .compare-content table tbody td {
    text-align: right; }
  .bodyrtl .compare-content .detail-buttons .share .fas {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }
  .bodyrtl .contact-content p {
    text-align: right; }
  .bodyrtl .contact-content .contact-info {
    padding-right: 0; }
    .bodyrtl .contact-content .contact-info li .fas {
      text-align: right; }
  .bodyrtl .contact-content .socials .list {
    text-align: right;
    padding-right: 0; }
  .bodyrtl .contact-content .form-start {
    text-align: right; }
    .bodyrtl .contact-content .form-start .input-group .input-group-prepend .input-group-text {
      border-left: 0;
      border-right: 1px solid #dee2e6; }
    .bodyrtl .contact-content .form-start .input-group input {
      border-left: 1px solid #dee2e6; }
  .bodyrtl .contact-content .contact-main {
    text-align: right; }
    .bodyrtl .contact-content .contact-main .contact-logo {
      padding-right: 0;
      border-right: none;
      border-left: 1px solid #dee2e6; }
  .bodyrtl .page-area {
    text-align: right; }
    .bodyrtl .page-area .nav {
      padding-right: 0; }
  .bodyrtl .registration-process .input-group .input-group-prepend .input-group-text {
    border-left: 0;
    border-right: 1px solid #dee2e6; }
  .bodyrtl .registration-socials .right {
    text-align: left; }
  .bodyrtl .order-one-content .list-group {
    padding-right: 0; }
    .bodyrtl .order-one-content .list-group .nav-link {
      text-align: right; }
      .bodyrtl .order-one-content .list-group .nav-link .fas {
        margin-left: 7px;
        margin-right: 0; }
  .bodyrtl .order-two-content .list-group {
    padding-right: 0; }
    .bodyrtl .order-two-content .list-group .nav-link {
      text-align: right; }
      .bodyrtl .order-two-content .list-group .nav-link .fas {
        margin-left: 7px;
        margin-right: 0; }
  .bodyrtl .order-two-content .table td {
    text-align: right; }
  .bodyrtl .order-two-content .form-group {
    text-align: right; }
  .bodyrtl .wishlist-content .list-group {
    padding-right: 0; }
    .bodyrtl .wishlist-content .list-group .nav-link {
      text-align: right; }
      .bodyrtl .wishlist-content .list-group .nav-link .fas {
        margin-left: 7px;
        margin-right: 0; }
  .bodyrtl .wishlist-content .media img {
    margin-left: 1rem;
    margin-right: 0; }
  .bodyrtl .wishlist-content .media .input-group {
    margin-left: 10px; }
  .bodyrtl .wishlist-content .media .share .fas {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }
  .bodyrtl .shipping-content .list-group {
    padding-right: 0; }
    .bodyrtl .shipping-content .list-group .nav-link {
      text-align: right; }
      .bodyrtl .shipping-content .list-group .nav-link .fas {
        margin-left: 7px;
        margin-right: 0; }
  .bodyrtl .shipping-content .shipping-table .form-check {
    padding-left: 0;
    padding-right: 45px; }
    .bodyrtl .shipping-content .shipping-table .form-check .form-check-input {
      right: 18px;
      left: auto; }
  .bodyrtl .shipping-content .shipping-table .edit-tag ul {
    padding-right: 0; }
  .bodyrtl .shipping-content .main-form {
    text-align: right; }
    .bodyrtl .shipping-content .main-form .select-control::before {
      right: auto;
      left: 15px; }
  .bodyrtl .profile-content .list-group {
    padding-right: 0; }
    .bodyrtl .profile-content .list-group .nav-link {
      text-align: right; }
      .bodyrtl .profile-content .list-group .nav-link .fas {
        margin-left: 7px;
        margin-right: 0; }
  .bodyrtl .profile-content .media img {
    margin-left: 15px; }
  .bodyrtl .profile-content .media .media-body h4 {
    text-align: right; }
  .bodyrtl .profile-content .media .media-body .detail p {
    text-align: left; }
  .bodyrtl .profile-content form label {
    text-align: right; }
  .bodyrtl .profile-content form .select-control::before {
    right: auto;
    left: 15px; }
  .bodyrtl .profile-content form .input-group.date .input-group-prepend .input-group-text {
    border-left-width: 1px;
    border-right-width: 0; }
  .bodyrtl .profile-content form .btn {
    float: left; }
  .bodyrtl .shop-content .breadcum-area {
    text-align: right; }
  .bodyrtl .shop-content .list-group .list-group-item {
    float: right;
    margin-right: 0;
    margin-left: 10px; }
  .bodyrtl .shop-content .list-group .list-group-item:before {
    padding-right: 0;
    padding-left: 0.5rem; }
  .bodyrtl .right-menu {
    text-align: right; }
    .bodyrtl .right-menu .right-menu-categories .main-manu span {
      float: left; }
    .bodyrtl .right-menu .right-menu-categories .main-manu img {
      margin-left: 7px;
      margin-right: 0 !important; }
    .bodyrtl .right-menu .right-menu-categories .sub-manu .list-item {
      padding-right: 0; }
      .bodyrtl .right-menu .right-menu-categories .sub-manu .list-item .fas {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        margin-left: 7px;
        margin-right: 0 !important; }
      .bodyrtl .right-menu .right-menu-categories .sub-manu .list-item a {
        padding-right: 38px; }
    .bodyrtl .right-menu .range-slider-main .extra-controls font {
      margin-left: 10px;
      margin-right: 10px; }
    .bodyrtl .right-menu .range-slider-main .extra-controls span font {
      margin-left: 10px;
      margin-right: 0; }
    .bodyrtl .right-menu .color-range-main .color-range ul {
      padding-right: 0; }
    .bodyrtl .right-menu .brands-main .brands ul li a .fas {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      margin-left: 7px;
      margin-right: 0; }
  .bodyrtl .product-page h1 {
    text-align: right; }
  .bodyrtl .product-page .list {
    text-align: right; }
  .bodyrtl .product-page .nav .nav-link {
    text-align: right;
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .product-page .tab-content {
    text-align: right; }
  .bodyrtl .product-page .list-main .icon-liked {
    float: left; }
    .bodyrtl .product-page .list-main .icon-liked .badge {
      right: auto;
      left: -8px; }
  .bodyrtl .product-page .list-main .list li {
    padding-left: 6px;
    padding-right: 0px;
    border-left: 1px solid #e1e1e1;
    border-right: none;
    margin-right: 0;
    margin-left: 6px; }
  .bodyrtl .product-page .list-main .list .instock .fas {
    margin-left: 5px;
    margin-right: 0;
    float: left; }
  .bodyrtl .product-page .controls .select-control::before {
    left: 30px;
    right: auto; }
  .bodyrtl .product-page .product-buttons h2 span {
    margin-right: 15px;
    margin-left: 0;
    float: left; }
  .bodyrtl .product-page .xzoom-thumbs a:last-child img {
    margin: 0; }
  .bodyrtl .product-page-two .list-main .list .instock .fas {
    float: right; }
  .bodyrtl .product-page-three .list-main .list .instock .fas {
    float: right; }
  .bodyrtl .product-page-three .xzoom-3 .xzoom2, .bodyrtl .product-page-three .xzoom-3 .xzoom3, .bodyrtl .product-page-three .xzoom-3 .xzoom4, .bodyrtl .product-page-three .xzoom-3 .xzoom5 {
    float: left; }
  .bodyrtl .product-page-three .xzoom-3 .xzoom-thumbs {
    float: right;
    text-align: right; }
  .bodyrtl .product-page-three .add-favrt li {
    margin-left: 15px;
    margin-right: 0; }
    .bodyrtl .product-page-three .add-favrt li .fas {
      margin-left: 5px;
      margin-right: 0; }
  .bodyrtl .product-page-four .list-main .list .instock .fas {
    float: right; }
  .bodyrtl .product-page-four .add-favrt li {
    margin-left: 15px;
    margin-right: 0; }
    .bodyrtl .product-page-four .add-favrt li .fas {
      margin-left: 5px;
      margin-right: 0; }
  .bodyrtl .product-page-five .product-buttons .icon-liked {
    margin-right: 15px; }
  .bodyrtl .product-page-five .list-main .list .instock .fas {
    float: right; }
  .bodyrtl .product-page-five .add-favrt li {
    margin-left: 15px;
    margin-right: 0; }
    .bodyrtl .product-page-five .add-favrt li .fas {
      margin-left: 5px;
      margin-right: 0; }
  .bodyrtl .product-page-six .product-buttons .icon-liked {
    margin-right: 15px; }
  .bodyrtl .product-page-six .list-main .list .instock .fas {
    float: right; }
  .bodyrtl .product-page-six .add-favrt li {
    margin-left: 15px;
    margin-right: 0; }
    .bodyrtl .product-page-six .add-favrt li .fas {
      margin-left: 5px;
      margin-right: 0; }
  .bodyrtl .modal .modal-body {
    text-align: right; }
    .bodyrtl .modal .modal-body .list li {
      padding-left: 10px;
      padding-right: 0;
      margin-left: 10px;
      margin-right: 0;
      border-right: none;
      border-left: 1px solid #e1e1e1; }
      .bodyrtl .modal .modal-body .list li.instock .fas {
        margin-left: 5px;
        margin-right: 0; }
  .bodyrtl .pagination div {
    text-align: left; }
    .bodyrtl .pagination div .fa-angle-double-left {
      transform: rotate(-180deg); }
    .bodyrtl .pagination div .fa-angle-double-right {
      transform: rotate(-180deg); }
  .bodyrtl .modal .modal-dialog .modal-body .pro-description .pro-infos .pro-single-info ul {
    padding-right: 0; }
  .bodyrtl #registerTab {
    padding-right: 0; }
  .bodyrtl .item-quantity input {
    margin-left: -1px; }
  .bodyrtl .select-control::before {
    left: 0;
    right: auto; }
  .bodyrtl .checkout-content .checkout-module a.btn {
    margin-right: 0;
    margin-left: 15px; }
  .bodyrtl .checkout-content .checkout-module .form-group .form-check-inline {
    float: right;
    padding-right: 15px;
    margin-left: 15px; }
    .bodyrtl .checkout-content .checkout-module .form-group .form-check-inline .form-check-input {
      margin-right: -1.25rem;
      margin-left: 0; }
    .bodyrtl .checkout-content .checkout-module .form-group .form-check-inline .form-check-label {
      margin-right: 10px; }
  .bodyrtl .form-check {
    padding-right: 1.25rem;
    padding-left: 0; }
    .bodyrtl .form-check .form-check-input {
      margin-right: -1.25rem;
      margin-left: 0; }
  .bodyrtl .list-group {
    padding-right: 0; }
  .bodyrtl .shipping-content .table tr td .form-check {
    padding-right: 35px; }
  .bodyrtl .shipping-content .table tr td .form-check .form-check-input {
    right: 25px;
    left: auto; }
  .bodyrtl .pro-content .pro-heading-title {
    padding-right: 15px; }
  .bodyrtl .pro-tab-content .pro-heading-title {
    padding-left: 0;
    padding-right: 0; }
  .bodyrtl .checkout-module .checkoutd-nav .nav-item {
    margin-left: 17px;
    margin-right: 0; }
  .bodyrtl .checkout-module .checkoutd-nav .nav-item a {
    padding-right: 30px;
    padding-left: 0; }
  .bodyrtl .checkout-module .checkoutd-nav .nav-item a:after {
    border-left: none;
    border-right: 18.5px solid #e1e1e1;
    left: -18px;
    right: auto !important; }
  .bodyrtl .checkout-module .checkoutd-nav .nav-item a:before {
    border-left: none;
    border-right: 18.5px solid #fff;
    right: 0;
    left: auto; }
  .bodyrtl .checkout-module .checkoutd-nav .nav-item a.active:after {
    border-left: none;
    border-right: 18.5px solid #b38741; }
  .bodyrtl .checkout-module .checkoutd-nav .nav-item a.active:before {
    border-left: none;
    border-right: 18.5px solid #fff; }
  .bodyrtl .aboutus-content .accordions .card-header a:after {
    left: 20px;
    right: auto; }
  .bodyrtl .modal .modal-dialog .modal-body .pro-description .pro-infos .pro-single-info b {
    margin-left: 10px;
    margin-right: 0; }
  .bodyrtl .pro-header-options .dropdown .dropdown-menu {
    left: 0 !important;
    right: auto !important;
    text-align: right;
    margin-right: -28px;
    margin-left: 0; }
  .bodyrtl .listing .product article .pro-description {
    padding-right: 15px; }
  .bodyrtl .cart-content .table .item-detail {
    float: right; }
  .bodyrtl .shipping-content .table tr .controls, .bodyrtl .shipping-content .table tr .controls ul {
    padding-right: 0; }
  .bodyrtl .top-bar label {
    margin: 0px 15px 0px 5px; }
  .bodyrtl .top-bar .form-inline .form-group {
    margin-left: 15px;
    margin-right: 0; }
  .bodyrtl .login-content .registration-process .btn-link {
    margin-right: 15px;
    margin-left: 0; }

@media only screen and (max-width: 991px) {
  .bodyrtl .header-area .alert .pro-description .close {
    left: -40px;
    right: auto; }
  .bodyrtl .aboutus-content .pro-heading-title, .bodyrtl .pro-full-screen-banner .pro-full-screen-overlay .pro-heading-title, .bodyrtl .pro-pl-content .pro-heading-title, .bodyrtl .testimonails-content .pro-heading-title, .bodyrtl .pro-bc-content .pro-heading-title, .bodyrtl .categories-content .pro-heading-title, .bodyrtl .pro-tr-content .pro-heading-title, .bodyrtl .pro-plr-content .pro-heading-title {
    padding-left: 0;
    padding-right: 0; }
  .bodyrtl .mobile-pro-icons {
    left: 5px;
    right: auto; } }

@media only screen and (max-width: 767px) {
  .bodyrtl .order-detail-content .order-id tbody tr td {
    padding-right: 15px;
    padding-left: 0; }
  .bodyrtl .top-bar .form-inline .form-group .select-control::before {
    right: auto;
    left: 0; }
  .bodyrtl .mobile-overlay {
    right: 0; }
  .bodyrtl .mobile-pro-icons {
    left: 5px;
    right: auto; }
  .bodyrtl .product article .pro-thumb .pro-tag {
    left: auto;
    right: 5px; }
  .bodyrtl .popular-product article .pro-thumb .pro-tag {
    left: auto;
    right: 5px; }
  .bodyrtl .blog-content .pro-blog .pro-thumb .blog-date {
    left: 15px;
    right: auto; }
  .bodyrtl .bodyrtl .dropdown .dropdown-menu {
    left: 0;
    right: auto !important;
    text-align: right !important; }
  .bodyrtl .footer-mobile .single-footer {
    float: right; }
  .bodyrtl .top-bar .form-inline .form-group .form-control {
    padding-left: 25px;
    padding-right: 15px; }
  .bodyrtl .top-bar .form-inline .form-group::before {
    left: 10px;
    right: auto; }
  .bodyrtl .pagination div {
    text-align: center; }
  .bodyrtl .products-area .nav-pills .active:after {
    left: 14px;
    right: 39%; }
  .bodyrtl .product article .mobile-icons {
    right: 7px;
    left: auto; }
    .bodyrtl .product article .mobile-icons .badge {
      left: -10px;
      right: auto; }
  .bodyrtl .info-boxes-content .info-box-full .info-box {
    padding-left: 15px;
    padding-right: 15px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #ced4da; }
  .bodyrtl .footer-area .copyright-content .footer-info {
    text-align: center; }
  .bodyrtl .footer-area .copyright-content .footer-image {
    text-align: center; }
  .bodyrtl .aboutus-content .rounded-circle {
    margin-left: auto;
    margin-right: auto !important; }
  .bodyrtl .profile-content .media .media-body .detail p {
    text-align: right; }
  .bodyrtl .profile-content .select-control::before {
    left: 15px;
    right: auto; }
  .bodyrtl .wishlist-content .media img {
    margin-left: auto;
    margin-right: auto; }
  .bodyrtl .shipping-content .h5-heading {
    text-align: right; }
  .bodyrtl .product-page h2 {
    text-align: right; }
    .bodyrtl .product-page h2 span {
      float: none !important; }
  .bodyrtl .product-page .product-buttons {
    text-align: right; }
    .bodyrtl .product-page .product-buttons .icon-liked {
      left: 14px;
      right: auto; }
    .bodyrtl .product-page .product-buttons .buttons {
      margin-left: 0;
      margin-right: 0; }
  .bodyrtl .product-page .input-group-btn .btn {
    float: left;
    width: 100%;
    border-left: none;
    border-right: none; }
  .bodyrtl .product-page .nav .nav-link {
    width: 32.18%;
    margin-left: 5px; }
    .bodyrtl .product-page .nav .nav-link:last-child {
      margin-left: 0; }
  .bodyrtl .product-page .add-favrt li {
    text-align: right; }
  .bodyrtl .product-page-six .product-buttons .icon-liked {
    left: 14px;
    right: auto; }
  .bodyrtl .product-page-six .product-buttons .buttons {
    margin-left: 0;
    margin-right: 0; }
  .bodyrtl #back-to-top {
    bottom: 15px;
    right: 15px; }
  .bodyrtl .checkout-content .checkout-module .checkoutm-nav {
    padding-right: 0; }
  .bodyrtl .checkout-content .checkout-module .btn.swipe-to-top {
    margin-right: 0;
    margin-left: 15px; }
  .bodyrtl .checkout-content .checkout-module .form-group .form-check-inline {
    justify-content: end;
    padding-right: 24px;
    width: 100%; }
    .bodyrtl .checkout-content .checkout-module .form-group .form-check-inline .form-check-label {
      margin-right: 15px; }
  .bodyrtl .cart-content .table .item-detail {
    float: right; }
  .bodyrtl .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap {
    right: 15px !important; }
  .bodyrtl .carousel-content-home-page-2 .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap {
    left: 255px !important; }
  .bodyrtl .carousel-content-home-page-3 .rev_slider_wrapper .rev_slider li[data-index="rs-3042"] .tp-parallax-wrap {
    left: 236px !important; } }
