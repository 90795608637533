// ...........footer.............
$footer-bg: $gray-100;

.footer-three {
  padding-top: 100px;
  margin-top: 100px;
  background-color: $footer-bg;

  .single-footer {
    h5 {
      font-family: $font-family-base-bold;
      font-size: $font-size-lg;
      color: $body-color;
      margin-bottom: 15px;
      margin-top: -4px;
    }

    .pro-about {
      ul {
        li {
          color: $body-color;
          font-size: $font-size-base;

          img {
            width: 120px;
            margin-bottom: 8px;
          }
        }
      }
    }
    ul {
      li {
        display: block;
        width: 100%;
        list-style: none;
        font-size: $font-size-base;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: -4px;
        }

        span {
          display: block;
        }

        a {
          color: $body-color;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          &:hover {
            text-decoration: none;
            color: $secondary;
          }
        }
      }
    }
  }
  .footer-top-content {
    padding-bottom: 100px;
    background-color: $footer-bg;
    border-bottom: 1px solid $border-color;
    margin-bottom: 100px;
    h4 {
      font-family: $font-family-base-bold;
      margin-top: -7px;
    }
    p {
      font-size: $font-size-base;
      margin-bottom: -4px;
    }
    .search {
      position: relative;
      //margin-top: 15px;
      width: 100%;

      input {
        float: right;
        height: 40px;
        padding: 8px 98px 8px 8px;
        width: 100%;
        border-radius: 0;
        outline: none !important;
        border: $border-width solid $border-color;
      }

      .btn {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  .copyright-content {
    background-color: $footer-bg;
    color: color-yiq($footer-bg);
    padding-top: 50px;
    padding-bottom: 50px;

    .footer-info {
      text-align: center;
      font-size: $font-size-sm;
      margin-bottom: -4px;
      margin-top: -4px;
      a {
        color: color-yiq($footer-bg);

        &:hover {
          color: $secondary;
        }
      }
    }
  }
}
