/*-----------
/----- Header Top bar -------- */

$bg-top-bar: $primary;
$bg-header-bar: #fff;
$bg-menu-bar: #fff;
$bg-nav-bar: #fff;

.header-mobile {
  .bg-top-bar {
    background-color: $bg-top-bar;
  }
  .header-mini {
    min-height: 26px;

    .navbar-lang {
      display: flex;
      justify-content: center;
      .dropdown {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 15px;
        padding-right: 12px;

        .dropdown-toggle {
          font-size: $font-size-sm;
          color: color-yiq($bg-top-bar);

          &::after {
            color: color-yiq($bg-top-bar);
          }
        }

        .dropdown-menu {
          animation: animationmenu ease 0.5s;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-fill-mode: forwards;

          background-color: $white;

          font-size: $font-size-sm;

          margin: 0 0 0 -28px;
          padding: 1.2rem 0;
          border-width: 0;

          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);

          .dropdown-item {
            color: color-yiq($white);
            padding: 5px 1.8rem;

            &:hover,
            &.active,
            &:active {
              background-color: transparent;
              color: $secondary;
            }
          }
        }

        &:hover .dropdown-menu {
          display: block;
        }
      }
    }
  }

  /*-----------
    /----- Header bar -------- */

  .bg-header-bar {
    background-color: $bg-header-bar;
  }
  .header-maxi {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;

    .navigation-mobile-container {
      margin-left: -8px;
      .navigation-mobile-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        padding: 0.55rem 0.75rem;
        font-size: 1rem;
        line-height: 1;
        border-radius: $border-radius;

        .fas {
          font-size: 20px;
          color: color-yiq($bg-header-bar);
        }
      }
      #navigation-mobile {
        height: 100%;
        width: 250px;
        position: fixed;
        z-index: 999;
        top: 0;
        left: -250px;
        background-color: darken($bg-header-bar, 2%);
        overflow-x: hidden;
        transition: 0.5s;
        .logout-main {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 26px 15px;
          position: relative;
          background-color: $bg-header-bar;
          width: 250px;
          border-bottom: $border-width solid $border-color;
          .welcome {
            font-family: $font-family-base-bold;
            color: color-yiq($bg-header-bar);
            font-size: 0.824rem;
          }
          .logout {
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: darken($color: $bg-header-bar, $amount: 10%);
              font-size: $font-size-sm;
              color: color-yiq($bg-header-bar);
              width: 70px;
              height: 26px;
              border-radius: 15px;
              // margin-left: 2px
            }
          }
        }

        a[aria-expanded='true']:after {
          content: '\f077';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color: $body-color-secondary;
          font-size: $font-size-sm;
          position: absolute;
          right: 20px;
        }
        a[aria-expanded='false']:after {
          content: '\f078';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color: $body-color-secondary;
          font-size: $font-size-sm;
          position: absolute;
          right: 20px;
        }
        .main-manu {
          font-family: $font-family-base;
          font-size: $font-size-sm;
          width: 250px;
          text-align: left;
          border-bottom: $border-width solid $border-color;
        }
        .sub-manu {
          .unorder-list {
            padding: 0;
            margin-bottom: 0;
            li {
              list-style: none;
              a {
                width: 100%;
                text-align: left;
                padding-left: 45px;
              }
              .sub-manu1 {
                a {
                  padding-left: 60px;
                }
              }
            }
          }
        }
        &.navigation-active {
          left: 0;
        }
      }
    }

    .logo {
      width: 176px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
      padding-top: 4px;
      text-decoration: none;
      margin-left: auto;
      margin-right: auto;
      strong {
        color: $secondary;
      }
    }

    .cart-dropdown {
      float: right;
      position: relative;
      z-index: 9;

      .cart-dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        padding: 0.55rem 0.75rem;
        font-size: 1rem;
        line-height: 1;
        border-radius: $border-radius;

        .fas {
          color: color-yiq($bg-header-bar);
          font-size: 20px;
        }

        .badge {
          position: absolute;
          right: -3px;
          top: -2px;
        }
      }
      .dropdown-menu {
        animation: animationcartmenu ease 0.5s;
        animation-iteration-count: 1;

        animation-fill-mode: forwards;

        -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
        width: 270px;
        border-radius: $border-radius;
        border-color: $white;
        z-index: 99999;
        margin-top: 30px;

        .shopping-cart-items {
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 17px;
          max-height: 350px;
          overflow-y: auto;
          margin-bottom: 0;
          li:last-child {
            border-bottom: none;
          }
          li {
            list-style: none;
            float: left;
            position: relative;
            width: 100%;
            margin-bottom: 10px;
            border-bottom: $border-width solid $border-color;
            padding-bottom: 10px;
            margin-left: 0;

            .item-thumb {
              position: absolute;
              left: 0;
              top: 0;

              .image {
                width: 50px;
                height: 50px;
                border: $border-width solid $border-color;
                border-radius: $border-radius;
                overflow: hidden;
                img {
                  transform: translateY(-10%);
                }
              }
            }
            .item-detail {
              float: left;
              padding-left: 64px;

              h2 {
                display: block;
                font-family: $font-family-base-bold;
                font-size: $font-size-base;
                white-space: normal;
                text-transform: capitalize;
              }

              .item-s {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: $font-size-base;
                color: $body-color;

                .fas {
                  color: $body-color-secondary;
                  float: right;
                  margin-left: 8px;
                  -webkit-transition: 0.4s;
                  transition: 0.4s;

                  &:hover {
                    color: $secondary;
                  }
                }
              }
            }
          }
          .item-summary {
            font-family: $font-family-base-bold;
            font-size: $font-size-lg;
            color: $body-color;
            margin-bottom: 0;
            margin-top: 5px;
            white-space: normal;
          }
          .btn {
            padding: 0.675rem 1rem;
          }
        }
      }
    }
  }

  //----- Menu bar -------- */
  .bg-menu-bar {
    background-color: $bg-menu-bar;
  }
  .bg-nav-bar {
    background-color: $bg-nav-bar;
    // width: 100%;
    // // padding-right: 15px;
    // // padding-left: 15px;
    // margin-right: auto;
    // margin-left: auto;
  }
  .header-navbar {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    .form-inline {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .search-field-module {
        position: relative;
        background-color: $white;
        border: 1px solid $border-color;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        width: 100%;

        &:focus {
          border-color: $secondary;
        }

        .search-field-select {
          float: left;
          position: relative;
          width: 80px !important;
          height: 50px;

          .form-control {
            height: 100%;
            border: none;
            padding: 0 0 0 15px;
            width: 80px;
          }
          &::before {
            content: '';
            width: 1px;
            height: 25px;
            background: $border-color;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: -10px;
            z-index: 1;
          }
        }
        .search-field-wrap {
          float: left;
          height: 50px;
          width: calc(100% - 80px);
          input {
            float: left;
            padding: 0 55px 0 20px;
            height: 50px;
            width: 100%;
            border-radius: 0;
            border: none;
            outline: none !important;
          }
          .btn-secondary {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 52px;
            padding: 0;
          }
        }
      }
    }
  }
}

.mobile-overlay {
  display: none;
  top: 0;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 998;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}
/* display .overlay when it has the .active class */
.mobile-overlay.active {
  display: block;
  opacity: 1;
}

.rtl {
  .header-mobile {
    .header-mini {
      .navbar-lang {
        .select-control.currency {
          border-right: 1px solid $gray-300;
          border-left: none;
        }
        .select-control {
          margin-right: 10px !important;
          margin-left: 0 !important;

          .form-control {
            padding-left: 0 !important;
            padding-right: 0.75rem !important;
          }
        }
      }
    }

    .header-maxi {
      .navigation-mobile-container {
        margin-right: 15px;
        #navigation-mobile {
          right: -250px;
          left: auto;

          .logout-main .logout a {
            left: 0.75rem;
            right: auto;
          }
          .main-manu {
            text-align: right;
            span {
              float: left;
            }
          }
          .sub-manu {
            a {
              text-align: right;
              padding-left: 0.75rem;
              padding-right: 30px;
              span {
                float: left;
              }
            }
          }

          &.navigation-active {
            left: auto;
            right: 0;
          }
        }
      }
      .cart-dropdown {
        margin-left: 15px;
        float: left;
        .cart-dropdown-toggle {
          .badge {
            left: -5px;
            right: auto;
          }
        }
        .shopping-cart-items {
          padding-right: 0;

          .item-thumb {
            right: 0;
            left: auto;

            .icon {
              left: -5px;
              right: auto;
            }
          }
          .item-detail {
            padding-left: 0;
            padding-right: 74px;
            text-align: right;

            .item-quantity {
              float: right;
              margin-right: 0;
              margin-left: 10px;
            }
            .item-price {
              float: left;
              margin-left: 0;
            }
          }
          .tt-summary {
            text-align: right;

            span {
              float: left;
            }
          }
          .buttons {
            .btn:first-child {
              margin-right: 0;
              margin-left: 5px;
            }
            .btn:last-child {
              margin-right: 5px;
              margin-left: 0;
            }
          }
        }
      }
    }
    .header-navbar {
      .form-inline {
        .search {
          .select-control {
            float: right;
            border-right: none;
            border-left: 1px solid $gray-300;
          }
          .select-control::before {
            left: 10px;
            right: auto;
          }
          input {
            font-size: 12px;
            float: right;
            height: 30px;
            padding: 8px 12px 8px 12px;
            width: calc(100% - 98px);
            border-radius: 0;
            border: none;
            outline: none !important;
          }
          .btn-secondary {
            left: 1px;
            right: auto;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-mobile {
    .header-navbar {
      .form-inline {
        .search-field-module {
          .search-field-select {
            width: 150px !important;
            .form-control {
              width: 150px;
            }
          }
          .search-field-wrap {
            width: calc(100% - 150px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .header-mobile
    .header-maxi
    .navigation-mobile-container
    .navigation-mobile-toggler {
    margin-right: 8px;
  }

  .header-mobile .header-maxi .cart-dropdown .cart-dropdown-toggle {
    .badge {
      right: 0;
    }
  }

  .bodyrtl {
    .header-mobile .header-maxi .cart-dropdown {
      float: left;
      margin-left: 15px;

      .cart-dropdown-toggle {
        .badge {
          left: 0;
          right: auto;
        }
      }
    }

    .header-mobile .header-mini .navbar-lang .dropdown {
      margin-left: 15px;
      margin-right: 0;
      padding-right: 0;
      padding-left: 12px;

      .dropdown-menu {
        text-align: right;
        right: -28px;
        left: auto;
      }
    }
  }
}
