/*-----------
/----- Header Top bar -------- */

$bg-top-bar: $primary;
$bg-header-bar: #fff;
$bg-menu-bar: #fff;
$bg-nav-bar: #fff;

.header-area {
  .alert {
    background-color: $secondary;
    position: relative;
    z-index: 8;
    min-height: 50px;
    padding: 13px 35px 12px;
    margin-bottom: 0;
    border: none;

    .pro-description {
      position: relative;

      text-align: center;
      width: 100%;

      .pro-info {
        font-size: 20px;
        color: color-yiq($secondary);
        line-height: 25px;
        strong {
          font-family: $font-family-base-bold;
        }

        .pro-link-dropdown.js-toppanel-link-dropdown {
          display: inline-block;
          z-index: 8;

          .pro-dropdown-toggle {
            display: inline-block;
            position: relative;
            background: 0 0;
            border: none;
            font-family: $font-family-base-bold;
            color: color-yiq($secondary);
            text-decoration: underline;
            padding: 0;
            outline: 0;
            cursor: help;
          }
        }
      }

      .close {
        font-size: 40px;
        color: color-yiq($secondary);
        font-weight: normal;
        line-height: 24px;
        padding: 0;
        right: -5px;
        text-shadow: none;
      }
    }
  }
}
.header-one {
  position: relative;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .bg-top-bar {
    background-color: $bg-top-bar;
  }

  .header-mini {
    .dropdown {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-right: 15px;
      padding-right: 12px;

      .dropdown-toggle {
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: $font-size-sm;
        color: color-yiq($bg-top-bar);

        &::after {
          color: color-yiq($bg-top-bar);
        }
      }

      .dropdown-menu {
        animation: animationmenu ease 0.5s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards;

        background-color: $white;

        font-size: $font-size-sm;

        margin: 0 0 0 -28px;
        padding: 1.2rem 0;
        border-width: 0;

        -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);

        .dropdown-item {
          color: color-yiq($white);
          padding: 5px 1.8rem;

          &:hover,
          &.active,
          &:active {
            background-color: transparent;
            color: $secondary;
          }
        }
      }
      &:hover > .dropdown-menu {
        display: block;
      }
    }

    .navbar-lang,
    .pro-header-options {
      display: flex;
      align-items: center;
    }
    .pro-header-options {
      justify-content: flex-end;

      .pro-avatar {
        font-size: $font-size-sm;
        color: color-yiq($bg-top-bar);
      }

      .dropdown:first-child {
        padding-right: 0;
      }

      .dropdown {
        margin-left: 15px;
        margin-right: 0;

        .dropdown-menu {
          right: 0;
          left: auto;
        }
      }
    }
  }

  /*-----------
    /----- Header bar -------- */

  .bg-header-bar {
    background-color: $bg-header-bar;
    border-bottom: 1px solid $border-color;
  }
  .header-maxi {
    padding: 15px 0;

    .search-field-module {
      position: relative;
      background-color: $white;
      border: 1px solid $border-color;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      width: 80%;
      margin-left: auto;
      margin-right: auto;

      &:focus {
        border-color: $secondary;
      }

      .search-field-select {
        float: left;
        position: relative;
        width: 160px !important;
        height: 50px;

        .dropdown {
          padding-left: 1.4rem;
          margin-right: 15px;

          .dropdown-toggle {
            margin-top: 15px;
            margin-bottom: 15px;
            line-height: 20px;
            font-size: $font-size-sm;
            color: $body-color;

            &::after {
              color: $body-color;
              right: -23px;
            }
          }

          .dropdown-menu {
            animation: animationmenu ease 0.5s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            animation-fill-mode: forwards;

            background-color: $white;

            font-size: $font-size-sm;

            margin: 0 0 0 0;
            padding: 1.2rem 0;

            border-width: 0;

            -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
            box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);

            .dropdown-item {
              color: color-yiq($white);
              padding: 5px 1.4rem;

              &:hover,
              &.active,
              &:active {
                background-color: transparent;
                color: $secondary;
              }
            }
          }
          &:hover > .dropdown-menu {
            display: block;
          }
        }

        .bootstrap-select {
          width: 100% !important;

          .btn.dropdown-toggle {
            background-color: $white;
            padding-left: 1.4rem;
            padding-right: 1.4rem;
            padding-bottom: 0;
            padding-top: 0;
            margin-top: 15px;
            margin-bottom: 15px;
            line-height: 20px;
            font-family: $font-family-base;
            color: $body-color;
            text-transform: none;
            width: 100%;

            .filter-option-inner-inner {
              text-overflow: ellipsis;
            }

            &:focus {
              outline: none !important;
            }

            &::after {
              bottom: 6px;
              right: 11px;
              color: $body-color-secondary;
            }
          }
        }
        &::before {
          content: '';
          width: 1px;
          height: 25px;
          background: $border-color;
          position: absolute;
          top: 50%;
          margin-top: -12px;
          right: -10px;
          z-index: 1;
        }
      }
      .search-field-wrap {
        float: left;
        height: 50px;
        width: calc(100% - 160px);

        input {
          font-size: 15px;
          float: left;
          padding: 0 55px 0 30px;
          height: 50px;
          width: 100%;
          border-radius: 0;
          border: none;
          outline: none !important;
          font-family: $font-family-base;
          font-size: $font-size-sm;
          color: $body-color-secondary;
        }
        .btn-secondary {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 52px;
          padding: 0;

          .fa {
            font-size: 15px;
          }
        }
      }
    }

    .pro-header-right-options {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;

      li {
        position: relative;
        display: inline-block;
        list-style: none;
        margin-left: 35px;

        a.btn,
        button {
          display: flex;
          position: relative;
          padding: 0;
          text-decoration: none;

          .fas,
          .far {
            font-size: 22px;
            color: color-yiq($bg-header-bar);

            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:hover {
              color: $secondary;
            }
          }

          .badge {
            // padding: 3px 2px 2px 4px;
            // position: absolute;
            // right:-8px;
            // //right: auto;
            // top: -8px;
            // height: 16px;
            // min-width: 16px;
            // color: color-yiq($secondary);
            // border-radius: $border-radius;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // z-index: 99;
            padding: 3px 2px 2px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
            height: 16px;
            min-width: 16px;
            color: color-yiq($secondary);
            border-radius: $border-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99;
          }
        }
      }
      .dropdown {
        float: right;

        .dropdown-toggle::after {
          content: none;
        }

        .dropdown-menu {
          animation: animationcartmenu ease 0.5s;
          animation-iteration-count: 1;

          animation-fill-mode: forwards;

          -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
          width: 270px;
          border-radius: $border-radius;
          border-color: $white;
          z-index: 99999;
          margin-top: 30px;

          .shopping-cart-items {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 17px;
            max-height: 360px;
            overflow-y: auto;
            li:last-child {
              border-bottom: none;
            }
            li {
              float: left;
              position: relative;
              width: 100%;
              margin-bottom: 10px;
              border-bottom: $border-width solid $border-color;
              padding-bottom: 10px;
              margin-left: 0;

              .item-thumb {
                position: absolute;
                left: 0;
                top: 0;

                .image {
                  width: 50px;
                  height: 50px;
                  border: $border-width solid $border-color;
                  border-radius: $border-radius;
                  overflow: hidden;

                  img {
                    transform: translateY(-10%);
                  }
                }
              }
              .item-detail {
                float: left;
                padding-left: 64px;

                h2 {
                  display: block;
                  font-family: $font-family-base-bold;
                  font-size: $font-size-base;
                  white-space: normal;
                  text-transform: capitalize;
                }

                .item-s {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: $font-size-base;
                  color: $body-color;

                  .fas {
                    color: $body-color-secondary;
                    float: right;
                    margin-left: 8px;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    cursor: pointer;

                    &:hover {
                      color: $secondary;
                    }
                  }
                }
              }
            }
            .item-summary {
              font-family: $font-family-base-bold;
              font-size: $font-size-lg;
              color: $body-color;
              margin-bottom: 0;
              margin-top: 5px;
              white-space: normal;
            }
            .btn {
              display: block;
              padding: 0.675rem 1rem;
            }
          }
        }
      }
    }
  }

  //----- Menu bar -------- */
  .bg-menu-bar {
    background-color: $bg-menu-bar;
  }
  .bg-nav-bar {
    background-color: $bg-nav-bar;
  }
  .header-navbar {
    nav {
      width: 100%;
      padding: 0;
      .navbar-collapse {
        justify-content: center;
        ul {
          li {
            padding: 15px 14px 15px 0;
            margin-right: 45px;

            .nav-link {
              position: relative;
              font-family: $font-family-base-bold;
              font-size: $font-size-sm;
              color: color-yiq($bg-menu-bar);
              text-transform: uppercase;
              padding: 0;

              .badge-secondary {
                position: absolute;
                top: -13px;
                left: -12px;
                border-radius: 0;
                padding: 3px 3px 2px 3px;
                text-align: center;

                &:after {
                  content: '';
                  position: absolute;
                  left: 14px;
                  top: 12px;
                  width: 0;
                  height: 0;
                  border: 3px solid transparent;
                  border-top: 10px solid $secondary;
                  transform: rotate(-28deg);
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }

            .dropdown-menu {
              animation: animationmenu ease 0.5s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
              background-color: $white;

              font-size: $font-size-base;

              margin: 0 0 0 -28px;
              padding: 1.2rem 0;
              border-width: 0;

              -webkit-box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
              box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);

              .dropdown-item {
                color: color-yiq($white);
                padding: 5px 1.8rem;

                &:hover,
                &.active,
                &:active {
                  background-color: transparent;
                  color: $secondary;
                }
              }
            }
          }

          .dropdown {
            .dropdown-toggle::after {
              color: $body-color-secondary;
              bottom: 6px;
            }

            &:hover > .dropdown-menu {
              display: block;
            }
          }

          .mega-dropdown {
            position: static !important;

            .mega-dropdown-menu {
              padding-left: 1.2rem;
              padding-right: 1.2rem;

              margin-left: 0;

              width: 100%;

              .dropdown-header {
                padding-left: 0;
                font-family: $font-family-base-bold;
                font-size: $font-size-base;
                margin-bottom: 10px;
                color: $body-color;
                padding-top: 0;
              }
              ul {
                padding-left: 0;
                li {
                  padding: 0;
                  margin-right: 0;
                  list-style: none;
                  a {
                    padding-left: 0;
                  }
                }
              }
            }
            .mega-dropdown-menu[aria-expanded='true'] {
              display: flex !important;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.dropdown-submenu {
  position: relative;

  .fas {
    font-size: 13px;
    color: color-yiq(darken($secondary, 2%));
    margin-top: 5px;
    float: right;
  }

  .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 0 !important;
    margin-top: -19px !important;
  }

  &:hover > .dropdown-menu {
    display: block;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-one
    .header-navbar
    nav
    .navbar-collapse
    ul
    .mega-dropdown
    .mega-dropdown-menu
    ul
    li
    a {
    white-space: normal;
  }
}
@media only screen and (max-width: 991px) {
  .header-area {
    .alert {
      .pro-description {
        .pro-info {
          font-size: 14px;
        }
        .close {
          right: -40px;
        }
      }
    }
  }
}
